import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useLocation, Link } from "react-router-dom"; // Import Link

import { Paper, Typography } from "@mui/material";

import { ReactComponent as Logo } from "../../vendor/logo.svg";
import SignInComponent from "../../components/auth/SignIn";
import Alert from "@mui/material/Alert";

const Brand = styled(Logo)`
  fill: ${(props) => props.theme.palette.primary.main};
  width: 64px;
  height: 64px;
  margin-bottom: 32px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function SignIn() {
  const location = useLocation();
  return (
    <React.Fragment>
      <Link to="/">
        <Brand />
      </Link>
      <div>
        {location.state?.message && (
          <Alert
            severity="success"
            color="warning"
            sx={{
              position: "fixed",
              top: "30px",
              right: "20px",
              zIndex: 9999,
            }}
          >
            {location.state.message}
          </Alert>
        )}
      </div>
      <Wrapper>
        <Helmet title="Sign In" />
        <Typography component="h2" variant="body1" align="center">
          Sign in to your account to continue
        </Typography>

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default SignIn;
