import React from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ImageIcon from "@mui/icons-material/Image";

import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Grid,
  Typography,
  DialogActions,
} from "@mui/material";
import { Videocam, LiveTv, InsertLink } from "@mui/icons-material";

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

function CustomReception() {
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
    if (open === true) {
    }
    setState(open);
  };
  return (
    <Box
      sx={{ width: 450 }}
      style={{ width: "1000px" }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Customize Reception
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container md={12}>
          <React.Fragment>
            <Grid md={8} style={{ paddingRight: "15px" }}>
              <Typography variant="h5" mb={2}>
                Reception Canvas
              </Typography>
              <img
                src="/static/img/others/ReceptionCanvas.png"
                style={{ width: "100%", border: "1px solid #eee" }}
                alt=""
              />
            </Grid>
            <Grid md={4}>
              <Typography variant="h5" mb={2}>
                Objects
              </Typography>
              <Typography style={{ fontSize: "12px" }}>
                Drag and drop objects to place on the Reception. You can then
                customize them in the canvas
              </Typography>
              <List
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <ListItem
                  style={{ border: "1px solid #c6c5c8", marginBottom: "10px" }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Background Image"
                    secondary="Add a background photo for your reception on which you can place videos or links (Recommended)"
                  />
                </ListItem>
                <ListItem
                  style={{ border: "1px solid #c6c5c8", marginBottom: "10px" }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <Videocam />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Videos"
                    secondary="Add promo videos to welcome attendees like product showcases, services etc"
                  />
                </ListItem>
                <ListItem
                  style={{ border: "1px solid #c6c5c8", marginBottom: "10px" }}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <LiveTv />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Live/upcoming sessions"
                    secondary="Add a live session widget on the reception for easier session entry. It will display all the live sessions."
                  />
                </ListItem>
                <ListItem style={{ border: "1px solid #c6c5c8" }}>
                  <ListItemAvatar>
                    <Avatar>
                      <InsertLink />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary="Links"
                    secondary="Add transparent clickable areas which can be linked to areas of the event or external URLs"
                  />
                </ListItem>
              </List>
            </Grid>
          </React.Fragment>
        </Grid>
      </Grid>
      <DialogActions>
        <Button color="primary" onClick={toggleDrawer(false)}>
          Cancel
        </Button>
        {/* <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          sx={{
            mb: 2,
            // Override default disabled styles
            "&.Mui-disabled": {
              backgroundColor: "primary.main", // Change this to your desired color
              color: "white", // Ensure text color stays visible
              opacity: 1, // Prevent the button from becoming semi-transparent
            },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}
export default CustomReception;
