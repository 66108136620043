import React from "react";
import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Grid,
  Typography,
  DialogActions,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";
const CloudUpload = styled(MuiCloudUpload)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;
const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;
function WaitingScreenAdd() {
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
    if (open === true) {
    }
    setState(open);
  };
  return (
    <Box sx={{ width: 450 }} style={{ width: "350px" }} role="presentation">
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Upload new media
          </Typography>
          <br />
          <Typography variant="h7">
            Upload a new media to be shown on stage before the session starts
            and during paused session
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <CenteredContent>
              <BigAvatar
                alt="Remy Sharp"
                src="/static/img/avatars/avatar-1.jpg"
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} /> Upload
                </Button>
                <Typography variant="caption" display="block" gutterBottom>
                  Upload file formats like jpg, gif, png and mp4. Dimensions:
                  1920x1080 px | up to 500 MB.
                </Typography>
              </label>
            </CenteredContent>
          </Grid>
        </Grid>
      </Grid>
      <DialogActions>
        <Button color="primary" onClick={toggleDrawer(false)}>
          Cancel
        </Button>
        {/* <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          sx={{
            mb: 2,
            // Override default disabled styles
            "&.Mui-disabled": {
              backgroundColor: "primary.main", // Change this to your desired color
              color: "white", // Ensure text color stays visible
              opacity: 1, // Prevent the button from becoming semi-transparent
            },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}
export default WaitingScreenAdd;
