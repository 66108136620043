import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../utils/axios";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  CardContent,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import { Add as AddIcon } from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const ToolbarTitle = styled.div`
  min-width: 220px;

  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 900px;
  }
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

function createData(
  customer,
  customerEmail,
  customerAvatar,
  status,
  amount,
  id,
  date
) {
  return { customer, customerEmail, customerAvatar, status, amount, id, date };
}

const rows = [
  createData("Event A", "Description", "A", 0, "$530,00", "7", "2023-01-02"),
  createData("Event B", "Description", "D", 2, "$209,00", "10", "2023-02-13"),
  createData("Event C", "Description", "E", 2, "$535,00", "9", "2023-03-04"),
  createData("Event D", "Description", "E", 2, "$678,00", "8", "2023-02-17"),
  createData("Event E", "Description", "G", 0, "$314,00", "7", "2023-03-28"),
  createData("Event F", "Description", "J", 0, "$864,00", "9", "2023-01-23"),
  createData("Event G", "Description", "L", 2, "$341,00", "10", "2023-03-14"),
  createData("Event H", "Description", "M", 2, "$781,00", "12", "2023-02-22"),
  createData("Event I", "Description", "M", 0, "$273,00", "15", "2023-05-31"),
  createData("Event J", "Description", "S", 0, "$713,00", "10", "2023-04-25"),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "customer", alignment: "left", label: "Event Name" },
  { id: "status", alignment: "left", label: "Status" },
  { id: "date", alignment: "left", label: "Date" },
  { id: "id", alignment: "left", label: "Participants" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTableToolbar({ numSelected, doChangeProps }) {
  const [viewStyle, setViewStyle] = useState("List");
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const { t } = useTranslation();

  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;
    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
      width: 160px;
    }
  `;

  const Search = styled.div`
    border-radius: 2px;
    margin-left: 12px;
    background-color: ${(props) => props.theme.header.background};
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }
  `;

  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;

  const handleClickShowing = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleCloseShowing = () => {
    setAnchorEl1(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar style={{ paddingLeft: "0" }}>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography
            color="inherit"
            variant="subtitle1"
            style={{ paddingLeft: "12px" }}
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Grid container>
            {viewStyle === "Card" ? (
              <Grid item md={1}>
                <Checkbox />
              </Grid>
            ) : null}
            <Grid item md={3}>
              <Typography component="div">
                <Search className="searchBar">
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input
                    placeholder={t("Search")}
                    sx={{ width: { xs: "120px", md: "160px" } }}
                  />
                </Search>
              </Typography>
            </Grid>
            <Grid item md={8} className="filter">
              <Button
                variant="contained"
                disabled
                sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              >
                All
              </Button>
              <Button
                variant="outlined"
                sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              >
                Upcoming
              </Button>
              <Button
                variant="outlined"
                sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              >
                Ongoing
              </Button>
              <Button
                variant="outlined"
                sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              >
                Paused
              </Button>
              <Button
                variant="outlined"
                sx={{ mr: 10, display: { xs: "none", md: "inline" } }}
              >
                Completed
              </Button>
              <IconButton
                aria-label="View"
                size="large"
                aria-owns={anchorEl1 ? "simple-menu-showing" : undefined}
                aria-haspopup="true"
                onClick={handleClickShowing}
                variant="contained"
                color="secondary"
                sx={{ mr: 2, display: { xs: "block", md: "none" } }}
              >
                <VisibilityIcon />
              </IconButton>
              <Menu
                id="simple-menu-showing"
                anchorEl={anchorEl1}
                open={Boolean(anchorEl1)}
                onClose={handleCloseShowing}
              >
                <MenuItem>All</MenuItem>
                <MenuItem>Upcoming</MenuItem>
                <MenuItem>Ongoing</MenuItem>
                <MenuItem>Paused</MenuItem>
                <MenuItem>Completed</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        )}
      </ToolbarTitle>
      <Grid container display="block" textAlign="right">
        {numSelected === 1 ? (
          <Grid item>
            <Tooltip title="View">
              <IconButton
                aria-label="View"
                size="large"
                component={NavLink}
                to="/events/view-event"
              >
                <VisibilityIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : numSelected > 1 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Paper>
            <Button
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              View
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={(e) => {
                  doChangeProps("List");
                  setViewStyle("List");
                  setAnchorEl(null);
                }}
              >
                List
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  doChangeProps("Card");
                  setViewStyle("Card");
                  setAnchorEl(null);
                }}
              >
                Card
              </MenuItem>
            </Menu>
          </Paper>
        )}
      </Grid>
    </Toolbar>
  );
}

function EnhancedTable({ events, setEvents }) {
  const [viewStyle, setViewStyle] = useState("List");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // setViewStyle("Card");
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          doChangeProps={setViewStyle}
        />
      </Paper>
      {/* <ViewMenu doChangeProps={setViewStyle} /> */}
      {viewStyle === "List" ? (
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(events, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row">
                          <Customer>
                            {/* <Avatar>{row.customerAvatar}</Avatar> */}
                            <Card sx={{ height: 90, maxWidth: 148 }}>
                              <CardMedia
                                component="img"
                                height="194"
                                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
                                alt="Paella dish"
                              />
                            </Card>
                            <Box ml={3}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                display="inline"
                              >
                                {row.name}
                              </Typography>
                              <br />
                              {row.customerEmail}
                            </Box>
                          </Customer>
                        </TableCell>
                        <TableCell>
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Ongoing"
                            sent={+true}
                          />
                          {row.status === 0 && (
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label="Ongoing"
                              sent={+true}
                            />
                          )}
                          {row.status === 2 && (
                            <Chip
                              size="small"
                              mr={1}
                              mb={1}
                              label="Upcoming"
                              paid={+true}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {new Date(row.startDate)
                            .toLocaleDateString("en-US", {
                              weekday: "short",
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            })
                            .replace(/\//g, "-")}
                          <br />
                          {new Date(row.startTime).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                          -
                          {new Date(row.endTime).toLocaleTimeString("en-US", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </TableCell>
                        <TableCell align="left"></TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box m={1} pt={3} style={{ margin: "0" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Ongoing" color="warning" />}
                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum."
                chip={<Chip label="Upcoming" color="success" />}
                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Upcoming" color="success" />}
                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa."
                chip={<Chip label="Upcoming" color="success" />}
                image="https://d2xqcdy5rl17k2.cloudfront.net/images/default-landing-banner-min.png"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

const Project = ({ image, title, description, chip }) => {
  return (
    <Paper>
      <Card>
        {image ? (
          <div
            style={{
              position: "relative",
            }}
          >
            <CardMedia image={image} title="Contemplative Reptile" />
            <Checkbox
              value="remember"
              color="primary"
              style={{ position: "absolute", top: "0px", left: "0px" }}
            />
          </div>
        ) : null}
        <CardContent>
          <Box>
            <Typography variant="h4" gutterBottom display="inline">
              Event A
            </Typography>
            <br />
            Description
          </Box>
          <Typography mb={4} mt={4} color="textSecondary" component="p">
            Wed 20 Dec 2023
            <br />
            11:00 AM - 12:00 PM IST
            <br />
            <br />
            10 Participants
          </Typography>
          {chip}
        </CardContent>
        {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
      </Card>
    </Paper>
  );
};

const GET_EVENTS_QUERY = `
  query {
    getAllEvents {
      id
      name
      startDate
      endDate
      startTime
      endTime
      timezone
      organizedBy
      supportEmail
    }
  }
`;

function Events() {
  const location = useLocation();
  const [events, setEvents] = useState([]);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  // Fetch events from API
  const fetchEvents = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: GET_EVENTS_QUERY,
      });
      console.log("ppppppppppp##", response.data);
      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setEvents(response.data.getAllEvents);
    } catch (error) {
      setAlert({
        open: true,
        message: "Failed to fetch events",
        severity: "error",
      });
    }
  };

  // Check for state passed from navigation
  useEffect(() => {
    if (location.state?.message) {
      setAlert({
        open: true,
        message: location.state.message,
        severity: location.state.severity || "success",
      });
    }
    fetchEvents();
    console.log("!!!!!!!!!!!!!!! rrrrrrrrr");
  }, [location.state]);
  return (
    <React.Fragment>
      <Helmet title="Events List" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Events List
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/events/dashboard">
              Dashboard
            </Link>
            <Typography>Events List</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              component={Link}
              color="primary"
              to="/events/add-event"
            >
              <AddIcon />
              New Event
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable events={events} setEvents={setEvents} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Events;
