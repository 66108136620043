import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
} from "@mui/material";

import Code from "../../../components/Code";

const CREATE_WIDGET = gql`
  mutation CreateWidget($input: WidgetInput!) {
    createWidget(input: $input) {
      id
      script_id
      business_details {
        business_type
        name
        display_name
        website_url
      }
    }
  }
`;

const TEST_WIDGET_INTEGRATION = gql`
  mutation TestWidgetIntegration($scriptId: String!, $websiteUrl: String!) {
    testWidgetIntegration(scriptId: $scriptId, websiteUrl: $websiteUrl) {
      success
      message
    }
  }
`;

function EventEntryTab() {
  const [widgetId, setWidgetId] = useState("");
  const [businessType, setBusinessType] = useState(1);
  const [name, setName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [websiteUrl, setWebsiteUrl] = useState("");
  const [scriptTag, setScriptTag] = useState("");
  const [error, setError] = useState(null);
  const [integrationTestResult, setIntegrationTestResult] = useState(null);

  const [createWidget, { loading: createLoading }] = useMutation(
    CREATE_WIDGET,
    {
      onError: (error) => {
        console.error("Error creating widget:", error);
        setError(error.message);
      },
    }
  );

  const [testWidgetIntegration, { loading: testLoading }] = useMutation(
    TEST_WIDGET_INTEGRATION,
    {
      onError: (error) => {
        console.error("Error testing widget integration:", error);
        setIntegrationTestResult({ success: false, message: error.message });
      },
    }
  );

  const handleCreateWidget = async () => {
    try {
      const { data } = await createWidget({
        variables: {
          input: {
            business_details: {
              business_type: businessType,
              name,
              display_name: displayName,
              website_url: websiteUrl,
            },
          },
        },
      });
      setWidgetId(data.createWidget.script_id);
      setScriptTag(renderScriptTag(data.createWidget.script_id));
      setError(null);
    } catch (err) {
      // Error is handled in the onError callback of useMutation
    }
  };

  const handleTestIntegration = async () => {
    try {
      console.log("!!!!!!! scriptId", widgetId, websiteUrl);
      const { data } = await testWidgetIntegration({
        variables: {
          scriptId: widgetId,
          websiteUrl,
        },
      });
      setIntegrationTestResult(data.testWidgetIntegration);
    } catch (err) {
      // Error is handled in the onError callback of useMutation
    }
  };

  const renderScriptTag = (scriptId) => {
    return `
<script type="text/javascript" async>
(function(d,s){
var l=d.createElement(s),e=d.getElementsByTagName(s)[0];
l.async=true;l.type='text/javascript';
l.src='http://localhost:3001/embed.js';
l.id='${scriptId}';
e.parentNode.insertBefore(l,e);})(document,'script');</script>`.trim();
  };

  return (
    <Grid justifyContent="space-between" container>
      <Grid item md={12}>
        {error && (
          <Alert severity="error" style={{ marginBottom: "15px" }}>
            {error}
          </Alert>
        )}
        <Grid container spacing={6}>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            <FormControl style={{ width: "100%" }}>
              <InputLabel htmlFor="business-type">
                What are you using the widget for?*
              </InputLabel>
              <Select
                value={businessType}
                onChange={(e) => setBusinessType(e.target.value)}
                inputProps={{
                  name: "business-type",
                  id: "business-type",
                }}
              >
                <MenuItem value={1}>Sales</MenuItem>
                <MenuItem value={2}>Support</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            <TextField
              label="Give your widget a name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            <TextField
              label="Set display company name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              variant="outlined"
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={6} style={{ marginBottom: "15px" }}>
            <TextField
              label="Website URL"
              value={websiteUrl}
              onChange={(e) => setWebsiteUrl(e.target.value)}
              variant="outlined"
              fullWidth
              placeholder="https://example.com"
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item md={12} style={{ marginBottom: "15px" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateWidget}
              style={{ marginRight: "15px" }}
              disabled={createLoading}
            >
              {createLoading ? <CircularProgress size={24} /> : "Create Widget"}
            </Button>
          </Grid>
        </Grid>
        {widgetId && (
          <Grid container spacing={6}>
            <Grid item md={6} style={{ marginBottom: "15px" }}>
              <Typography variant="h3" gutterBottom>
                Website Integration
              </Typography>
              <Box mb={4}>
                <InputLabel>
                  1. Integrate the code into the footer of your website
                </InputLabel>
                <Typography variant="subtitle1" gutterBottom>
                  <Code>{scriptTag}</Code>
                </Typography>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigator.clipboard.writeText(scriptTag)}
                >
                  Copy
                </Button>
              </Box>
              <Box mb={4}>
                <InputLabel>
                  2. After adding the script to your website, test the
                  integration
                </InputLabel>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleTestIntegration}
                  style={{ marginTop: "10px" }}
                  disabled={testLoading}
                >
                  {testLoading ? (
                    <CircularProgress size={24} />
                  ) : (
                    "Test Widget Integration"
                  )}
                </Button>
                {integrationTestResult && (
                  <Alert
                    severity={
                      integrationTestResult.success ? "success" : "error"
                    }
                    style={{ marginTop: "10px" }}
                  >
                    {integrationTestResult.message}
                  </Alert>
                )}
              </Box>
              <Box mb={4}>
                <InputLabel>
                  3. Do not forget to complete your privacy policy on your
                  website
                </InputLabel>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ marginTop: "10px" }}
                >
                  Data Protection Text For Your Website
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

function PrepareWebsite() {
  return (
    <React.Fragment>
      <EventEntryTab />
    </React.Fragment>
  );
}

export default PrepareWebsite;
