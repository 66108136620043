import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { Link } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";

import {
  AppBar,
  Button as MuiButton,
  Container,
  Grid,
  Box,
  Toolbar,
} from "@mui/material";

import { ReactComponent as Logo } from "../../../vendor/logo.svg";

const Button = styled(MuiButton)(spacing);

const Brand = styled.div`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  font-family: ${(props) => props.theme.typography.fontFamily};
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  margin-top: -2px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 32px;
  height: 32px;

  vertical-align: middle;
  display: inline;
`;

const AppBarComponent = () => {
  const { user } = useAuth();
  return (
    <React.Fragment>
      <AppBar position="relative" color="transparent" elevation={0}>
        <Toolbar>
          <Container>
            <Grid container alignItems="center">
              <Grid item>
                <Brand>
                  <BrandIcon />
                  Mira
                </Brand>
              </Grid>
              <Grid item xs />
              <Grid item>
                <Box sx={{ display: { xs: "none", md: "inline-block" } }}>
                  <Button
                    ml={2}
                    color="inherit"
                    component={Link}
                    to="/dashboard/analytics"
                    target="_blank"
                  >
                    Live Preview
                  </Button>
                  <Button
                    ml={2}
                    color="inherit"
                    component={Link}
                    to="/documentation/welcome"
                    target="_blank"
                  >
                    Documentation
                  </Button>
                  <Button
                    ml={2}
                    color="inherit"
                    component={Link}
                    to="/documentation/support"
                    target="_blank"
                  >
                    Support
                  </Button>
                  {user ? (
                    <Button
                      ml={2}
                      color="inherit"
                      component={Link}
                      to="/private"
                    >
                      Dashboard
                    </Button>
                  ) : (
                    <>
                      <Button
                        ml={2}
                        color="inherit"
                        component={Link}
                        to="/auth/sign-in"
                      >
                        Sign In
                      </Button>
                      <Button
                        ml={2}
                        color="inherit"
                        component={Link}
                        to="/auth/sign-up"
                      >
                        Sign Up
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default AppBarComponent;
