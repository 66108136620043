import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  InputLabel,
  Button,
  FormGroup,
  FormControlLabel,
  Switch,
  Card,
  CardMedia,
  Radio,
  RadioGroup,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";

import Code from "../../../components/Code";

const DisplayOptions = ({ handleChildData }) => {
  const [buttonStyle, setButtonStyle] = useState("Button");
  const [mode, setMode] = useState("Standard");
  const [position, setPosition] = useState("left");
  const [squareButton, setSquareButton] = useState(true);
  const [minimizeSmallButton, setMinimizeSmallButton] = useState(true);
  const [showPreview, setShowPreview] = useState(true);
  const [background, setBackground] = useState(null);
  const [logo, setLogo] = useState(null);

  const handleModeChange = (newMode) => {
    setMode(newMode);
    handleChildData(newMode);
  };

  const handleButtonStyleChange = (newStyle) => {
    setButtonStyle(newStyle);
  };

  const handleFileUpload = (event, type) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        if (type === "background") {
          setBackground(reader.result);
        } else if (type === "logo") {
          setLogo(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item md={12}>
        <Grid container spacing={6}>
          <Grid item md={12} style={{ marginBottom: "15px" }}>
            <div style={{ marginTop: "10px" }}>
              {["Standard", "Developer"].map((m) => (
                <Button
                  key={m}
                  variant={mode === m ? "contained" : "outlined"}
                  color="primary"
                  style={{ marginRight: "15px" }}
                  onClick={() => handleModeChange(m)}
                >
                  {m} Mode
                </Button>
              ))}
            </div>
          </Grid>
        </Grid>

        {mode === "Standard" ? (
          <Grid container spacing={6}>
            <Grid item md={5} style={{ marginBottom: "15px" }}>
              <Typography variant="h3" gutterBottom>
                Website Integration
              </Typography>

              {/* Button Style */}
              <Box mb={3}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  1. Button Style
                </InputLabel>
                <Grid container>
                  {["Button", "Button+Text"].map((style) => (
                    <Grid
                      key={style}
                      md={5}
                      style={{ marginRight: "10px" }}
                      onClick={() => handleButtonStyleChange(style)}
                    >
                      <Card>
                        <CardMedia
                          component="img"
                          height="140"
                          image={`/static/img/others/${style
                            .toLowerCase()
                            .replace("+", "")}.png`}
                          alt={style}
                          style={{
                            objectFit: "contain",
                            backgroundPosition: "center",
                            width: "100%",
                            border:
                              buttonStyle === style
                                ? "2px solid #376fd0"
                                : "none",
                            borderRadius: "10px",
                          }}
                        />
                      </Card>
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.9rem",
                          borderBottom:
                            buttonStyle === style
                              ? "2px solid #376fd0"
                              : "none",
                        }}
                      >
                        {style}
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Box>

              {/* Position */}
              <Box mb={4}>
                <InputLabel>2. Position</InputLabel>
                <RadioGroup
                  row
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <FormControlLabel
                    value="left"
                    control={<Radio />}
                    label="Left"
                  />
                  <FormControlLabel
                    value="right"
                    control={<Radio />}
                    label="Right"
                  />
                </RadioGroup>
              </Box>

              {/* Square Button */}
              <Box mb={4}>
                <InputLabel>3. Square Button</InputLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={squareButton}
                        onChange={(e) => setSquareButton(e.target.checked)}
                      />
                    }
                    label=""
                  />
                </FormGroup>
              </Box>

              {/* Background */}
              <Box mb={4}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  4. Background
                </InputLabel>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="background-upload"
                  type="file"
                  onChange={(e) => handleFileUpload(e, "background")}
                />
                <label htmlFor="background-upload">
                  <Button variant="contained" color="primary" component="span">
                    Upload Background
                  </Button>
                </label>
                {background && (
                  <Typography variant="body2" style={{ marginTop: "10px" }}>
                    Background uploaded
                  </Typography>
                )}
              </Box>

              {/* Logo */}
              <Box mb={4}>
                <InputLabel style={{ marginBottom: "10px" }}>
                  5. Logo
                </InputLabel>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="logo-upload"
                  type="file"
                  onChange={(e) => handleFileUpload(e, "logo")}
                />
                <label htmlFor="logo-upload">
                  <Button variant="contained" color="primary" component="span">
                    Upload Logo
                  </Button>
                </label>
                {logo && (
                  <Typography variant="body2" style={{ marginTop: "10px" }}>
                    Logo uploaded
                  </Typography>
                )}
              </Box>

              {/* Minimize small Button */}
              <Box mb={4}>
                <InputLabel>
                  6. Minimize small Button
                  <FormControlLabel
                    label=""
                    control={
                      <Switch
                        checked={minimizeSmallButton}
                        onChange={(e) =>
                          setMinimizeSmallButton(e.target.checked)
                        }
                      />
                    }
                    style={{ marginLeft: "0px" }}
                  />
                </InputLabel>
              </Box>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showPreview}
                      onChange={(e) => setShowPreview(e.target.checked)}
                    />
                  }
                  label="Show Preview"
                />
              </FormGroup>
            </Grid>

            {/* Preview */}
            <Grid item md={7} style={{ marginBottom: "15px" }}>
              <Typography variant="h3" gutterBottom>
                Preview
              </Typography>
              {showPreview && (
                <Card
                  style={{
                    height: "400px",
                    backgroundImage: `url(${background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    position: "relative",
                  }}
                >
                  {logo && (
                    <img
                      src={logo}
                      alt="Logo"
                      style={{
                        position: "absolute",
                        top: "10px",
                        left: "10px",
                        maxWidth: "100px",
                        maxHeight: "100px",
                      }}
                    />
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    style={{
                      position: "absolute",
                      [position]: "20px",
                      bottom: "20px",
                      borderRadius: squareButton ? "0" : "20px",
                    }}
                  >
                    {buttonStyle === "Button"
                      ? "Call Agent"
                      : "Call Agent Now!"}
                  </Button>
                </Card>
              )}
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={6}>
            <Grid item md={6} style={{ marginBottom: "15px" }}>
              <Box mb={4}>
                <InputLabel>
                  Integrate your own design by adding the following code in your
                  button
                </InputLabel>
                <Typography variant="subtitle1" gutterBottom my={4}>
                  <Code>{`<button onclick="pathadvice.connect()">Call Agent</button>`}</Code>
                </Typography>
                <Button variant="outlined" color="primary">
                  Copy
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DisplayOptions;
