import React, { useState } from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import Switch from "@mui/material/Switch";
import { axiosInstance } from "../../../../utils/axios";
import {
  Alert as MuiAlert,
  Avatar as MuiAvatar,
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  DialogActions,
} from "@mui/material";
import {
  CloudUpload as MuiCloudUpload,
  RemoveCircleOutline,
  AddCircleOutline,
} from "@mui/icons-material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { spacing } from "@mui/system"; // Ensure spacing is imported

const CloudUpload = styled(MuiCloudUpload)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;
const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

// Validation schema using Yup
const validationSchema = Yup.object({
  boothName: Yup.string().required("Booth Name is required"),
  boothTags: Yup.string().required("Booth Tags are required"),
  boothExhibitors: Yup.string().required("Booth Exhibitors are required"),
});

function GroupAdd() {
  const [open, setOpen] = useState(false);
  const [chatEnabled, setChatEnabled] = useState(false);
  const [tablesEnabled, setTablesEnabled] = useState(false);
  const [liveBroadcastEnabled, setLiveBroadcastEnabled] = useState(false);
  const [numberOfTables, setNumberOfTables] = useState(2); // For controlling number of tables

  const handleSubmit = async (values) => {
    try {
      const response = await axiosInstance.post(
        "/graphql", // Replace with your GraphQL endpoint
        {
          query: `
          mutation CreateBooth($input: CreateBoothInput!) {
            createBooth(input: $input) {
              id
              boothName
              boothTags
              boothExhibitors
              chatEnabled
              tablesEnabled
              liveBroadcastEnabled
              numberOfTables
            }
          }
        `,
          variables: {
            input: {
              boothName: values.boothName,
              boothTags: values.boothTags,
              boothExhibitors: values.boothExhibitors,
              chatEnabled: chatEnabled, // from state
              tablesEnabled: tablesEnabled, // from state
              liveBroadcastEnabled: liveBroadcastEnabled, // from state
              numberOfTables: numberOfTables, // from state
            },
          },
        }
      );
      console.log("Form successfully submitted", response.data);
    } catch (error) {
      console.error("Error submitting form", error);
    }
  };

  return (
    <Box
      sx={{ width: 450, padding: "0 24px" }} // Add padding for left and right spacing
      style={{ width: "450px", padding: "0 24px" }} // Inline style for compatibility
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Booth
          </Typography>
        </Grid>
      </Grid>

      <Formik
        initialValues={{
          boothName: "",
          boothTags: "",
          boothExhibitors: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <Grid container spacing={6}>
              <Grid item md={12}>
                <Typography variant="h5" gutterBottom display="inline">
                  General
                </Typography>
              </Grid>

              <Grid item md={12}>
                <CenteredContent>
                  <BigAvatar
                    alt="Booth Logo"
                    src="/static/img/avatars/avatar-1.jpg"
                  />
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      <CloudUpload mr={2} /> Upload Booth Logo
                    </Button>
                    <Typography variant="caption" display="block" gutterBottom>
                      Recommended format: 960x480px • JPG, SVG, PNG • Up to 5 MB
                    </Typography>
                  </label>
                </CenteredContent>
              </Grid>

              <Grid item md={12}>
                <Field
                  name="boothName"
                  as={TextField}
                  label="Booth Name *"
                  variant="outlined"
                  fullWidth
                  helperText={
                    touched.boothName && errors.boothName
                      ? errors.boothName
                      : "Add a booth name."
                  }
                  error={touched.boothName && Boolean(errors.boothName)}
                />
              </Grid>

              <Grid item md={12}>
                <Field
                  name="boothTags"
                  as={TextField}
                  label="Booth Tags"
                  variant="outlined"
                  fullWidth
                  helperText={
                    touched.boothTags && errors.boothTags
                      ? errors.boothTags
                      : "Create a tag or select from the ones available."
                  }
                  error={touched.boothTags && Boolean(errors.boothTags)}
                />
              </Grid>

              <Grid item md={12}>
                <Field
                  name="boothExhibitors"
                  as={TextField}
                  label="Booth Exhibitors"
                  variant="outlined"
                  fullWidth
                  helperText={
                    touched.boothExhibitors && errors.boothExhibitors
                      ? errors.boothExhibitors
                      : "Invite up to 10 people to your booth."
                  }
                  error={
                    touched.boothExhibitors && Boolean(errors.boothExhibitors)
                  }
                />
              </Grid>

              {/* Chat Enabled Switch */}
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography variant="subtitle1">Chat</Typography>
                      <Typography color="success">
                        <small>
                          Choose whether you want to allow visitors to publicly
                          post messages within the booth.
                        </small>
                      </Typography>
                    </Grid>
                    <Grid item md={4} style={{ textAlign: "right" }}>
                      <Switch
                        checked={chatEnabled}
                        onChange={() => setChatEnabled(!chatEnabled)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>

              {/* Tables Enabled Switch */}
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography variant="subtitle1">Tables</Typography>
                      <Typography color="success">
                        <small>
                          Allow visitors to sit at tables for in-depth
                          conversations.
                        </small>
                      </Typography>
                    </Grid>
                    <Grid item md={4} style={{ textAlign: "right" }}>
                      <Switch
                        checked={tablesEnabled}
                        onChange={() => setTablesEnabled(!tablesEnabled)}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>

              {/* Number of Tables */}
              {tablesEnabled && (
                <Grid item md={12}>
                  <ListItem alignItems="flex-start" disablePadding>
                    <Grid container>
                      <Grid item md={8}>
                        <Typography variant="subtitle1">
                          Number of Tables
                        </Typography>
                      </Grid>
                      <Grid item md={4}>
                        <Grid container style={{ justifyContent: "center" }}>
                          <Button
                            onClick={() =>
                              setNumberOfTables(Math.max(0, numberOfTables - 1))
                            }
                          >
                            <RemoveCircleOutline />
                          </Button>
                          <Typography>{numberOfTables}</Typography>
                          <Button
                            onClick={() =>
                              setNumberOfTables(numberOfTables + 1)
                            }
                          >
                            <AddCircleOutline />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ListItem>
                </Grid>
              )}

              {/* Live Broadcast Enabled Switch */}
              <Grid item md={12}>
                <ListItem alignItems="flex-start" disablePadding>
                  <Grid container>
                    <Grid item md={8}>
                      <Typography variant="subtitle1">
                        Live Broadcast
                      </Typography>
                      <Typography color="success">
                        <small>
                          Enable live video broadcasts in the booth.
                        </small>
                      </Typography>
                    </Grid>
                    <Grid item md={4} style={{ textAlign: "right" }}>
                      <Switch
                        checked={liveBroadcastEnabled}
                        onChange={() =>
                          setLiveBroadcastEnabled(!liveBroadcastEnabled)
                        }
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </ListItem>
              </Grid>

              <DialogActions>
                <Button color="primary" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  Submit
                </Button>
              </DialogActions>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default GroupAdd;
