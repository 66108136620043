import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { height, spacing } from "@mui/system";
import { green, red } from "@mui/material/colors";

import Actions from "./Actions";
import BarChart from "./BarChart";
import LineChart from "./LineChart";
import CustomerRequestByDay from "./CustomerRequestByDay";
import CustomerRequestByHour from "./CustomerRequestByHour";
import SessionsByDay from "./SessionsByDay";
import SessionsByHour from "./SessionsByHour";
import ViewsByDay from "./ViewsByDay";
import ViewsByHour from "./ViewsByHour";
import DoughnutChart from "./DoughnutChart";
import Stats from "./Stats";
import Table from "./Table";
import Table1 from "./Table1";
import RequestsByTypeWebConnect from "./RequestsByTypeWebConnect";
import RequestsByTypeQrConnect from "./RequestsByTypeQrConnect";
import SessionsByTypeWebConnect from "./SessionsByTypeWebConnect";
import SessionsByTypeQrConnect from "./SessionsByTypeQrConnect";
import Browser from "./Browser";
import TopOrigins from "./TopOrigins";
import TopReferrers from "./TopReferrers";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Dashboard() {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Grid justifyContent="space-between" container spacing={6}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Dashboard
          </Typography>
          <Typography variant="subtitle1">
            {t("Welcome back")}, Lucy! {t("We've missed you")}.{" "}
            <span role="img" aria-label="Waving Hand Sign">
              👋
            </span>
          </Typography>
        </Grid>

        <Grid item>
          <Actions />
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Stats
            title="Shown Contact Form"
            amount="2"
            // chip="Today"
            percentagetext="26"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Stats
            title="Filled Contact Form"
            amount="170"
            // chip="Annual"
            percentagetext="-14%"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Stats
            title="Booked Apointment"
            amount="24"
            chip="Monthly"
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Stats
            title="Click Rate"
            amount="5%"
            // chip="Today"
            percentagetext="+26%"
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Stats
            title="Session Duration"
            amount="00:01:25"
            // chip="Annual"
            percentagetext="-14%"
            percentagecolor={red[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Stats
            title="All Finished Conversations"
            amount="8"
            chip="Monthly"
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Stats
            title="Sent Feedback Via Message"
            amount="8"
            chip="Monthly"
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <Stats
            title="Closed Feedback Forms"
            amount="8"
            chip="Monthly"
            percentagetext="+18%"
            percentagecolor={green[500]}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <Browser />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TopOrigins />
        </Grid>
        <Grid item xs={12} lg={4}>
          <TopReferrers />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={3}>
          <RequestsByTypeWebConnect />
        </Grid>
        <Grid item xs={12} lg={3}>
          <SessionsByTypeWebConnect />
        </Grid>
        <Grid item xs={12} lg={3}>
          <RequestsByTypeQrConnect />
        </Grid>
        <Grid item xs={12} lg={3}>
          <SessionsByTypeQrConnect />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <ViewsByHour />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ViewsByDay />
        </Grid>
        {/* <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid> */}
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <CustomerRequestByHour />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomerRequestByDay />
        </Grid>
        {/* <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid> */}
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <SessionsByHour />
        </Grid>
        <Grid item xs={12} lg={6}>
          <SessionsByDay />
        </Grid>
        {/* <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid> */}
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={4}>
          <DoughnutChart />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Table />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Table1 />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Dashboard;
