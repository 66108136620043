import React from "react";
import styled from "@emotion/styled";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import EventBrandingComponent from "./EventBranding";
import LandingPageComponent from "./LandingPage";
import ReceptionComponent from "./Reception";
import StageComponent from "./Stage";
import SponsorsPageComponent from "./Sponsors";

import { Grid, Paper as MuiPaper, MenuItem } from "@mui/material";
import { spacing } from "@mui/system";

const Paper = styled(MuiPaper)(spacing);

function Branding() {
  const [RTab, setRTab] = React.useState("Event Branding");
  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "250px", padding: "0" }}>
            {RTab === "Event Branding" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Event Branding");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Event Branding</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Event Branding");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Event Branding</ListItemText>
              </MenuItem>
            )}
            {RTab === "Landing Page" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Landing Page");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Landing Page</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Landing Page");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Landing Page</ListItemText>
              </MenuItem>
            )}
            {RTab === "Reception" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Reception");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Reception</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Reception");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Reception</ListItemText>
              </MenuItem>
            )}
            {RTab === "Stage" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Stage");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Stage</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Stage");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Stage</ListItemText>
              </MenuItem>
            )}
            {RTab === "Sponsors" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Sponsors");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Sponsors</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Sponsors");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Sponsors</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        {RTab === "Event Branding" ? (
          <EventBrandingComponent />
        ) : RTab === "Landing Page" ? (
          <LandingPageComponent />
        ) : RTab === "Reception" ? (
          <ReceptionComponent />
        ) : RTab === "Stage" ? (
          <StageComponent />
        ) : (
          <SponsorsPageComponent />
        )}
      </Grid>
    </Grid>
  );
}

export default Branding;
