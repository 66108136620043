import React from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import FormGroup from "@mui/material/FormGroup";

import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Grid,
  Typography,
  DialogActions,
  TextField,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";
const CloudUpload = styled(MuiCloudUpload)(spacing);
const CenteredContent = styled.div`
  text-align: center;
`;
const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

function AvailableTracks() {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open, oo) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "350px" }}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Select Sponsor Tier
          </Typography>
          <Typography>
            Choose which sponsor tier the sponsor is associated with
          </Typography>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                <ListItem alignItems="flex-start">
                  <FormGroup>
                    <FormControlLabel control={<Radio />} label="Gold" />
                  </FormGroup>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <FormGroup>
                    <FormControlLabel control={<Radio />} label="Silver" />
                  </FormGroup>
                </ListItem>
                <ListItem alignItems="flex-start">
                  <FormGroup>
                    <FormControlLabel control={<Radio />} label="Bronze" />
                  </FormGroup>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );
  return (
    <>
      <Grid item md={12}>
        <TextField
          name="groupName"
          label="Sponsor Name *"
          variant="outlined"
          fullWidth
          my={2}
          value="Gold"
          onClick={toggleDrawer("right", true)}
        />
        <Drawer
          anchor={"right"}
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </Grid>
    </>
  );
}
function SponsorAdd() {
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
    if (open === true) {
    }
    setState(open);
  };
  return (
    <Box
      sx={{ width: 450 }}
      style={{ width: "450px" }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Sponsor
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container spacing={6}>
          <Grid item md={12}>
            <CenteredContent>
              <BigAvatar
                alt="Remy Sharp"
                src="/static/img/avatars/avatar-1.jpg"
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} /> Upload Logo
                </Button>
                <Typography variant="caption" display="block" gutterBottom>
                  Recommended format: 400x200px | JPG, SVG, PNG | Up to 1 MB
                </Typography>
              </label>
            </CenteredContent>
          </Grid>
          <Grid item md={12}>
            <TextField
              name="groupName"
              label="Sponsor Name *"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <Grid item md={12}>
            <TextField
              name="groupName"
              label="Website URL"
              variant="outlined"
              fullWidth
              my={2}
            />
          </Grid>
          <AvailableTracks />
        </Grid>
      </Grid>
      <DialogActions>
        <Button color="primary" onClick={toggleDrawer(false)}>
          Cancel
        </Button>
        {/* <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          sx={{
            mb: 2,
            // Override default disabled styles
            "&.Mui-disabled": {
              backgroundColor: "primary.main", // Change this to your desired color
              color: "white", // Ensure text color stays visible
              opacity: 1, // Prevent the button from becoming semi-transparent
            },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}
export default SponsorAdd;
