import React from "react";
import styled from "@emotion/styled";

import { Alert as MuiAlert } from "@mui/material";
import { spacing } from "@mui/system";

import { useLocation } from "react-router-dom";
import Default from "../dashboards/Default";

const Alert = styled(MuiAlert)(spacing);

function ProtectedPage() {
  const location = useLocation();
  return (
    <React.Fragment>
      {location.state?.message && (
        <>
          <Alert severity="success" color="warning">
            {location.state.message}
          </Alert>
          <br />
        </>
      )}
      <Alert mb={4} severity="info">
        This page is only visible by authenticated users.
      </Alert>

      <Default />
    </React.Fragment>
  );
}

export default ProtectedPage;
