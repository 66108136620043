import * as React from "react";

import {
  Typography,
  Button,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Avatar,
  ListItemAvatar,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";

function Analytics() {
  return (
    <MenuList className="preview" style={{ height: "550px" }}>
      <MenuItem
        style={{
          minHeight: "50px",
          borderBottom: "1px solid #eee",
        }}
      >
        <ListItem
          disableGutters
          secondaryAction={
            <Button variant="contained" color="inherit">
              Connect
            </Button>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Google Analytics"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Track and analyze scheduling data to gain insights and
                  optimize your scheduling performance.
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </MenuItem>
    </MenuList>
  );
}
export default Analytics;
