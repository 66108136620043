import React, { useState } from "react";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  InputLabel,
  Alert,
  Paper,
  Button,
} from "@mui/material";
import ReactQuill from "react-quill";
import { MuiColorInput } from "mui-color-input";

const Text_Colors = ({ handleChildData }) => {
  const [buttonText, setButtonText] = useState("Yes gladly");
  const [slogan, setSlogan] = useState("Let's Conn777");
  const [isCustomizableButton, setIsCustomizableButton] = useState(false);
  const [customButtonText, setCustomButtonText] = useState("More information");
  const [customButtonLink, setCustomButtonLink] = useState("");
  const [buttonColor, setButtonColor] = useState("#4CAF50");
  const [fontColor, setFontColor] = useState("#000000");
  const [iconColor, setIconColor] = useState("#000000");
  const [speechBubbleText, setSpeechBubbleText] = useState(
    "May I help you further? I would be happy to support you personally and immediately."
  );
  const [welcomeScreenText, setWelcomeScreenText] = useState(
    `You have questions about our products?

    We are happy to help you!
    
    How would you prefer to communicate with us?`
  );
  const [welcomeMessage, setWelcomeMessage] = useState(
    `Great to have you here!

    You are interested in one of our products? Just let us know how we can help you and you will be connected directly with one of our consultants. 
    
    Thank you for your patience!`
  );
  const [activateFontShadow, setActivateFontShadow] = useState(true);

  const handleCustomizableButtonChange = (event) => {
    setIsCustomizableButton(event.target.checked);
  };

  // Chat window background style with an actual image from the internet
  const chatWindowStyle = {
    backgroundImage: `url('https://www.transparenttextures.com/patterns/inspiration-geometry.png')`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: "#f0f0f0", // Fallback color
    minHeight: "300px",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  return (
    <Grid justifyContent="space-between" container spacing={4}>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom>
          Customise Your Widget
        </Typography>
        <Alert
          severity="info"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          Please create all your texts in English or leave the default texts.
          You can manage the translations here Translations. We always show the
          widget in the browser language the user has chosen. If this is not
          available, English will be default
        </Alert>
      </Grid>
      <Grid item md={5}>
        <Typography variant="h6" gutterBottom>
          1. Button + Text
        </Typography>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <TextField
              label="Speech Bubble Text"
              multiline
              rows={2}
              value={speechBubbleText}
              onChange={(e) => setSpeechBubbleText(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <TextField
              label="Call To Action Button"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <TextField
              label="Slogan"
              value={slogan}
              onChange={(e) => setSlogan(e.target.value)}
            />
          </FormControl>
        </Box>
        <Typography variant="h6" gutterBottom>
          2. Welcome Screen
        </Typography>
        <Box mb={2}>
          <FormControlLabel
            control={
              <Switch
                checked={activateFontShadow}
                onChange={(e) => setActivateFontShadow(e.target.checked)}
              />
            }
            label="Activate font shadow"
          />
        </Box>
        <Box mb={2}>
          <ReactQuill
            theme="snow"
            value={welcomeScreenText}
            onChange={setWelcomeScreenText}
            style={{ height: "100px" }}
          />
        </Box>
        <Box mb={2}>
          <FormControlLabel
            control={
              <Switch
                checked={isCustomizableButton}
                onChange={handleCustomizableButtonChange}
              />
            }
            label="Customizable button"
          />
        </Box>
        {isCustomizableButton && (
          <>
            <Box mb={2}>
              <FormControl fullWidth variant="outlined" size="small">
                <TextField
                  label="Button Text"
                  value={customButtonText}
                  onChange={(e) => setCustomButtonText(e.target.value)}
                />
              </FormControl>
            </Box>
            <Box mb={2}>
              <FormControl fullWidth variant="outlined" size="small">
                <TextField
                  label="Link"
                  value={customButtonLink}
                  onChange={(e) => setCustomButtonLink(e.target.value)}
                />
              </FormControl>
            </Box>
          </>
        )}
        <Box mb={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <MuiColorInput
              format="hex"
              value={buttonColor}
              onChange={setButtonColor}
              label="Button Color"
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <MuiColorInput
              format="hex"
              value={fontColor}
              onChange={setFontColor}
              label="Font Color"
            />
          </FormControl>
        </Box>
        <Box mb={2}>
          <FormControl fullWidth variant="outlined" size="small">
            <MuiColorInput
              format="hex"
              value={iconColor}
              onChange={setIconColor}
              label="Icon Color"
            />
          </FormControl>
        </Box>
        <Typography variant="h6" gutterBottom>
          3. Welcome Message
        </Typography>
        <Box mb={2}>
          <ReactQuill
            theme="snow"
            value={welcomeMessage}
            onChange={setWelcomeMessage}
            style={{ height: "100px" }}
          />
        </Box>
      </Grid>
      <Grid item md={7}>
        <Typography variant="h6" gutterBottom>
          Preview
        </Typography>
        <Paper elevation={3} style={{ padding: "20px", marginBottom: "20px" }}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: fontColor,
              textShadow: activateFontShadow
                ? "2px 2px 4px rgba(0,0,0,0.5)"
                : "none",
            }}
          >
            Step 1: Button + Text
          </Typography>
          <Box
            mb={2}
            style={{
              border: "1px solid #ccc",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <Typography
              style={{
                color: fontColor,
                textShadow: activateFontShadow
                  ? "2px 2px 4px rgba(0,0,0,0.5)"
                  : "none",
              }}
            >
              {speechBubbleText}
            </Typography>
          </Box>
          <Button
            variant="contained"
            style={{ backgroundColor: buttonColor, color: fontColor }}
          >
            {buttonText}
          </Button>
          <Typography
            variant="subtitle1"
            style={{
              marginTop: "10px",
              color: fontColor,
              textShadow: activateFontShadow
                ? "2px 2px 4px rgba(0,0,0,0.5)"
                : "none",
            }}
          >
            {slogan}
          </Typography>
        </Paper>
        <Paper elevation={3} style={chatWindowStyle}>
          <Typography
            variant="h5"
            gutterBottom
            style={{
              color: fontColor,
              textShadow: activateFontShadow
                ? "2px 2px 4px rgba(0,0,0,0.5)"
                : "none",
            }}
          >
            Step 2: Welcome Screen
          </Typography>
          <Box mb={2}>
            <Typography
              style={{
                color: fontColor,
                textShadow: activateFontShadow
                  ? "2px 2px 4px rgba(0,0,0,0.5)"
                  : "none",
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                padding: "10px",
                borderRadius: "5px",
              }}
            >
              <div dangerouslySetInnerHTML={{ __html: welcomeScreenText }} />
            </Typography>
          </Box>
          {isCustomizableButton && (
            <Button
              variant="contained"
              style={{ backgroundColor: buttonColor, color: fontColor }}
              href={customButtonLink}
            >
              {customButtonText}
            </Button>
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Text_Colors;
