import React, { useState } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import { NavLink, Link } from "react-router-dom";
import {
  Grid,
  Card,
  Typography,
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  Button,
  Chip,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 75vh;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
`;

const ChatMain = styled(Grid)`
  display: flex;
  flex-direction: column;
`;

const ChatMessages = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: ${(props) => props.theme.spacing(2)};
`;

const ChatMessage = styled.div`
  margin: ${(props) => props.theme.spacing(2, 0)};
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-flex;
  align-items: flex-start;
  max-width: 70%;
`;

const ChatMessageTime = styled(Typography)`
  font-size: 0.75rem;
  opacity: 0.7;
  margin-top: ${(props) => props.theme.spacing(0.5)};
`;

const ChatMessageAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
  margin-right: ${(props) => props.theme.spacing(1.5)};
`;

const ChatMessageBubble = styled.div`
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.primary.main
      : props.theme.palette.background.paper};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.primary.contrastText
      : props.theme.palette.text.primary};
  border-radius: 18px;
  padding: ${(props) => props.theme.spacing(1.5, 2)};
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  margin-bottom: ${(props) => props.theme.spacing(0.5)};
`;

const ChatInput = styled(Grid)`
  padding: ${(props) => props.theme.spacing(2)};
  background: ${(props) => props.theme.palette.background.paper};
  border-top: 1px solid ${(props) => props.theme.palette.divider};
`;

const ChatSidebar = styled(Grid)`
  border-left: 1px solid ${(props) => props.theme.palette.divider};
  padding: ${(props) => props.theme.spacing(2)};
`;

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "left",
}) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        {position === "left" && <ChatMessageAvatar alt={name} src={avatar} />}
        <div>
          <ChatMessageBubble highlighted={position === "right"}>
            <ChatMessageBubbleName variant="subtitle2">
              {name}
            </ChatMessageBubbleName>
            <Typography variant="body2">{message}</Typography>
          </ChatMessageBubble>
          <ChatMessageTime variant="caption">{time}</ChatMessageTime>
        </div>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function ChatWindow() {
  const [inputType, setInputType] = useState("Open");
  const [priorityLevel, setPriorityLevel] = useState("Low");
  const [assignTo, setAssignTo] = useState("James");

  return (
    <ChatContainer container component={Card}>
      <ChatMain item xs={12} md={10}>
        <ChatMessages>
          <ChatMessageComponent
            name="Remy Sharp <johndoe@gmail.com>"
            avatar="/static/img/avatars/avatar-2.jpg"
            message="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
            time="14, Jan 2024 07:25 PM"
            position="left"
          />
          <ChatMessageComponent
            name="You <johndoe@gmail.com>"
            avatar="/static/img/avatars/avatar-1.jpg"
            message="It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
            time="14, Jan 2024 07:25 PM"
            position="right"
          />
          {/* Add more ChatMessageComponent instances as needed */}
        </ChatMessages>
        <ChatInput container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Type your message"
              multiline
              rows={3}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Status</InputLabel>
              <Select
                value={inputType}
                onChange={(e) => setInputType(e.target.value)}
                label="Status"
              >
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Pending">Pending</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
                <MenuItem value="Resolved">Resolved & Closed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Priority Level</InputLabel>
              <Select
                value={priorityLevel}
                onChange={(e) => setPriorityLevel(e.target.value)}
                label="Priority Level"
              >
                <MenuItem value="Low">Low</MenuItem>
                <MenuItem value="Medium">Medium</MenuItem>
                <MenuItem value="High">High</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Assign To</InputLabel>
              <Select
                value={assignTo}
                onChange={(e) => setAssignTo(e.target.value)}
                label="Assign To"
              >
                <MenuItem value="James">James</MenuItem>
                <MenuItem value="Niaz">Niaz</MenuItem>
                <MenuItem value="Shabbir">Shabbir</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              endIcon={<SendIcon />}
              size="large"
            >
              Submit
            </Button>
          </Grid>
        </ChatInput>
      </ChatMain>
      <ChatSidebar item xs={12} md={2}>
        <Box>
          <Typography variant="subtitle1" gutterBottom>
            Status
            <Chip label="Open" color="primary" size="small" sx={{ ml: 1 }} />
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Updated At
            <Typography variant="body2" color="textSecondary">
              14, Jan 2024 07:25 PM
            </Typography>
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Customer
            <Typography variant="body2" color="textSecondary">
              John Doe
            </Typography>
          </Typography>
        </Box>
      </ChatSidebar>
    </ChatContainer>
  );
}

function SupportTicketAdd() {
  return (
    <React.Fragment>
      <Helmet title="Add New Support Ticket" />
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Add New Support Ticket
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/widget/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/widget/support-tickets">
              Support Tickets
            </Link>
            <Typography>Add New Support Ticket</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="inherit"
            component={Link}
            to="/widget/support-tickets"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Divider my={6} />
      <ChatWindow />
    </React.Fragment>
  );
}

export default SupportTicketAdd;
