import React from "react";
import styled from "@emotion/styled";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import WaitingScreenAddComponent from "./WaitingScreenAdd";
import Drawer from "@mui/material/Drawer";

import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
  CardContent,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  FormControlLabel,
} from "@mui/material";
import {
  Add as AddIcon,
  CloudUpload as MuiCloudUpload,
} from "@mui/icons-material";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const CloudUpload = styled(MuiCloudUpload)(spacing);
const Card = styled(MuiCard)(spacing);
const CenteredContent = styled.div`
  text-align: left;
`;
const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const Divider = styled(MuiDivider)(spacing);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Stage() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
    if (open === true) {
    }
    setState(open);
  };
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Stage
          </Typography>
          <Typography>
            Upload and modify your live stage for a customised branded
            experience
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="secondary"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab label="Stage Backdrop" {...a11yProps(0)} />
          <Tab label="Waiting Screen" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
        <CustomTabPanel value={value} index={0}>
          <Grid container mb={4}>
            <Grid justifyContent="space-between" container spacing={10} mb={4}>
              <Grid item>
                <Typography variant="h5" gutterBottom display="inline">
                  Stage Backdrop
                </Typography>
                <Typography>
                  Upload and modify the backdrop of your live stage for a
                  customised branded experience
                </Typography>
              </Grid>
            </Grid>
            <Grid md={4}>
              <Typography
                variant="h6"
                gutterBottom
                display="inline"
                style={{ fontSize: "13px" }}
              >
                Upload backdrop
              </Typography>
              <br />
              <Typography style={{ fontSize: "11px" }}>
                Add an image to be applied as the stage backdrop
              </Typography>
              <Card>
                <CenteredContent>
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="contained"
                      color="inherit"
                      component="span"
                      style={{
                        objectFit: "contain",
                        backgroundPosition: "center",
                        width: "100%",
                        borderRadius: "10px",
                        height: "140px",
                      }}
                    >
                      <CloudUpload mr={2} /> Upload
                    </Button>
                  </label>
                </CenteredContent>
              </Card>
              <Typography>
                Recommended format: 1920 x 1080 px | aspect ratio: 16:9 | JPEG,
                JPG, PNG | up to 10 MB
              </Typography>
            </Grid>
            <Grid md={2}></Grid>
            <Grid md={4}>
              <Typography
                variant="h6"
                gutterBottom
                display="inline"
                style={{ fontSize: "13px" }}
              >
                Guideline Preview
              </Typography>
              <br />
              <Typography style={{ fontSize: "11px" }}>
                Click and drag to resize and define the video area.
              </Typography>
              <img
                src="/static/img/others/StageBackdrop.png"
                style={{ width: "100%" }}
                alt=""
              />
            </Grid>
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid container mb={4}>
            <Grid justifyContent="space-between" container spacing={10} mb={4}>
              <Grid item>
                <Typography variant="h5" gutterBottom display="inline">
                  Waiting Screen
                </Typography>
              </Grid>
              <Grid item>
                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={toggleDrawer(true)}
                  >
                    <AddIcon />
                    Upload Waiting Screen
                  </Button>
                  <Drawer
                    anchor="right"
                    open={state}
                    onClose={toggleDrawer(false)}
                  >
                    <WaitingScreenAddComponent />
                  </Drawer>
                </div>
              </Grid>
              <Grid style={{ paddingLeft: "40px" }}>
                <Typography>
                  Upload videos , gif or images to show on stage before the
                  session and during paused session
                </Typography>
              </Grid>
            </Grid>
            <Grid md={12} mb={2}>
              <Typography
                variant="h6"
                gutterBottom
                display="inline"
                style={{ fontSize: "13px" }}
              >
                Waiting Screen
              </Typography>
              <br />
              <Typography style={{ fontSize: "11px" }}>
                Add an image to be applied as the stage backdrop
              </Typography>
            </Grid>
            <Grid md={4} style={{ paddingRight: "10px" }}>
              <Card>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <CardMedia
                    image="/static/img/avatars/avatar-1.jpg"
                    title="Contemplative Reptile"
                  />
                </div>
                <CardContent>
                  <Typography gutterBottom variant="h4" component="h2">
                    System default
                  </Typography>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="start"
                        control={<Switch color="primary" />}
                        label="Set as default"
                        labelPlacement="start"
                        style={{ marginLeft: "0" }}
                      />
                    </FormGroup>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
            <Grid md={4} style={{ paddingRight: "10px" }}>
              <Card>
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <CardMedia
                    image="/static/img/avatars/avatar-1.jpg"
                    title="Contemplative Reptile"
                  />
                  <DeleteIcon
                    style={{ position: "absolute", top: "5px", right: "5px" }}
                  />
                </div>
                <CardContent>
                  <Typography gutterBottom variant="h4" component="h2">
                    Waiting Screen 1
                  </Typography>
                  <FormControl component="fieldset">
                    <FormGroup aria-label="position" row>
                      <FormControlLabel
                        value="start"
                        control={<Switch color="primary" />}
                        label="Set as default"
                        labelPlacement="start"
                        style={{ marginLeft: "0" }}
                      />
                    </FormGroup>
                  </FormControl>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </CustomTabPanel>
      </Box>
    </React.Fragment>
  );
}

export default Stage;
