import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import CardMedia from "@mui/material/CardMedia";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Search as SearchIcon } from "react-feather";
import { darken } from "polished";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { Formik } from "formik";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";

import { axiosInstance } from "../../../utils/axios";

import {
  Grid,
  CardActions,
  CardContent,
  Card as MuiCard,
  CardHeader as MuiCardHeader,
  Breadcrumbs as MuiBreadcrumbs,
  Paper as MuiPaper,
  Divider as MuiDivider,
  TextField as MuiTextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableRow,
  InputBase,
  FormControlLabel,
  FormHelperText,
  Snackbar,
  Alert,
} from "@mui/material";

import { tableCellClasses } from "@mui/material/TableCell";

import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";

import { spacing } from "@mui/system";

const Header = styled.div`
  padding: ${(props) => props.theme.spacing(6)} 0;
`;

const Card = styled(MuiCard)(spacing);

const CardHeader = styled(MuiCardHeader)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const EventType = (props) => {
  const { doChangeProps } = props;
  return (
    <div>
      <Header>
        <Typography variant="h3" gutterBottom align="center">
          What kind of event type is it?
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Choose the type of the event type you would like to create.
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Grid container spacing={6} alignItems="flex-end">
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="One To One"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="One To Many"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="Many To One"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="Many To Many"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card p={5}>
                <CardMedia
                  component="img"
                  height="140"
                  image="https://d2xqcdy5rl17k2.cloudfront.net/images/dashboard/event-types/modules/il-event-type-MEETUP-light.png"
                  alt="green iguana"
                />
                <CardHeader
                  title="Hybrid"
                  titleTypographyProps={{ align: "center" }}
                  pb={0}
                  pt={2}
                />
                <CardContent>
                  <Typography variant="subtitle1" align="center">
                    Host a talk or presentation with speakers to educate and
                    engage your audience.
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    onClick={(e) => {
                      doChangeProps("1");
                    }}
                  >
                    Proceed
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

const EventSubType = (props) => {
  const { doChangeProps } = props;
  return (
    <div>
      <Header>
        <Typography variant="h3" gutterBottom align="center">
          Start building your event type
        </Typography>
        <Typography variant="subtitle1" gutterBottom align="center">
          Start from scratch or pick a template to set up your event faster.
        </Typography>
      </Header>

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={12}>
          <Grid container spacing={6} alignItems="flex-end">
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/blank-f8f34e8a582e7b3b073f04f1220db88e.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader
                      title="Start with a blank event"
                      pb={0}
                      pt={2}
                    />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      {/* <Button size="small" variant="outlined">
                        Learn More Webinar
                      </Button> */}
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/expo-887d82b4e95ad9c41975bcb541c831f2.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Expos and tradeshows" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/webinar-6a4efb0e86ef06e800cbdafc1d623498.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Webinars" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/workshop-2f2fe16a2e9ca8f855b3fb92845d4a99.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Courses and workshops" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/conference-55d0d60b1a4529ac04c79cf95115f1b4.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Conferences and summits" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/meetup-99b31a509cadb02a08e0771dfa9c812b.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Meet ups" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/all-hands-089eb3be05d99d9348ad1f1157bc6e66.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Internal company event" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card p={5}>
                <Grid container>
                  <Grid md={4}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://assets-versions-cdn.static.events.ringcentral.com/assets/packs/media/react/features/event-creation-wizard/assets/templates/recruitment-b69d68ef95037ccb071a77071479178d.png"
                      alt="green iguana"
                    />
                  </Grid>
                  <Grid md={8}>
                    <CardHeader title="Recruitment fair" pb={0} pt={2} />
                    <CardContent>
                      <Typography variant="subtitle1">
                        Host a talk or presentation with speakers to educate and
                        engage your audience.
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" variant="outlined">
                        Learn More
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => {
                          doChangeProps("2");
                        }}
                      >
                        Start
                      </Button>
                    </CardActions>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

function CustomizedTableDemo() {
  return (
    <Card mb={6} mt={6}>
      <Paper>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <CustomTableRow key={row.id}>
                <CustomTableCell component="th" scope="row">
                  UTC -12:00
                </CustomTableCell>
                <CustomTableCell align="right">
                  Canada/Yukon Time
                  <br />
                  <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    (UTC-07:00) Yukon
                  </small>
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}
const Search = styled("div")`
  border-radius: 2px;
  background-color: ${(props) => darken(0.05, props.theme.header.background)};
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
`;

const SearchIconWrapper = styled("div")`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;
const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
  }
`;
const EventEntryForm = (props, alert, setAlert) => {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  return (
    <Formik
      initialValues={{
        title: "",
        url: "",
        description: "",
        duration: "",
        assignment: "Collective",
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().max(255).required("Title is required"),
        url: Yup.string().url("Enter a valid URL").required("URL is required"),
        description: Yup.string().required("Description is required"),
        duration: Yup.number()
          .min(1, "Duration must be at least 1 minute")
          .required("Duration is required"),
        assignment: Yup.string().required("Assignment type is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const response = await axiosInstance.post("/graphql", {
            query: `
              mutation {
                createEventType(input: {
                  title: "${values.title}",
                  url: "${values.url}",
                  description: "${values.description}",
                  duration: ${values.duration},
                  assignment: "${values.assignment}"
                }) {
                  eventType {
                    id
                    title
                    duration
                    description
                    url
                  }
                }
              }
            `,
          });
          navigate("/calendar/event-types", {
            state: { message: "Event Type created Successfully" },
          });

          if (response.data.errors) {
            throw new Error(response.data.errors[0].message);
          }

          // Handle successful response
          setStatus({ success: true });
          setAlert({
            open: true,
            message: "Event Type Created Successfully!",
            severity: "success",
          });
          console.log("Event Created Successfully:", response.data);
        } catch (error) {
          const errorMessage =
            error.response?.data?.message || "An unexpected error occurred";
          setStatus({ success: false });
          setErrors({ submit: errorMessage });
        } finally {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <Card mb={6}>
            <CardContent>
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="title"
                    name="title"
                    label="Title"
                    placeholder="Give your event an exciting name"
                    variant="outlined"
                    fullWidth
                    value={values.title}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="url"
                    name="url"
                    label="Url"
                    placeholder="Type your unique url"
                    variant="outlined"
                    fullWidth
                    value={values.url}
                    error={Boolean(touched.url && errors.url)}
                    helperText={touched.url && errors.url}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    my={2}
                  />
                </Grid>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormLabel>Description</FormLabel>
                <ReactQuill
                  style={{ height: 150 }}
                  theme="snow"
                  value={values.description}
                  mb={5}
                  onChange={(value) =>
                    handleChange({ target: { name: "description", value } })
                  }
                  placeholder="Type something.."
                />
                {touched.description && errors.description && (
                  <FormHelperText error>{errors.description}</FormHelperText>
                )}
                <TextField
                  id="duration"
                  name="duration"
                  label="Duration"
                  placeholder="In Minutes"
                  variant="outlined"
                  fullWidth
                  value={values.duration}
                  error={Boolean(touched.duration && errors.duration)}
                  helperText={touched.duration && errors.duration}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  my={2}
                  mt={12}
                />
              </Grid>
              <Grid item md={6}>
                <FormControl>
                  <FormLabel>Assignment</FormLabel>
                  <RadioGroup
                    aria-labelledby="assignment-radio-group-label"
                    name="assignment"
                    value={values.assignment}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Collective"
                      control={<Radio />}
                      label="Collective"
                    />
                    <FormControlLabel
                      value="Round Robin"
                      control={<Radio />}
                      label="Round Robin"
                    />
                    <FormControlLabel
                      value="Managed Event"
                      control={<Radio />}
                      label="Managed Event"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Paper mt={4}>
                <div>
                  <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="form-dialog-title"
                  >
                    <DialogTitle id="form-dialog-title">
                      Timezone
                      <br />
                      <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Set the timezone in which your event is occurring
                      </small>
                    </DialogTitle>
                    <DialogContent>
                      <Typography>
                        <Search className="searchBar">
                          <SearchIconWrapper>
                            <SearchIcon />
                          </SearchIconWrapper>
                          <Input placeholder="Search for a country or a timezone" />
                        </Search>
                      </Typography>
                      <CustomizedTableDemo />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => setOpen(false)} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </Paper>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Create Event
              </Button>
            </CardContent>
          </Card>
        </form>
      )}
    </Formik>
  );
};

function EventTypeAdd() {
  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [eventType, setEventType] = useState("0");
  return (
    <React.Fragment>
      <Helmet title="Add New Event Type" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add New Event Type
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/calendar/event-types">
              Events Type List
            </Link>
            <Typography>Add New Event Type</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/calendar/event-types"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {eventType === "0" ? (
            <EventType doChangeProps={setEventType} />
          ) : eventType === "1" ? (
            <EventSubType doChangeProps={setEventType} />
          ) : (
            <EventEntryForm alert={alert} setAlert={setAlert} />
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

export default EventTypeAdd;
