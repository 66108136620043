import React from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import Switch from "@mui/material/Switch";

import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const Divider = styled(MuiDivider)(spacing);

function Lounge() {
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Lounge
          </Typography>
          <Typography>
            The social lounge is the virtual space where the attendee can be
            seated at any table, move across the table, and interact with those
            at the same table in a mini-virtual event
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Grid item md={12}>
          <ListItem alignItems="flex-start" disablePadding>
            <Grid container>
              <Grid md={6}>
                <Typography variant="subtitle1">
                  Social Lounge
                  <Typography
                    color="success"
                    style={{ paddingLeft: "12px !important" }}
                  >
                    <small
                      style={{
                        position: "relative",
                        bottom: "4px",
                        paddingLeft: "2px",
                      }}
                    >
                      Enabled Social Lounge so that attendees can share their
                      thoughts with all the participants at the event.
                    </small>
                  </Typography>
                </Typography>
              </Grid>
              <Grid md={6} style={{ textAlign: "right" }}>
                <Switch inputProps={{ "aria-label": "controlled" }} />
              </Grid>
            </Grid>
          </ListItem>
        </Grid>
        <Grid item md={12} style={{ textAlign: "center" }}>
          <img src="/static/img/others/Lounge.png" alt="" />
        </Grid>
        <Grid item md={12} style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<OpenInNewIcon />}
          >
            Preview Lounge
          </Button>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Lounge;
