import { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import {
  Sliders,
  Briefcase,
  User,
  Calendar,
  Key,
  Users,
  Grid,
  CheckSquare,
  Map,
  Anchor,
} from "react-feather";

const useSettingsItems = () => {
  const { isAuthenticated, isInitialized, user } = useAuth();
  const [navItems, setNavItems] = useState([]);
  const savedPermissions =
    JSON.parse(localStorage.getItem("rbacPermissions")) || [];

  useEffect(() => {
    if (isInitialized) {
      if (isAuthenticated) {
        // Check if user's subscription level is 1
        if (user?.subscriptionLevel === "1") {
          // Allow access to all pages if subscription level is 1
          setNavItems([
            {
              title: "Getting Started",
              pages: [
                {
                  href: "settings",
                  icon: Sliders,
                  title: "Settings",
                  children: [
                    {
                      href: "/settings/business-settings",
                      icon: Briefcase,
                      title: "Business Settings",
                    },
                    {
                      href: "/settings/profile",
                      icon: User,
                      title: "Profile",
                    },
                    {
                      href: "/settings/change-password",
                      icon: Key,
                      title: "Change Password",
                    },
                    {
                      href: "/settings/integrations",
                      icon: Anchor,
                      title: "Integrations",
                    },
                  ],
                },
                {
                  href: "/settings/users",
                  icon: Users,
                  title: "User Management",
                },
                {
                  href: "/settings/groups",
                  icon: Grid,
                  title: "Groups",
                },
                {
                  href: "/settings/roles-permissions",
                  icon: CheckSquare,
                  title: "Roles & Permissions",
                },
                {
                  href: "/settings/space-management",
                  icon: Map,
                  title: "Space Management",
                },
              ],
            },
          ]);
        } else {
          // Build pagesSection dynamically based on savedPermissions
          const pagesSection = [
            {
              href: "settings",
              icon: Sliders,
              title: "Settings",
              children: [
                {
                  href: "/business-settings",
                  icon: Briefcase,
                  title: "Business Settings",
                },
                {
                  href: "/profile",
                  icon: User,
                  title: "Profile",
                },
                {
                  href: "/availability",
                  icon: Calendar,
                  title: "Availability",
                },
                {
                  href: "/change-password",
                  icon: Key,
                  title: "Change Password",
                },
              ],
            },
            savedPermissions.includes("VIEW_USERS") && {
              href: "/settings/users",
              icon: Users,
              title: "User Management",
            },
            savedPermissions.includes("VIEW_GROUPS") && {
              href: "/settings/groups",
              icon: Grid,
              title: "Groups",
            },
            savedPermissions.includes("VIEW_ROLES_PERMISSIONS") && {
              href: "/settings/roles-permissions",
              icon: CheckSquare,
              title: "Roles & Permissions",
            },
            savedPermissions.includes("VIEW_SPACE_MANAGEMENT") && {
              href: "/space-management",
              icon: Map,
              title: "Space Management",
            },
          ].filter(Boolean); // Remove any undefined entries

          setNavItems([
            {
              title: "Getting Started",
              pages: pagesSection,
            },
          ]);
        }
      } else {
        setNavItems([]);
      }
    }
  }, [isInitialized, isAuthenticated, user, savedPermissions]);

  return navItems;
};

export default useSettingsItems;
