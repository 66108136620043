// import axios from "axios";
//
// const axiosInstance = axios.create();
// console.log("axios init");
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     // Log the URL of the request that caused the error
//     if (error.config && error.config.url) {
//       console.log("Error Request URL:", error.config.url);
//     }
//     console.log("err", error);
//     return Promise.reject((error.response && error.response.data) || error);
//   }
// );
// export default axiosInstance;

import axios from "axios";

// Create an axios instance
const axiosInstance = axios.create({
  validateStatus: false,
  baseURL: process.env.REACT_APP_BACKEND_URI,
  timeout: 10000, // Optional timeout, in milliseconds
  headers: {
    "Content-Type": "application/json",
    Accept: "*/*, application/json, text/plain",
    // Add other default headers if needed
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Do something before the request is sent
    const token = localStorage.getItem("accessToken"); // For example, get token from local storage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Add token to headers if it exists
    }
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Any status code within the range of 2xx causes this function to trigger
    return response.data;
  },
  (error) => {
    // Any status codes outside the range of 2xx cause this function to trigger
    if (error.response && error.response.status === 401) {
      // Handle unauthorized errors, e.g., redirect to login
      console.log("Unauthorized, logging out...");
      // Optionally, you can clear the token or redirect to a login page
    }
    console.log("Error Url:", error.config.url);
    return Promise.reject(error);
  }
);

export { axiosInstance };
