// src/apolloClient.js

import { ApolloClient, InMemoryCache, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";
import { createUploadLink } from "apollo-upload-client";

// Create an upload link for queries and mutations
const uploadLink = createUploadLink({
  uri: "http://localhost:3000/graphql",
  credentials: "include", // Include credentials if needed
});

// Create an authentication link to include auth token in headers
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("accessToken");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Combine authLink and uploadLink
const httpLinkWithAuth = authLink.concat(uploadLink);

// Create a WebSocket link for subscriptions
const wsLink = new GraphQLWsLink(
  createClient({
    url: "ws://localhost:3000/graphql",
    connectionParams: () => {
      const token = localStorage.getItem("accessToken");
      return {
        authorization: token ? `Bearer ${token}` : "",
      };
    },
  })
);

// Split links based on operation type
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLinkWithAuth // Use the combined link here
);

// Initialize Apollo Client
const client = new ApolloClient({
  link: splitLink, // Use splitLink instead of uploadLink
  cache: new InMemoryCache(),
});

export default client;
