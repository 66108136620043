import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { axiosInstance } from "../../utils/axios";
import PropTypes from "prop-types";
import CalendarsComponent from "../../components/settings/Integrations/Calendars";
import ConferencesComponent from "../../components/settings/Integrations/Conferences";
import AutomationsComponent from "../../components/settings/Integrations/Automations";
import PaymentsComponent from "../../components/settings/Integrations/Payments";
import AnalyticsComponent from "../../components/settings/Integrations/Analytics";

import {
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  Link,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Integrations() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // Fetch users initially
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getGroupsWithRoles {
                      groupsWithRoles {
                        group {
                          id
                          name
                        }
                        roles {
                          id
                          name
                        }
                      }
                    }
            }
          `,
        });

        if (response.data.errors) {
          console.error(response.data.errors);
        } else {
          setGroups(response.data.getGroupsWithRoles.groupsWithRoles);
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
          query {
            getRoles {
              id
              name
            }
          }
        `,
        });

        if (response.data.errors) {
          console.error(response.data.errors);
        } else {
          setRoles(response.data.getRoles);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchGroups();
    fetchRoles();
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Groups" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Groups
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Settings
            </Link>
            <Typography>Users</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={handleClickOpen}
            >
              <AddIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Calendars" {...a11yProps(0)} />
            <Tab label="Conferences" {...a11yProps(1)} />
            <Tab label="Automations" {...a11yProps(2)} />
            <Tab label="Payments" {...a11yProps(3)} />
            <Tab label="Analytics" {...a11yProps(4)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <CalendarsComponent />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ConferencesComponent />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <AutomationsComponent />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <PaymentsComponent />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <AnalyticsComponent />
        </CustomTabPanel>
      </Box>
    </React.Fragment>
  );
}

export default Integrations;
