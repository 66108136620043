import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as Yup from "yup";
import { Formik } from "formik";
import { axiosInstance } from "../../utils/axios";

import {
  Alert,
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Checkbox,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Spacer = styled.div`
  flex: 1 1 100%;
`;
const ToolbarTitle = styled.div`
  min-width: 150px;
`;

// GraphQL Queries and Mutations
const GET_SPACES = `
  query {
    getSpaces {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const CREATE_SPACE = `
  mutation CreateSpace($input: CreateSpaceInput!) {
    createSpace(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const UPDATE_SPACE = `
  mutation UpdateSpace($id: ID!, $name: String!) {
    updateSpace(id: $id, name: $name) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;

const DELETE_SPACE = `
  mutation DeleteSpace($id: ID!) {
    deleteSpace(id: $id)
  }
`;

const formatDate = (dateString) => {
  if (!dateString) return "";

  try {
    const date = new Date(parseInt(dateString)); // If date is timestamp
    if (isNaN(date.getTime())) {
      // If timestamp parsing failed, try direct ISO string
      const isoDate = new Date(dateString);
      if (isNaN(isoDate.getTime())) return "Invalid Date";
      return new Intl.DateTimeFormat("default", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }).format(isoDate);
    }
    return new Intl.DateTimeFormat("default", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
  } catch (error) {
    console.error("Error formatting date:", error);
    return "Invalid Date";
  }
};

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const headCells = [
    { id: "id", align: "left", label: "ID" },
    { id: "name", align: "left", label: "Name" },
    { id: "createdAt", align: "left", label: "Created At" },
    { id: "actions", align: "right", label: "Actions" },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all spaces" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Spaces
          </Typography>
        )}
      </ToolbarTitle>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton>
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
}

function Spaces() {
  const [spaces, setSpaces] = useState([]);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingSpace, setEditingSpace] = useState(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  useEffect(() => {
    fetchSpaces();
  }, []);

  const fetchSpaces = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: GET_SPACES,
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setSpaces(response.data.getSpaces);
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error fetching spaces",
        severity: "error",
      });
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = spaces.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditClick = (space) => {
    setEditingSpace(space);
    setOpenDialog(true);
  };

  const handleDeleteClick = async (id) => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: DELETE_SPACE,
        variables: { id },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      setSpaces(spaces.filter((space) => space.id !== id));
      setAlert({
        open: true,
        message: "Space deleted successfully",
        severity: "success",
      });
    } catch (error) {
      setAlert({
        open: true,
        message: error.message || "Error deleting space",
        severity: "error",
      });
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingSpace(null);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, spaces.length - page * rowsPerPage);

  return (
    <React.Fragment>
      <Helmet title="Spaces" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Spaces
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/">
              Dashboard
            </Link>
            <Typography>Spaces</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            <AddIcon /> Add Space
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={spaces.length}
            />
            <TableBody>
              {spaces
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((space, index) => {
                  const isItemSelected = isSelected(space.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={space.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => handleClick(event, space.id)}
                          inputProps={{ "aria-labelledby": labelId }}
                        />
                      </TableCell>
                      <TableCell>{space.id}</TableCell>
                      <TableCell>{space.name}</TableCell>
                      <TableCell>{formatDate(space.createdAt)}</TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="primary"
                          onClick={() => handleEditClick(space)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="error"
                          onClick={() => handleDeleteClick(space.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={5} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={spaces.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {editingSpace ? "Edit Space" : "Create New Space"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {editingSpace
              ? "Edit the space details below"
              : "Enter the details for the new space"}
          </DialogContentText>
          <Formik
            initialValues={{
              name: editingSpace ? editingSpace.name : "",
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Name is required"),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              try {
                const mutation = editingSpace ? UPDATE_SPACE : CREATE_SPACE;
                const variables = editingSpace
                  ? { id: editingSpace.id, name: values.name }
                  : { input: { name: values.name } };

                const response = await axiosInstance.post("/graphql", {
                  query: mutation,
                  variables,
                });

                if (response.data.errors) {
                  throw new Error(response.data.errors[0].message);
                }

                const newSpace = editingSpace
                  ? response.data.updateSpace
                  : response.data.createSpace;

                setSpaces((prev) =>
                  editingSpace
                    ? prev.map((space) =>
                        space.id === editingSpace.id ? newSpace : space
                      )
                    : [...prev, newSpace]
                );

                setAlert({
                  open: true,
                  message: `Space ${
                    editingSpace ? "updated" : "created"
                  } successfully`,
                  severity: "success",
                });

                handleCloseDialog();
              } catch (error) {
                setAlert({
                  open: true,
                  message:
                    error.message ||
                    `Error ${editingSpace ? "updating" : "creating"} space`,
                  severity: "error",
                });
              } finally {
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <MuiTextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3 }}
                >
                  {isSubmitting
                    ? "Saving..."
                    : editingSpace
                    ? "Save Changes"
                    : "Create Space"}
                </Button>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default Spaces;
