import React from "react";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { CloudUpload as MuiCloudUpload } from "@mui/icons-material";
import { spacing } from "@mui/system";
const CloudUpload = styled(MuiCloudUpload)(spacing);
const CenteredContent = styled.div`
  text-align: left;
`;
const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
  margin-bottom: 10px;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  //   margin: 0 auto ${(props) => props.theme.spacing(2)};
`;
const Divider = styled(MuiDivider)(spacing);

function EventBranding() {
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Event Branding
          </Typography>
          <Typography>
            Define the design, theme and branding for your event
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Grid container mb={4}>
          <Grid md={7} style={{ marginRight: "10px" }}>
            <Grid item md={12} mb={2}>
              <Typography variant="h5" gutterBottom display="inline">
                Event logo
              </Typography>
              <br />
              <Typography>
                Add your own brand logo to increase your brand engagement
              </Typography>
            </Grid>
            <CenteredContent>
              <BigAvatar
                alt="Remy Sharp"
                src="/static/img/avatars/avatar-1.jpg"
              />
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="raised-button-file"
                multiple
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="primary" component="span">
                  <CloudUpload mr={2} /> Upload Logo
                </Button>
                <Typography variant="caption" display="block" gutterBottom>
                  Recommended format JPG, SVG or PNG | Up to 2 MB
                </Typography>
              </label>
            </CenteredContent>
          </Grid>
          <Grid md={4}>
            <Grid item md={12}>
              <img src="/static/img/others/EventBranding.png" alt="" />
            </Grid>
            <Grid item md={12}>
              <Button variant="contained" color="primary">
                Try Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default EventBranding;
