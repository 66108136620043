import { createContext, useEffect, useReducer } from "react";
import jwtDecode from "jwt-decode";

import { axiosInstance } from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";

import { useNavigate, useLocation } from "react-router-dom";

// Note: If you're trying to connect JWT to your own backend, don't forget
// to remove the Axios mocks in the `/src/index.js` file.

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  rbacPermissions: [],
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        rbacPermissions: action.payload.permissions,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        rbacPermissions: action.payload.permissions,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        rbacPermissions: [],
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        rbacPermissions: [],
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext(null);

function getUniquePermissions(data) {
  console.log("!!!!!", data);
  const permissions = new Set();

  // Collect permissions from roles
  data.getUser.roles.forEach((role) => {
    role.permissions.forEach((permission) => {
      permissions.add(permission);
    });
  });

  // Collect permissions from group roles
  data.getUser.groups.forEach((group) => {
    group.roles.forEach((role) => {
      role.permissions.forEach((permission) => {
        permissions.add(permission);
      });
    });
  });

  // Convert Set to Array
  return Array.from(permissions);
}

function AuthProvider({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          const decoded = jwtDecode(accessToken);
          console.log("@@@@@@@@", decoded);
          setSession(accessToken);

          // Replace this with an API call that checks if the token is still valid
          let query = "";
          if (decoded.clientId) {
            query = `
              query GetClient($id: ID!) {
              getClient(id: $id) {
                id
                name
                companyName
                email
                subscriptionLevel
                avatar
              }
            }
            `;
            const response = await axiosInstance.post("/graphql", {
              query,
              variables: {
                id: decoded.clientId,
              },
            });

            // Handle errors returned from the backend
            if (response.errors || !response.data.getClient) {
              throw new Error("Invalid token");
            }

            const user = { ...response.data.getClient };

            dispatch({
              type: INITIALIZE,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }
          if (decoded.userId) {
            query = `
              query GetUser($id: ID!) {
              getUser(id: $id) {
                name
                email
                roles {
                  name
                  permissions
                }
                groups {
                  group {
                    id
                    name
                  }
                  roles {
                    id
                    name
                    permissions
                  }
                }
              }
            }
            `;
            console.log("user Id", decoded.userId);
            const response = await axiosInstance.post("/graphql", {
              query,
              variables: {
                id: decoded.userId,
              },
            });

            // Handle errors returned from the backend
            if (response.errors || !response.data.getUser) {
              throw new Error("Invalid token");
            }

            const user = { ...response.data.getUser };
            const uniquePermissions = getUniquePermissions(response.data);
            dispatch({
              type: INITIALIZE,
              payload: {
                rbacPermissions: ["ADD_USER"],
                isAuthenticated: true,
                user,
              },
            });
          }
          if (
            location.pathname === "/auth/sign-in" ||
            location.pathname === "/auth/sign-up"
          ) {
            navigate("/private");
          }
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        const accessToken = window.localStorage.getItem("accessToken");
        console.error(",,,,", accessToken);
        console.error(err);
        setSession(null); // Clear session if an error occurs
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, [navigate]);
  // useEffect(() => {
  //   const initialize = async () => {
  //     try {
  //       const accessToken = window.localStorage.getItem("accessToken");
  //
  //       if (accessToken && isValidToken(accessToken)) {
  //         const decoded = jwtDecode(accessToken);
  //         setSession(accessToken);
  //
  //         const query = `
  //           query GetClient($id: ID!) {
  //             getClient(id: $id) {
  //               id
  //               name
  //               companyName
  //               email
  //               subscriptionLevel
  //             }
  //           }
  //         `;
  //
  //         const response = await axiosInstance.post("/graphql", {
  //           query,
  //           variables: {
  //             id: decoded.clientId,
  //           },
  //         });
  //         const user = { ...response.data.getClient };
  //
  //         dispatch({
  //           type: INITIALIZE,
  //           payload: {
  //             isAuthenticated: true,
  //             user,
  //           },
  //         });
  //
  //         navigate("/private");
  //       } else {
  //         dispatch({
  //           type: INITIALIZE,
  //           payload: {
  //             isAuthenticated: false,
  //             user: null,
  //           },
  //         });
  //       }
  //     } catch (err) {
  //       console.error(err);
  //       dispatch({
  //         type: INITIALIZE,
  //         payload: {
  //           isAuthenticated: false,
  //           user: null,
  //         },
  //       });
  //     }
  //   };
  //
  //   initialize();
  // }, [navigate]);

  // const signIn = async (email, password) => {
  //   console.log("sign in start", email);
  //   const response = await axiosInstance.post("/graphql", {
  //     query: `
  //     mutation LoginClient($input: LoginInput!) {
  //       loginClient(input: $input) {
  //         token
  //         client {
  //           id
  //           name
  //           email
  //         }
  //       }
  //     }
  //   `,
  //     variables: {
  //       input: {
  //         email,
  //         password,
  //       },
  //     },
  //   });
  //   const { token, user } = response.data;
  //
  //   setSession(token);
  //   dispatch({
  //     type: SIGN_IN,
  //     payload: {
  //       user,
  //     },
  //   });
  // };

  const signIn = async (email, password) => {
    console.log("!!!!!!!!!!!!!!!!!!! sign in", email);
    try {
      // Execute the GraphQL mutation for login
      const response = await axiosInstance.post("/graphql", {
        query: `
      mutation Login($input: LoginInput!) {
        login(input: $input) {
          __typename
          ... on AuthPayload {
            token
            client {
              id
              name
              companyName
              email
              subscriptionLevel
            }
          }
          ... on UserAuthPayload {
            token
            user {
              id
              name
              email
            }
          }
          ... on AuthError {
            message
          }
        }
      }
    `,
        variables: {
          input: {
            email,
            password,
          },
        },
      });

      // Check if the response contains errors
      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      // Destructure login from response data
      const { login } = response.data;

      // Handle the response based on the returned type
      if (!login) {
        throw new Error("Login failed: No data returned from the server.");
      }

      if (login.__typename === "AuthError") {
        throw new Error(login.message);
      }

      if (login.__typename === "AuthPayload") {
        const { token, client } = login;

        // Set the JWT token and dispatch SIGN_IN
        setSession(token);
        dispatch({
          type: SIGN_IN,
          payload: {
            user: client,
          },
        });
      }

      if (login.__typename === "UserAuthPayload") {
        const { token, user } = login;

        // Fetch additional user permissions
        const permissionsQuery = `
        query GetUser($id: ID!) {
          getUser(id: $id) {
            name
            email
            roles {
              name
              permissions
            }
            groups {
              roles {
                permissions
              }
            }
          }
        }
      `;

        const userResponse = await axiosInstance.post("/graphql", {
          query: permissionsQuery,
          variables: {
            id: user.id,
          },
        });

        // Handle possible errors in the user query response
        if (userResponse.errors) {
          throw new Error(userResponse.errors[0].message);
        }

        // Extract the user and calculate unique permissions
        const userData = userResponse.data.getUser;
        const uniquePermissions = getUniquePermissions(userData);

        // Store RBAC permissions locally
        localStorage.setItem(
          "rbacPermissions",
          JSON.stringify(uniquePermissions)
        );

        // Set the JWT token and dispatch SIGN_IN
        setSession(token);
        dispatch({
          type: SIGN_IN,
          payload: {
            user: userData,
            permissions: uniquePermissions,
          },
        });
      }
    } catch (error) {
      let errorMessage = "An unexpected error occurred";

      if (error.response) {
        // Server responded with a status other than 2xx
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        // Request was made but no response was received
        errorMessage = "No response from server";
      } else {
        // Something happened in setting up the request
        errorMessage = error.message || errorMessage;
      }

      // Handle the error accordingly (e.g., show an alert)
      throw new Error(errorMessage);
    }
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  // const signUp = async (
  //   name,
  //   email,
  //   password,
  //   companyName,
  //   subscriptionLevel
  // ) => {
  //   const response = await axiosInstance.post("/graphql", {
  //     email,
  //     password,
  //     name,
  //     companyName,
  //     subscriptionLevel,
  //   });
  //   const { accessToken, user } = response.data;
  //
  //   window.localStorage.setItem("accessToken", accessToken);
  //   dispatch({
  //     type: SIGN_UP,
  //     payload: {
  //       user,
  //     },
  //   });
  // };

  const signUp = async (name, email, password, companyName) => {
    const subscriptionLevel = "1";
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
      mutation RegisterClient($input: ClientInput!) {
        registerClient(input: $input) {
          token
          client {
            id
            name
            email
          }
        }
      }
    `,
        variables: {
          input: {
            name,
            email,
            password,
            companyName,
            subscriptionLevel,
          },
        },
      });

      if (response.errors) {
        throw new Error(response.errors[0].message);
      }

      const { token, client } = response.data.registerClient;

      setSession(token);
      dispatch({
        type: SIGN_UP,
        payload: {
          user: client,
        },
      });
    } catch (error) {
      let errorMessage = "An unexpected error occurred";

      if (error.response) {
        // Server responded with a status other than 2xx
        errorMessage = error.response.data.message || errorMessage;
      } else if (error.request) {
        // Request was made but no response was received
        errorMessage = "No response from server";
      } else {
        // Something happened in setting up the request
        errorMessage = error.message || errorMessage;
      }

      throw new Error(errorMessage);
    }
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        signIn,
        signOut,
        signUp,
        resetPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
