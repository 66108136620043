import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  List,
  ListItem,
  FormControlLabel,
} from "@mui/material";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

function App() {
  const [token, setToken] = useState(null);
  const [calendars, setCalendars] = useState([]);
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [events, setEvents] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const connectGoogle = () => {
    window.location.href = "http://localhost:5000/auth/google";
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    const calendarsParam = urlParams.get("calendars");

    if (tokenParam) {
      setToken(tokenParam);
      fetchCalendars(tokenParam);

      if (calendarsParam) {
        const calendarIds = calendarsParam.split(",");
        setSelectedCalendars(calendarIds);
        fetchEvents(tokenParam, calendarIds);
      } else {
        setDialogOpen(true);
      }
    }
  }, []);

  const fetchCalendars = async (token) => {
    const response = await axios.get(
      `https://www.googleapis.com/calendar/v3/users/me/calendarList`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setCalendars(response.data.items);
  };

  const handleCalendarSelect = (calendarId) => {
    setSelectedCalendars((prevSelected) =>
      prevSelected.includes(calendarId)
        ? prevSelected.filter((id) => id !== calendarId)
        : [...prevSelected, calendarId]
    );
  };

  const saveCalendars = () => {
    const calendarQuery = selectedCalendars.join(",");
    const newUrl = new URL(window.location.href);
    newUrl.searchParams.set("calendars", calendarQuery);
    window.history.pushState({}, "", newUrl);

    fetchEvents(token, selectedCalendars);
    setDialogOpen(false);
  };

  const fetchEvents = async (token, calendarIds) => {
    const eventsData = [];
    for (let calendarId of calendarIds) {
      const response = await axios.get(
        `https://www.googleapis.com/calendar/v3/calendars/${calendarId}/events`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      eventsData.push(
        ...response.data.items.map((event) => ({
          title: event.summary,
          start: event.start.dateTime || event.start.date,
          end: event.end.dateTime || event.end.date,
        }))
      );
    }
    setEvents(eventsData);
  };

  return (
    <div className="App">
      <h1>Google Calendar Integration</h1>
      {!token ? (
        <Button variant="contained" onClick={connectGoogle}>
          Connect with Google
        </Button>
      ) : (
        <>
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>Select Calendars</DialogTitle>
            <DialogContent>
              <List>
                {calendars.map((calendar) => (
                  <ListItem key={calendar.id}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCalendars.includes(calendar.id)}
                          onChange={() => handleCalendarSelect(calendar.id)}
                        />
                      }
                      label={calendar.summary}
                    />
                  </ListItem>
                ))}
              </List>
            </DialogContent>
            <DialogActions>
              <Button onClick={saveCalendars} variant="contained">
                Save
              </Button>
            </DialogActions>
          </Dialog>

          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            events={events}
          />
        </>
      )}
    </div>
  );
}

export default App;
