import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../utils/axios";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Typography,
  Button,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  TextField as MuiTextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Link,
  Snackbar,
  Alert as MuiAlert,
} from "@mui/material";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Helmet } from "react-helmet-async";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Alert = MuiAlert;

const CREATE_EVENT_MUTATION = `
  mutation CreateEvent($input: EventInput!) {
    createEvent(input: $input) {
      id
      name
      startDate
      endDate
      startTime
      endTime
      timezone
      about
      organizedBy
      supportEmail
    }
  }
`;

const validationSchema = Yup.object({
  name: Yup.string().required("Event name is required"),
  startDate: Yup.date().required("Start date is required"),
  endDate: Yup.date().required("End date is required"),
  startTime: Yup.date().required("Start time is required"),
  endTime: Yup.date().required("End time is required"),
  timezone: Yup.string().required("Timezone is required"),
  organizedBy: Yup.string().required("Organizer name is required"),
  supportEmail: Yup.string()
    .email("Invalid email")
    .required("Email is required"),
});

const timezones = [
  { label: "Asia/Kolkata", value: "Asia/Kolkata" },
  { label: "America/New_York", value: "America/New_York" },
  { label: "Europe/London", value: "Europe/London" },
];

const EventEntryForm = () => {
  const navigate = useNavigate(); // useNavigate for redirection
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      timezone: "Asia/Kolkata",
      about: "",
      organizedBy: "",
      supportEmail: "",
      calendar: "google",
    },
    validationSchema,
    onSubmit: async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        const {
          name,
          startDate,
          endDate,
          startTime,
          endTime,
          timezone,
          calendar,
          about,
          organizedBy,
          supportEmail,
        } = values;

        // Axios request to GraphQL API
        const response = await axiosInstance.post("/graphql", {
          query: CREATE_EVENT_MUTATION,
          variables: {
            input: {
              name,
              startDate,
              endDate,
              startTime,
              endTime,
              timezone,
              about,
              organizedBy,
              supportEmail,
            },
          },
        });

        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }

        navigate("/events/events", {
          state: {
            message: "Event Created Successfully!",
            severity: "success",
          },
        });
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "An unexpected error occurred";
        setAlert({
          open: true,
          message: errorMessage,
          severity: "error",
        });
        setStatus({ success: false });
        setErrors({ submit: errorMessage });
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <MuiCard>
      <CardContent>
        <form onSubmit={formik.handleSubmit}>
          <Typography variant="h6" gutterBottom>
            Basic Information
          </Typography>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <MuiTextField
                label="Event Name"
                name="name"
                variant="outlined"
                fullWidth
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="Start Date"
                value={formik.values.startDate}
                onChange={(value) => formik.setFieldValue("startDate", value)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    fullWidth
                    error={
                      formik.touched.startDate &&
                      Boolean(formik.errors.startDate)
                    }
                    helperText={
                      formik.touched.startDate && formik.errors.startDate
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <DatePicker
                label="End Date"
                value={formik.values.endDate}
                onChange={(value) => formik.setFieldValue("endDate", value)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    fullWidth
                    error={
                      formik.touched.endDate && Boolean(formik.errors.endDate)
                    }
                    helperText={formik.touched.endDate && formik.errors.endDate}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TimePicker
                label="Start Time"
                value={formik.values.startTime}
                onChange={(value) => formik.setFieldValue("startTime", value)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    fullWidth
                    error={
                      formik.touched.startTime &&
                      Boolean(formik.errors.startTime)
                    }
                    helperText={
                      formik.touched.startTime && formik.errors.startTime
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TimePicker
                label="End Time"
                value={formik.values.endTime}
                onChange={(value) => formik.setFieldValue("endTime", value)}
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    fullWidth
                    error={
                      formik.touched.endTime && Boolean(formik.errors.endTime)
                    }
                    helperText={formik.touched.endTime && formik.errors.endTime}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Timezone</InputLabel>
                <Select
                  label="Timezone"
                  name="timezone"
                  value={formik.values.timezone}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.timezone && Boolean(formik.errors.timezone)
                  }
                  helperText={formik.touched.timezone && formik.errors.timezone}
                >
                  {timezones.map((zone) => (
                    <MenuItem key={zone.value} value={zone.value}>
                      {zone.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" gutterBottom>
                About the event
              </Typography>
              <ReactQuill
                theme="snow"
                value={formik.values.about}
                onChange={(value) => formik.setFieldValue("about", value)}
              />
            </Grid>

            <Grid item xs={12}>
              <MuiTextField
                label="Organized By"
                name="organizedBy"
                variant="outlined"
                fullWidth
                value={formik.values.organizedBy}
                onChange={formik.handleChange}
                error={
                  formik.touched.organizedBy &&
                  Boolean(formik.errors.organizedBy)
                }
                helperText={
                  formik.touched.organizedBy && formik.errors.organizedBy
                }
              />
            </Grid>

            <Grid item xs={12}>
              <MuiTextField
                label="Support Email"
                name="supportEmail"
                variant="outlined"
                fullWidth
                value={formik.values.supportEmail}
                onChange={formik.handleChange}
                error={
                  formik.touched.supportEmail &&
                  Boolean(formik.errors.supportEmail)
                }
                helperText={
                  formik.touched.supportEmail && formik.errors.supportEmail
                }
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Calendar Sync</InputLabel>
                <Select
                  label="Calendar"
                  name="calendar"
                  value={formik.values.calendar}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.calendar && Boolean(formik.errors.calendar)
                  }
                  helperText={formik.touched.calendar && formik.errors.calendar}
                >
                  <MenuItem key="1" value="google">
                    Google
                  </MenuItem>
                  <MenuItem key="1" value="microsoft">
                    Microsoft
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                color="primary"
                variant="contained"
                size="small"
                type="submit"
              >
                Create Event
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>

      {/* Alert Notification */}
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.severity}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </MuiCard>
  );
};

const EventAdd = () => {
  return (
    <React.Fragment>
      <Helmet title="Add New Event" />
      <Grid container justifyContent="space-between" spacing={3}>
        <Grid item>
          <Typography variant="h3" gutterBottom>
            Add New Event
          </Typography>
          <Link href="/events/events">Back to Events</Link>
        </Grid>
      </Grid>
      <MuiDivider />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EventEntryForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default EventAdd;
