import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Paper,
  Alert,
  Checkbox,
  IconButton,
  Tooltip,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const ChatWindow = styled(Paper)`
  width: 300px;
  height: 450px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const ChatHeader = styled(Box)`
  background-color: #3498db;
  color: white;
  padding: 12px;
  font-weight: bold;
  font-size: 1rem;
`;

const ChatBody = styled(Box)`
  flex-grow: 1;
  padding: 12px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const SectionTitle = styled(Typography)`
  margin-top: 16px;
  margin-bottom: 8px;
  font-weight: 500;
`;

const FormFieldItem = styled(ListItem)`
  padding: 0;
  margin-bottom: 8px;
`;

function ContactForm({ handleChildData }) {
  const [unavailableMessage, setUnavailableMessage] = useState(
    "We are sorry, none of our agents are available right now. You are welcome to leave your email and we will contact you as soon as possible."
  );
  const [backgroundColor, setBackgroundColor] = useState("#f0f4f8");
  const [fontColor, setFontColor] = useState("#000000");
  const [enablePrivacy, setEnablePrivacy] = useState(false);
  const [privacyText, setPrivacyText] = useState("Privacy Information Text");
  const [formFields, setFormFields] = useState([
    { name: "Name", enabled: true, required: true },
    { name: "Email", enabled: true, required: true },
    { name: "Message", enabled: true, required: true },
  ]);

  const handleFormFieldChange = (index, field, value) => {
    const updatedFields = [...formFields];
    updatedFields[index][field] = value;
    setFormFields(updatedFields);
  };

  const addFormField = () => {
    setFormFields([
      ...formFields,
      { name: "New Field", enabled: true, required: false },
    ]);
  };

  const removeFormField = (index) => {
    const updatedFields = formFields.filter((_, i) => i !== index);
    setFormFields(updatedFields);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Contact Form Configuration
        </Typography>
        <Alert severity="info" style={{ marginBottom: "1rem" }}>
          Customize your contact form settings below. Changes will be reflected
          in the preview.
        </Alert>
      </Grid>
      <Grid item md={7}>
        <StyledPaper elevation={2}>
          <SectionTitle variant="subtitle1">General Settings</SectionTitle>
          <TextField
            fullWidth
            multiline
            rows={2}
            label="Unavailable Message"
            value={unavailableMessage}
            onChange={(e) => setUnavailableMessage(e.target.value)}
            margin="dense"
          />
          <Box display="flex" justifyContent="space-between" mt={1}>
            <MuiColorInput
              format="hex"
              value={backgroundColor}
              onChange={setBackgroundColor}
              label="Background"
              style={{ width: "48%" }}
            />
            <MuiColorInput
              format="hex"
              value={fontColor}
              onChange={setFontColor}
              label="Font Color"
              style={{ width: "48%" }}
            />
          </Box>
        </StyledPaper>

        <StyledPaper elevation={2}>
          <SectionTitle variant="subtitle1">Contact Form Fields</SectionTitle>
          <List>
            {formFields.map((field, index) => (
              <FormFieldItem key={index}>
                <ListItemText>
                  <TextField
                    value={field.name}
                    onChange={(e) =>
                      handleFormFieldChange(index, "name", e.target.value)
                    }
                    size="small"
                    fullWidth
                  />
                </ListItemText>
                <ListItemSecondaryAction>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={field.enabled}
                        onChange={(e) =>
                          handleFormFieldChange(
                            index,
                            "enabled",
                            e.target.checked
                          )
                        }
                        size="small"
                      />
                    }
                    label="Enabled"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.required}
                        onChange={(e) =>
                          handleFormFieldChange(
                            index,
                            "required",
                            e.target.checked
                          )
                        }
                        size="small"
                      />
                    }
                    label="Required"
                  />
                  <Tooltip title="Remove field">
                    <IconButton
                      edge="end"
                      onClick={() => removeFormField(index)}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </FormFieldItem>
            ))}
          </List>
          <Button
            startIcon={<AddIcon />}
            onClick={addFormField}
            variant="outlined"
            size="small"
            fullWidth
          >
            Add Field
          </Button>
        </StyledPaper>

        <StyledPaper elevation={2}>
          <SectionTitle variant="subtitle1">Privacy Information</SectionTitle>
          <FormControlLabel
            control={
              <Switch
                checked={enablePrivacy}
                onChange={(e) => setEnablePrivacy(e.target.checked)}
              />
            }
            label="Enable Privacy Information"
          />
          {enablePrivacy && (
            <Box mt={1}>
              <ReactQuill
                theme="snow"
                value={privacyText}
                onChange={setPrivacyText}
                style={{ height: "100px" }}
              />
            </Box>
          )}
        </StyledPaper>
      </Grid>
      <Grid item md={5}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          position="sticky"
          top={20}
        >
          <ChatWindow>
            <ChatHeader>Contact Form</ChatHeader>
            <ChatBody style={{ backgroundColor, color: fontColor }}>
              <Typography variant="body2" gutterBottom>
                {unavailableMessage}
              </Typography>
              <Box mt={2}>
                {formFields.map(
                  (field, index) =>
                    field.enabled && (
                      <TextField
                        key={index}
                        label={`${field.name}${field.required ? " *" : ""}`}
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="dense"
                      />
                    )
                )}
              </Box>
              {enablePrivacy && (
                <Typography
                  variant="caption"
                  dangerouslySetInnerHTML={{ __html: privacyText }}
                  style={{ marginTop: "8px" }}
                />
              )}
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "16px" }}
              >
                Submit
              </Button>
            </ChatBody>
          </ChatWindow>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ContactForm;
