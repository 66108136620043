import React from "react";
import styled from "@emotion/styled";
import ListItem from "@mui/material/ListItem";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Drawer from "@mui/material/Drawer";
import TiersAddComponent from "./TiersAdd";
import SponsorAddComponent from "./SponsorAdd";
import SponsorListComponent from "./SponsorList";

import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

function Sponsors() {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
    if (open === true) {
    }
    setState(open);
  };

  const [stateSponsor, setStateSponsor] = React.useState(false);

  const toggleSponsor = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
    if (open === true) {
    }
    setStateSponsor(open);
  };
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sponsors
          </Typography>
          <Typography> Manage sponsors for your event </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="outlined"
              color="primary"
              onClick={toggleDrawer(true)}
              style={{ marginRight: "10px" }}
            >
              <EditIcon />
              Manage Tiers
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={toggleSponsor(true)}
            >
              <AddIcon />
              New Sponsor
            </Button>
            <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
              <TiersAddComponent />
            </Drawer>
            <Drawer
              anchor="right"
              open={stateSponsor}
              onClose={toggleSponsor(false)}
            >
              <SponsorAddComponent />
            </Drawer>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Grid item md={12}>
          <ListItem alignItems="flex-start" disablePadding>
            <Grid container>
              <Grid md={6}>
                <Typography variant="h5">Tiers</Typography>
              </Grid>
            </Grid>
          </ListItem>
        </Grid>
        <Grid item md={12} mt={2}>
          <Stack direction="row" spacing={1}>
            <Chip label="1. Gold" variant="outlined" />
            <Chip label="2. Silver" variant="outlined" />
            <Chip label="3. Bronze" variant="outlined" />
          </Stack>
        </Grid>
        <Divider my={6} />
        <SponsorListComponent />
      </Box>
    </React.Fragment>
  );
}

export default Sponsors;
