import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Paper,
  Rating,
  Alert,
  Radio,
  RadioGroup,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";

const ChatWindow = styled(Paper)`
  width: 350px;
  height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
`;

const ChatHeader = styled(Box)`
  background-color: #3498db;
  color: white;
  padding: 15px;
  font-weight: bold;
  font-size: 1.1rem;
`;

const ChatBody = styled(Box)`
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const FeedbackTextArea = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
  .MuiOutlinedInput-root {
    background-color: white;
    border-radius: 18px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 10px;
  border-radius: 18px;
`;

function SessionEndScreen({ handleChildData }) {
  const [title, setTitle] = useState("Thank you!");
  const [closingMessage, setClosingMessage] = useState(
    "I hope I could help you ..."
  );
  const [backgroundColor, setBackgroundColor] = useState("#f0f4f8");
  const [fontColor, setFontColor] = useState("#000000");
  const [showFeedbackForm, setShowFeedbackForm] = useState(true);
  const [question1, setQuestion1] = useState("");
  const [question2, setQuestion2] = useState("");
  const [question3, setQuestion3] = useState("");
  const [allowMessageFeedback, setAllowMessageFeedback] = useState(true);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Get Feedback from your customer
        </Typography>
        <Alert
          severity="info"
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        >
          Please create all your texts in English or leave the default texts.
          You can manage the translations here Translations. We always show the
          widget in the browser language the user has chosen. If this is not
          available, English will be default.
        </Alert>
      </Grid>
      <Grid item md={6}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Title closing message"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <TextField
              label="Text closing message"
              multiline
              rows={3}
              value={closingMessage}
              onChange={(e) => setClosingMessage(e.target.value)}
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <MuiColorInput
              format="hex"
              value={backgroundColor}
              onChange={setBackgroundColor}
              label="Background Color"
            />
          </FormControl>
          <FormControl fullWidth margin="normal">
            <MuiColorInput
              format="hex"
              value={fontColor}
              onChange={setFontColor}
              label="Font Color"
            />
          </FormControl>
          <FormControlLabel
            control={
              <Switch
                checked={showFeedbackForm}
                onChange={(e) => setShowFeedbackForm(e.target.checked)}
              />
            }
            label="Feedback Form"
          />
          {showFeedbackForm && (
            <>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Question 1"
                  value={question1}
                  onChange={(e) => setQuestion1(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Question 2"
                  value={question2}
                  onChange={(e) => setQuestion2(e.target.value)}
                />
              </FormControl>
              <FormControl fullWidth margin="normal">
                <TextField
                  label="Question 3"
                  value={question3}
                  onChange={(e) => setQuestion3(e.target.value)}
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Switch
                    checked={allowMessageFeedback}
                    onChange={(e) => setAllowMessageFeedback(e.target.checked)}
                  />
                }
                label="Option for sending Feedback via message"
              />
            </>
          )}
        </Paper>
      </Grid>
      <Grid item md={6}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          height="100%"
        >
          <ChatWindow>
            <ChatHeader>Support Chat</ChatHeader>
            <ChatBody style={{ backgroundColor, color: fontColor }}>
              <Box mb={3} textAlign="center">
                {title && (
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{ color: fontColor, fontWeight: "bold" }}
                  >
                    {title}
                  </Typography>
                )}
                {closingMessage && (
                  <Typography variant="body1" style={{ color: fontColor }}>
                    {closingMessage}
                  </Typography>
                )}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={3}
              >
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: fontColor }}
                >
                  How would you rate your experience?
                </Typography>
                <Rating name="feedback-rating" defaultValue={0} size="large" />
              </Box>
              {showFeedbackForm && (
                <Box mb={3}>
                  {[question1, question2, question3]
                    .filter(Boolean)
                    .map((question, index) => (
                      <Box key={index} mb={2}>
                        <Typography
                          variant="body2"
                          style={{ color: fontColor, fontWeight: "bold" }}
                        >
                          {question}
                        </Typography>
                        <RadioGroup row>
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </Box>
                    ))}
                </Box>
              )}
              {(showFeedbackForm || allowMessageFeedback) && (
                <Box>
                  <Typography
                    variant="body2"
                    gutterBottom
                    style={{ color: fontColor, fontWeight: "bold" }}
                  >
                    Please leave your feedback:
                  </Typography>
                  <FeedbackTextArea
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder="Type your feedback here..."
                    fullWidth
                  />
                  <SubmitButton variant="contained" color="primary" fullWidth>
                    Submit Feedback
                  </SubmitButton>
                </Box>
              )}
            </ChatBody>
          </ChatWindow>
        </Box>
      </Grid>
    </Grid>
  );
}

export default SessionEndScreen;
