import React, { createContext, useContext, useReducer, useEffect } from "react";
import useAuth from "../hooks/useAuth"; // Adjust path as needed

// Action Types
const UPDATE_USER = "UPDATE_USER";
const CLEAR_USER = "CLEAR_USER";
const SET_INITIAL_USER = "SET_INITIAL_USER";

// Initial State
const initialState = {
  user: null,
  isInitialized: false,
};

// Reducer
const userReducer = (state, action) => {
  switch (action.type) {
    case SET_INITIAL_USER:
      return {
        ...state,
        user: action.payload,
        isInitialized: true,
      };
    case UPDATE_USER:
      return {
        ...state,
        user: {
          ...(state.user || {}), // Ensure we have an object to spread
          ...action.payload,
        },
      };
    case CLEAR_USER:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
};

// Create Context
const UserContext = createContext(null);

// Context Provider Component
export const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userReducer, initialState);
  const { user: authUser, isAuthenticated } = useAuth();

  // Initialize user data from auth context
  useEffect(() => {
    if (isAuthenticated && authUser) {
      dispatch({
        type: SET_INITIAL_USER,
        payload: authUser,
      });
    }
  }, [authUser, isAuthenticated]);

  // Update user info
  const updateUser = (userData) => {
    console.log("Updating user with:", userData);
    dispatch({
      type: UPDATE_USER,
      payload: userData,
    });
  };

  // Clear user info
  const clearUser = () => {
    dispatch({
      type: CLEAR_USER,
    });
  };

  console.log("Current UserContext State:", state);

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        isInitialized: state.isInitialized,
        updateUser,
        clearUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use user context
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
