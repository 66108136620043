import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik, Field, Form } from "formik";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  TextField,
  FormHelperText,
} from "@mui/material";
import { spacing } from "@mui/system";
import { axiosInstance } from "../../utils/axios";

const Card = styled(MuiCard)(spacing);
const Button = styled(MuiButton)(spacing);
const PermissionsGroup = styled(Box)({
  marginBottom: "1rem",
  padding: "0.5rem",
  border: "1px solid #ddd",
  borderRadius: "4px",
  backgroundColor: "#f9f9f9",
});
const PermissionItem = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginBottom: "0.5rem",
});
const PermissionLabel = styled(Typography)({
  marginLeft: "0.5rem",
});

const initialValues = {
  roleName: "",
  permissions: {},
};

function RolesAdd() {
  const navigate = useNavigate();
  const [permissionsData, setPermissionsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query GetPermissions {
              getPermissions {
                id
                name
                childrens
              }
            }
          `,
        });

        setPermissionsData(response.data.getPermissions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching permissions:", error);
      }
    };

    fetchPermissions();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <React.Fragment>
      <Helmet title="Add New Role" />
      <Typography variant="h3" gutterBottom>
        Add New Role
      </Typography>
      <Card mb={6}>
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              roleName: Yup.string().required("Role Name is required"),
              permissions: Yup.object().test(
                "at-least-one-selected",
                "At least one permission must be selected",
                (permissions) => {
                  // Check if any of the permissions or their children are selected
                  return Object.values(permissions).some(
                    (value) => value === true
                  );
                }
              ),
            })}
            onSubmit={async (values, { setSubmitting }) => {
              setLoading(true);
              const selectedPermissions = Object.entries(values.permissions)
                .filter(([key, value]) => value)
                .map(([key]) => key);
              try {
                const response = await axiosInstance.post("/graphql", {
                  query: `
                    mutation CreateRole($input: CreateRoleInput!) {
                      createRole(input: $input) {
                        id
                        name
                        permissions
                      }
                    }
                  `,
                  variables: {
                    input: {
                      name: values.roleName,
                      permissions: selectedPermissions,
                    },
                  },
                });

                console.log("Role created:", response.data.createRole);
                setSubmitting(false);
                setLoading(true);
                navigate("/settings/roles-permissions");
              } catch (error) {
                console.error("Error creating role:", error);
                setSubmitting(false);
              }
            }}
          >
            {({
              values,
              handleChange,
              setFieldValue,
              handleBlur,
              isSubmitting,
              errors,
              touched,
            }) => (
              <Form>
                <Grid container spacing={6}>
                  <Grid item md={6}>
                    <Field
                      name="roleName"
                      as={TextField}
                      label="Role Name"
                      fullWidth
                      variant="outlined"
                      value={values.roleName}
                      error={Boolean(touched.roleName && errors.roleName)}
                      helperText={touched.roleName && errors.roleName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Grid>
                </Grid>
                {errors.permissions && touched.permissions ? (
                  <FormHelperText error>{errors.permissions}</FormHelperText>
                ) : null}
                <Box mt={4}>
                  {permissionsData.map((parent) => (
                    <PermissionsGroup key={parent.id}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={parent.childrens.every(
                              (child) => values.permissions[child]
                            )}
                            onChange={(e) => {
                              parent.childrens.forEach((child) =>
                                setFieldValue(
                                  `permissions.${child}`,
                                  e.target.checked
                                )
                              );
                            }}
                          />
                        }
                        label={parent.name}
                      />
                      <Box pl={4}>
                        {parent.childrens.map((child) => (
                          <PermissionItem key={child}>
                            <Checkbox
                              checked={Boolean(values.permissions[child])}
                              onChange={handleChange}
                              name={`permissions.${child}`}
                            />
                            <PermissionLabel>{child}</PermissionLabel>
                          </PermissionItem>
                        ))}
                      </Box>
                    </PermissionsGroup>
                  ))}
                </Box>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  mt={3}
                >
                  Add New Role
                </Button>
              </Form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default RolesAdd;
