import React, { useState } from "react";
import styled from "@emotion/styled";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import "../../../Styles/Custom.css";
import Switch from "@mui/material/Switch";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import { MuiColorInput } from "mui-color-input";
import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  TableCell,
  TableRow,
  Typography,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
  Alert as MuiAlert,
} from "@mui/material";

import { green, orange } from "@mui/material/colors";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
  CloudUpload as MuiCloudUpload,
  FileUpload,
  Videocam,
  InsertPhoto,
} from "@mui/icons-material";

import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material/TableCell";
import { spacing } from "@mui/system";

import Code from "../../../components/Code";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;
const CloudUpload = styled(MuiCloudUpload)(spacing);
const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 200px;
  }
`;
const CenteredContent = styled.div`
  text-align: center;
`;
const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;
// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

function SessionEndScreen({ handleChildData }) {
  const [ButtonStyle, setButtonStyle] = React.useState("Button");
  const [Mode, setMode] = React.useState("Standard");
  const sendDataToParent = (d) => {
    handleChildData(d);
  };

  const [IsCustomizeableButton, setIsCustomizeableButton] = useState("false");
  const handleCustomizeableButtonChange = (event) => {
    setIsCustomizeableButton(JSON.stringify(event.target.checked));
  };
  const [value, setValue] = React.useState("#ffffff");

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  return (
    <Grid justifyContent="space-between" container>
      <Grid item md={12}>
        <Typography variant="h3" gutterBottom>
          End screen
        </Typography>
        <Alert mt={3} mb={3} severity="info">
          Please create all your texts in English or leave the default texts.
          You can manage the translations here Translations. We always show the
          widget in the browser language the user has chosen. If this is not
          available, English will be default.
        </Alert>
        <Grid container spacing={6}>
          <Grid item md={5} style={{ marginBottom: "15px" }}>
            {/* <Typography variant="h6" gutterBottom>
              1. Button + Text
            </Typography> */}
            <Box mb={4} mt={4}>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label="Title closing message"
                    id="biography"
                    variant="outlined"
                    defaultValue="Thank you!"
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <TextField
                    label="Text closing message"
                    id="biography"
                    multiline={true}
                    rows={3}
                    maxRows={4}
                    variant="outlined"
                    defaultValue="I hope I could help you ..."
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <MuiColorInput
                    format="hex"
                    value={value}
                    onChange={handleChange}
                    label="Background Color"
                    id="biography"
                    variant="outlined"
                    defaultValue=""
                  />
                </FormControl>
              </Grid>
              <Grid item md={12} mb={4}>
                <FormControl fullWidth my={2} variant="outlined">
                  <MuiColorInput
                    format="hex"
                    value={value}
                    onChange={handleChange}
                    label="Font Color"
                    id="biography"
                    variant="outlined"
                    defaultValue=""
                  />
                </FormControl>
              </Grid>
              <Grid item md={12}>
                <InputLabel>
                  Feedback Form
                  <FormControlLabel
                    label=""
                    control={
                      <Switch onChange={handleCustomizeableButtonChange} />
                    }
                    style={{ marginLeft: "0px" }}
                  />
                </InputLabel>
              </Grid>
              {IsCustomizeableButton == "true" ? (
                <React.Fragment>
                  <Grid item md={12} mb={4}>
                    <FormControl fullWidth my={2} variant="outlined">
                      <TextField
                        label="Question 1"
                        id="biography"
                        variant="outlined"
                        defaultValue=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} mb={4}>
                    <FormControl fullWidth my={2} variant="outlined">
                      <TextField
                        label="Question 2"
                        id="biography"
                        variant="outlined"
                        defaultValue=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12} mb={4}>
                    <FormControl fullWidth my={2} variant="outlined">
                      <TextField
                        label="Question 3"
                        id="biography"
                        variant="outlined"
                        defaultValue=""
                      />
                    </FormControl>
                  </Grid>
                  <Grid item md={12}>
                    <InputLabel>
                      Option for sending Feedback via message
                      <FormControlLabel
                        label=""
                        control={<Switch />}
                        style={{ marginLeft: "0px" }}
                      />
                    </InputLabel>
                  </Grid>
                </React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </Box>
          </Grid>
          <Grid item md={3} style={{ marginBottom: "15px" }}>
            <Typography variant="h6" gutterBottom>
              Preview
            </Typography>
            <CardMedia
              component="img"
              image="/static/img/others/SessionEndScreen.png"
              alt="Paella dish"
              style={{
                objectFit: "contain",
                backgroundPosition: "center",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SessionEndScreen;
