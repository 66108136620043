import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { axiosInstance } from "../../utils/axios";

import {
  Button,
  Paper,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Typography,
  MenuItem,
  DialogActions,
  TextField as MuiTextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const PaperStyled = styled(Paper)(spacing);

function createData(
  customer,
  customerEmail,
  customerAvatar,
  status,
  amount,
  id,
  date
) {
  return { customer, customerEmail, customerAvatar, status, amount, id, date };
}

// Initialize the rows state
const initialRows = [
  createData(
    "Aanna Walley",
    "anna@walley.com",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  // ... (keep all the initial data)
];

// Define table columns
const headCells = [
  { id: "name", alignment: "left", label: "Name" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "jobTitle", alignment: "left", label: "Job Title" },
  { id: "organization", alignment: "left", label: "Organization" },
  { id: "city", alignment: "left", label: "City" },
  { id: "country", alignment: "left", label: "Country" },
];

// Table header component
const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

// Main table component
const EnhancedTable = ({ rows, setRows }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("name");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <PaperStyled>
      <TableContainer>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.id}>
                  <TableCell>{`${row.firstName} ${row.lastName}`}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.jobTitle}</TableCell>
                  <TableCell>{row.organization}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{row.country}</TableCell>
                </TableRow>
              ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </PaperStyled>
  );
};

function People({ type }) {
  const [open, setOpen] = useState(false);

  const [rows, setRows] = useState(initialRows);

  // Fetch data based on the selected type
  useEffect(() => {
    const fetchData = async () => {
      try {
        const query = `
          query {
            getAllAttendees(type: ${type.toUpperCase()}) {
              id
              firstName
              lastName
              email
              jobTitle
              organization
              city
              country
            }
          }
        `;
        const response = await axiosInstance.post("/graphql", { query });
        setRows(response.data.getAllAttendees);
      } catch (error) {
        console.error(`Error fetching ${type} data:`, error);
      }
    };

    fetchData();
  }, [type]);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    jobTitle: Yup.string().required("Job Title is required"),
    organization: Yup.string().required("Organization is required"),
    city: Yup.string().required("City is required"),
    country: Yup.string().required("Country is required"),
    addedVia: Yup.string().required("Please select an option"),
  });

  // Handle form submission with Axios

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // Create attendee data
    const newAttendee = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      jobTitle: values.jobTitle,
      organization: values.organization,
      city: values.city,
      country: values.country,
      addedVia: values.addedVia,
      sendEmail: values.sendEmail,
      type: type.toUpperCase() ?? "ATTENDEE", // Default to ATTENDEE
    };

    // Axios call to the backend
    axiosInstance
      .post("/graphql", {
        query: `
        mutation CreateAttendee($input: CreateAttendeeInput!) {
          createAttendee(input: $input) {
            id
            firstName
            lastName
            email
            jobTitle
            organization
            city
            country
            addedVia
            sendEmail
            type
          }
        }
      `,
        variables: {
          input: newAttendee,
        },
      })
      .then((response) => {
        // Ensure response structure is correct
        const createdAttendee = response?.data?.createAttendee;
        console.log("!!!!!!!!!! createdAttendee", createdAttendee);
        if (createdAttendee) {
          console.log("!!!!!!!!!! rows", rows);
          // Add the newly created attendee to the table
          setRows((prevRows) => [...prevRows, createdAttendee]);
          setSubmitting(false);
          resetForm();
          setOpen(false); // Close dialog after successful submission
        } else {
          throw new Error("Attendee creation failed");
        }
      })
      .catch((error) => {
        // Handle error
        console.error("Error creating attendee:", error);
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {type}
          </Typography>
          <Typography> Manage your {type.toLowerCase()} from here </Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpen(true)}
          >
            <AddIcon />
            New {type}
          </Button>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable rows={rows} setRows={setRows} />
        </Grid>
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" mb={0}>
              Add New Attendee
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add attendees to the attendee list. For a seamless one-click
                registration/entry for your attendees, please fill all mandatory
                form fields.
              </DialogContentText>
              <br />
              <Formik
                initialValues={{
                  firstName: "",
                  lastName: "",
                  email: "",
                  jobTitle: "",
                  organization: "",
                  city: "",
                  country: "",
                  addedVia: "Register",
                  sendEmail: false,
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, values, handleChange }) => (
                  <Form>
                    <Grid container spacing={6}>
                      <Grid item md={6}>
                        <Field
                          as={MuiTextField}
                          name="firstName"
                          label="First Name"
                          variant="outlined"
                          fullWidth
                          my={2}
                          helperText={<ErrorMessage name="firstName" />}
                          error={Boolean(<ErrorMessage name="firstName" />)}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Field
                          as={MuiTextField}
                          name="lastName"
                          label="Last Name"
                          variant="outlined"
                          fullWidth
                          my={2}
                          helperText={<ErrorMessage name="lastName" />}
                          error={Boolean(<ErrorMessage name="lastName" />)}
                        />
                      </Grid>
                      <Grid item md={12}>
                        <Field
                          as={MuiTextField}
                          name="email"
                          label="Email Id"
                          variant="outlined"
                          fullWidth
                          my={2}
                          helperText={<ErrorMessage name="email" />}
                          error={Boolean(<ErrorMessage name="email" />)}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Field
                          as={MuiTextField}
                          name="jobTitle"
                          label="Job Title"
                          variant="outlined"
                          fullWidth
                          my={2}
                          helperText={<ErrorMessage name="jobTitle" />}
                          error={Boolean(<ErrorMessage name="jobTitle" />)}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Field
                          as={MuiTextField}
                          name="organization"
                          label="Organization"
                          variant="outlined"
                          fullWidth
                          my={2}
                          helperText={<ErrorMessage name="organization" />}
                          error={Boolean(<ErrorMessage name="organization" />)}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Field
                          as={MuiTextField}
                          name="city"
                          label="City"
                          variant="outlined"
                          fullWidth
                          my={2}
                          helperText={<ErrorMessage name="city" />}
                          error={Boolean(<ErrorMessage name="city" />)}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <FormControl fullWidth>
                          <InputLabel id="country-label">Country</InputLabel>
                          <Field
                            as={Select}
                            labelId="country-label"
                            id="country"
                            name="country"
                            label="Country"
                            value={values.country}
                            onChange={handleChange}
                          >
                            <MenuItem value="Canada">Canada</MenuItem>
                            <MenuItem value="USA">USA</MenuItem>
                            <MenuItem value="India">India</MenuItem>
                          </Field>
                          <ErrorMessage
                            name="country"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={6}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Added Via</FormLabel>
                          <Field
                            as={RadioGroup}
                            aria-label="addedVia"
                            name="addedVia"
                            value={values.addedVia}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="Register"
                              control={<Radio />}
                              label="Register attendee"
                            />
                            <FormControlLabel
                              value="Invite"
                              control={<Radio />}
                              label="Invite Attendee"
                            />
                          </Field>
                          <ErrorMessage
                            name="addedVia"
                            component="div"
                            style={{ color: "red" }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item md={12} className="checkboxPadding">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Field
                                as={Checkbox}
                                name="sendEmail"
                                color="primary"
                              />
                            }
                            label="Send invitation email with unique access link"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                    <DialogActions>
                      <Button onClick={() => setOpen(false)} color="primary">
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        color="primary"
                      >
                        Submit
                      </Button>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </DialogContent>
          </Dialog>
        </div>
      </Paper>
    </React.Fragment>
  );
}

export default People;
