import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import axios from "axios"; // Import axios
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import Break from "./Break";
import Session from "./Session";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
  Snackbar, // Import Snackbar for showing success messages
  Alert,
} from "@mui/material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

function Sessions() {
  const [tracks, setTracks] = useState([]); // State for tracks
  const [sessionsByDate, setSessionsByDate] = useState({}); // State for sessions grouped by date
  const [breaks, setBreaks] = useState([]); // State for breaks
  const [loading, setLoading] = useState(true); // State to track loading
  const [successMessage, setSuccessMessage] = useState(""); // State for success message

  const [state, setState] = useState({
    right: false,
  }); // State for drawer

  // Toggle drawer function
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // GraphQL query to fetch tracks, sessions, and breaks
  const TRACKS_QUERY = `
    query {
      tracks {
        id
        name
      }
    }
  `;

  const SESSIONS_QUERY = `
    query {
      getSessions {
        id
        name
        sessionDate
        sessionTime
        duration
        description
      }
    }
  `;

  const BREAKS_QUERY = `
    query {
      getBreaks {
        id
        name
        breakDate
        breakTime
        duration
        description
      }
    }
  `;

  // Fetching data using Axios with GraphQL
  useEffect(() => {
    const fetchData = async () => {
      try {
        const tracksResponse = await axios.post("/graphql", {
          query: TRACKS_QUERY,
        });
        const sessionsResponse = await axios.post("/graphql", {
          query: SESSIONS_QUERY,
        });
        const breaksResponse = await axios.post("/graphql", {
          query: BREAKS_QUERY,
        });

        setTracks(tracksResponse.data.data.tracks); // Set tracks data

        // Group sessions by date

        const groupedSessions = sessionsResponse.data.data.getSessions.reduce(
          (acc, session) => {
            // Convert the timestamp to a Date object
            const date = new Date(parseInt(session.sessionDate, 10));

            // Check if date is valid
            if (isNaN(date.getTime())) {
              console.error("Invalid date:", session.sessionDate);
              return acc;
            }

            // Format the date to YYYY-MM-DD
            const formattedDate = date.toISOString().split("T")[0];

            // Group sessions by formatted date
            if (!acc[formattedDate]) acc[formattedDate] = [];
            acc[formattedDate].push(session);

            return acc;
          },
          {}
        );

        setSessionsByDate(groupedSessions); // Set sessions grouped by date

        setBreaks(breaksResponse.data.data.getBreaks); // Set breaks data

        setLoading(false); // Set loading to false
      } catch (error) {
        console.error("Error fetching data", error);
        setLoading(false); // Ensure loading stops on error as well
      }
    };

    fetchData();
  }, []);

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      style={{ width: "400px" }}
    >
      {/* Existing drawer content */}
    </Box>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSaveSession = (newSession) => {
    const groupedSessions = newSession.reduce((acc, session) => {
      // Convert the timestamp to a Date object
      const date = new Date(parseInt(session.sessionDate, 10));

      // Check if date is valid
      if (isNaN(date.getTime())) {
        console.error("Invalid date:", session.sessionDate);
        return acc;
      }

      // Format the date to YYYY-MM-DD
      const formattedDate = date.toISOString().split("T")[0];

      // Group sessions by formatted date
      if (!acc[formattedDate]) acc[formattedDate] = [];
      acc[formattedDate].push(session);

      return acc;
    }, {});
    alert("sss");
    setSessionsByDate((prevSessions) => [...prevSessions, groupedSessions]); // Add new session to the list
    setSuccessMessage("Session created successfully!"); // Show success message
  };

  return (
    <React.Fragment>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={4000}
        onClose={() => setSuccessMessage("")}
      >
        <Alert severity="success" onClose={() => setSuccessMessage("")}>
          {successMessage}
        </Alert>
      </Snackbar>
      <Grid container spacing={10} justifyContent="space-between">
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Sessions
          </Typography>
          <Typography component="div">
            Choose from the different types of sessions to add to your event
            schedule
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Link
              variant="contained"
              color="primary"
              onClick={toggleDrawer("right", true)} // Fix toggleDrawer reference
              style={{
                textDecoration: "none",
                fontWeight: "bold",
                fontSize: "0.9rem",
                marginRight: "1rem",
              }}
            >
              Add Tracks
            </Link>
            <Drawer
              anchor={"right"}
              open={state["right"]}
              onClose={toggleDrawer("right", false)} // Fix toggleDrawer reference
            >
              {list("right")}
            </Drawer>
            <Break />
            <Session onSave={handleSaveSession} />
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      {/* Tracks Section */}
      <Grid container spacing={10} justifyContent="space-between">
        <Grid item md={12}>
          <Grid item md={8}>
            <span style={{ marginRight: "10px" }}> Tracks </span>
            {tracks.map((track) => (
              <Button
                key={track.id}
                variant="outlined"
                sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
                style={{ borderRadius: "30px" }}
              >
                {track.name}
              </Button>
            ))}
          </Grid>

          {/* Dates Section */}
          <Grid item md={12} style={{ marginTop: "20px" }}>
            {Object.keys(sessionsByDate).map((date) => (
              <Button
                key={date}
                variant="contained"
                sx={{ mr: 2, display: { xs: "none", md: "inline" } }}
              >
                {new Date(date).toDateString()}
              </Button>
            ))}
          </Grid>

          {/* Timeline Section */}
          <Timeline
            sx={{
              [`& .${timelineOppositeContentClasses.root}`]: {
                flex: 0.1,
              },
            }}
          >
            {Object.keys(sessionsByDate).map((date) =>
              sessionsByDate[date].map((session, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent color="textSecondary">
                    {new Date(
                      `1970-01-01T${session.sessionTime}Z`
                    ).toLocaleTimeString()}{" "}
                    {session.duration} Min
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    {session.name}
                    <EditIcon
                      style={{
                        position: "relative",
                        top: "5px",
                        width: "1rem",
                        marginLeft: "10px",
                      }}
                    />
                    <DeleteIcon
                      style={{
                        position: "relative",
                        top: "5px",
                        color: "red",
                        width: "1rem",
                      }}
                    />
                    <Typography component="div">
                      {session.description}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))
            )}

            {/* Breaks Section */}
            {breaks.map((breakItem, index) => (
              <TimelineItem key={index}>
                <TimelineOppositeContent color="textSecondary">
                  {new Date(
                    `1970-01-01T${breakItem.breakTime}Z`
                  ).toLocaleTimeString()}{" "}
                  {breakItem.duration} Min
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {breakItem.name}
                  <EditIcon
                    style={{
                      position: "relative",
                      top: "5px",
                      width: "1rem",
                      marginLeft: "10px",
                    }}
                  />
                  <DeleteIcon
                    style={{
                      position: "relative",
                      top: "5px",
                      color: "red",
                      width: "1rem",
                    }}
                  />
                  <Typography component="div">
                    {breakItem.description}
                  </Typography>
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Sessions;
