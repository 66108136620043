import React, { useState } from "react";
import styled from "@emotion/styled";
import {
  Grid,
  Typography,
  Box,
  FormControl,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Paper,
  Chip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  Select,
  Alert,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ReactQuill from "react-quill";
import SendIcon from "@mui/icons-material/Send";

const QuillWrapper = styled.div`
  .ql-editor {
    min-height: 100px;
  }
`;

const ChatWindow = styled(Paper)`
  width: 350px;
  height: 500px;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #f0f4f8;
`;

const ChatHeader = styled(Box)`
  background-color: #3498db;
  color: white;
  padding: 15px;
  font-weight: bold;
  font-size: 1.1rem;
`;

const ChatBody = styled(Box)`
  flex-grow: 1;
  padding: 15px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const ChatBubble = styled(Box)`
  background-color: #3498db;
  color: white;
  padding: 10px 15px;
  border-radius: 18px;
  max-width: 80%;
  margin-bottom: 10px;
  align-self: flex-end;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
`;

const OptionButton = styled(Button)`
  margin: 2px 0;
  padding: 6px 10px;
  font-size: 0.875rem;
  text-transform: none;
  border-radius: 20px;
  background-color: #2ecc71;
  color: white;
  display: inline-block;
  min-width: unset;
  &:hover {
    background-color: #27ae60;
  }
`;

const ChatInput = styled(TextField)`
  .MuiOutlinedInput-root {
    border-radius: 30px;
    background-color: white;
  }
`;

function RequestOptions({ handleChildData }) {
  const [isPreselection, setIsPreselection] = useState(false);
  const [automaticResponse, setAutomaticResponse] = useState(true);
  const [automaticResponseText, setAutomaticResponseText] = useState(
    "We are taking care of your request - pls stay tuned"
  );
  const [contactOptions, setContactOptions] = useState({
    chat: true,
    phone: true,
    video: true,
    audio: true,
  });
  const [isLeadForm, setIsLeadForm] = useState(false);
  const [isPrivacyInformation, setIsPrivacyInformation] = useState(false);
  const [privacyText, setPrivacyText] = useState(
    "I have read and accept the privacy policy and terms of service."
  );
  const [open, setOpen] = useState(false);

  const handleContactOptionToggle = (option) => {
    setContactOptions((prev) => ({ ...prev, [option]: !prev[option] }));
  };

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Customize how visitors can contact you
        </Typography>
      </Grid>
      <Grid item md={6}>
        <Paper elevation={3} style={{ padding: "20px" }}>
          <Typography variant="h6" gutterBottom>
            1. Preselection screen (optional)
          </Typography>
          <Alert severity="info" style={{ marginBottom: "20px" }}>
            e.g: General / Product information / Support <br />
            Inquiries are forwarded to assigned team members
          </Alert>
          <FormControlLabel
            control={
              <Switch
                checked={isPreselection}
                onChange={(e) => setIsPreselection(e.target.checked)}
              />
            }
            label="Enable Preselection"
          />
          {isPreselection && (
            <Box mt={2}>
              <Button variant="contained" onClick={() => setOpen(true)}>
                Add New Routing
              </Button>
              <Typography
                variant="h6"
                gutterBottom
                style={{ marginTop: "20px" }}
              >
                Routing Screen Buttons
              </Typography>
              <Stack direction="row" spacing={1}>
                <Chip label="General" onDelete={() => {}} />
                <Chip label="Product Info" onDelete={() => {}} />
                <Chip label="Support" onDelete={() => {}} />
              </Stack>
            </Box>
          )}

          <Typography variant="h6" gutterBottom style={{ marginTop: "30px" }}>
            2. Automatic chat response after request (optional)
          </Typography>
          <FormControlLabel
            control={
              <Switch
                checked={automaticResponse}
                onChange={(e) => setAutomaticResponse(e.target.checked)}
              />
            }
            label="Enable Automatic Response"
          />
          {automaticResponse && (
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Automatic chat response text"
              value={automaticResponseText}
              onChange={(e) => setAutomaticResponseText(e.target.value)}
              variant="outlined"
              margin="normal"
            />
          )}

          <Typography variant="h6" gutterBottom style={{ marginTop: "30px" }}>
            3. Contact options
          </Typography>
          <Box mb={2}>
            {Object.entries(contactOptions).map(([option, enabled]) => (
              <Button
                key={option}
                variant={enabled ? "contained" : "outlined"}
                onClick={() => handleContactOptionToggle(option)}
                style={{ marginRight: "10px", marginBottom: "10px" }}
              >
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </Button>
            ))}
          </Box>
          <FormControlLabel
            control={
              <Switch
                checked={isLeadForm}
                onChange={(e) => setIsLeadForm(e.target.checked)}
              />
            }
            label="Activate lead form before starting a conversation"
          />
          {isLeadForm && (
            <Box mt={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={isPrivacyInformation}
                    onChange={(e) => setIsPrivacyInformation(e.target.checked)}
                  />
                }
                label="Enable Privacy Information"
              />
              {isPrivacyInformation && (
                <QuillWrapper>
                  <ReactQuill
                    theme="snow"
                    value={privacyText}
                    onChange={setPrivacyText}
                  />
                </QuillWrapper>
              )}
            </Box>
          )}
        </Paper>
      </Grid>
      <Grid item md={6}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
          height="100%"
        >
          <ChatWindow>
            <ChatHeader>Support Chat</ChatHeader>
            <ChatBody>
              <ChatBubble>
                <Typography variant="body2">
                  You have questions about our products?
                </Typography>
              </ChatBubble>
              <ChatBubble>
                <Typography variant="body2">
                  We are happy to help you!
                </Typography>
              </ChatBubble>
              <ChatBubble>
                <Typography variant="body2">
                  How would you like to communicate with us?
                </Typography>
              </ChatBubble>
              <Box
                mt={1}
                display="flex"
                flexDirection="column"
                alignItems="flex-end"
              >
                {Object.entries(contactOptions).map(
                  ([option, enabled]) =>
                    enabled && (
                      <OptionButton key={option} variant="contained">
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                      </OptionButton>
                    )
                )}
              </Box>
            </ChatBody>
            <Box p={2}>
              <ChatInput
                fullWidth
                variant="outlined"
                placeholder="Type your message..."
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" color="primary">
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </ChatWindow>
        </Box>
      </Grid>

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add New Routing</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Display Name"
            type="text"
            fullWidth
            style={{ marginBottom: "20px" }}
          />
          <FormControl fullWidth>
            <InputLabel>Select User Group</InputLabel>
            <Select label="Select User Group">
              {/* Add menu items here */}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default RequestOptions;
