import * as React from "react";

import {
  Typography,
  Button,
  ListItem,
  ListItemText,
  MenuItem,
  MenuList,
  Avatar,
  ListItemAvatar,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";

function Conferences() {
  return (
    <MenuList className="preview" style={{ height: "550px" }}>
      <MenuItem
        style={{
          minHeight: "50px",
          borderBottom: "1px solid #eee",
        }}
      >
        <ListItem
          disableGutters
          secondaryAction={
            <Button variant="contained" color="inherit">
              Connect
            </Button>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Google Meet"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Schedule and join video meetings with our Google Meet
                  integrations.
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </MenuItem>
      <MenuItem
        style={{
          minHeight: "50px",
          borderBottom: "1px solid #eee",
        }}
      >
        <ListItem
          disableGutters
          secondaryAction={
            <Button variant="contained" color="inherit">
              Connect
            </Button>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Microsoft Teams"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Schedule and join video meetings with our Microsoft Teams
                  integrations.
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </MenuItem>
      <MenuItem
        style={{
          minHeight: "50px",
          borderBottom: "1px solid #eee",
        }}
      >
        <ListItem
          disableGutters
          secondaryAction={
            <Button variant="contained" color="inherit">
              Connect
            </Button>
          }
        >
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Zoom"
            secondary={
              <React.Fragment>
                <Typography
                  sx={{ display: "inline" }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Seamlessly create and manage Zoom meetings from your
                  scheduling links.
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>
      </MenuItem>
    </MenuList>
  );
}
export default Conferences;
