import React from "react";
import styled from "@emotion/styled";
import CardMedia from "@mui/material/CardMedia";
import CustomReceptionComponent from "./CustomReception";
import Drawer from "@mui/material/Drawer";

import {
  Box,
  Divider as MuiDivider,
  Grid,
  Typography,
  Card as MuiCard,
} from "@mui/material";
import { spacing } from "@mui/system";
const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

function Reception() {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
    if (open === true) {
      setButtonStyle("Button+Text(Old)");
    }
    setState(open);
  };
  const [ButtonStyle, setButtonStyle] = React.useState("Button");
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Reception
          </Typography>
          <Typography>
            Create a grand first impression for your attendees of the event. You
            can emphasize your brand and the event here
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Grid container spacing={6}>
          <Grid item md={12} style={{ marginBottom: "15px" }}>
            <Box mb={3}>
              <Grid container mb={4}>
                {ButtonStyle === "Button" ? (
                  <React.Fragment>
                    <Grid
                      md={4}
                      style={{ marginRight: "10px" }}
                      onClick={() => setButtonStyle("Button")}
                    >
                      <Card>
                        <CardMedia
                          component="img"
                          height="194"
                          image="/static/img/others/DefaultReception.png"
                          alt="Paella dish"
                          style={{
                            backgroundPosition: "center",
                            width: "100%",
                            border: "2px solid #376fd0",
                            borderRadius: "10px",
                          }}
                        />
                      </Card>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "center", marginTop: "10px" }}
                      >
                        Default Reception
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.7rem",
                          borderBottom: "2px solid #376fd0",
                        }}
                      >
                        This will highlight the banner image and upcoming
                        sessions
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid
                      md={4}
                      style={{ marginRight: "10px" }}
                      onClick={() => setButtonStyle("Button")}
                    >
                      <Card>
                        <CardMedia
                          component="img"
                          height="194"
                          image="/static/img/others/DefaultReception.png"
                          alt="Paella dish"
                          style={{
                            backgroundPosition: "center",
                            width: "100%",
                            // border: "1px solid #b4b4b4",
                            borderRadius: "10px",
                          }}
                        />
                      </Card>
                      <Typography
                        variant="h6"
                        style={{ textAlign: "center", marginTop: "10px" }}
                      >
                        Default Reception
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.7rem",
                        }}
                      >
                        This will highlight the banner image and upcoming
                        sessions
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
                {ButtonStyle === "Button+Text(Old)" ? (
                  <React.Fragment>
                    <Grid
                      md={4}
                      style={{ marginRight: "10px" }}
                      onClick={toggleDrawer(true)}
                    >
                      <Card>
                        <CardMedia
                          component="img"
                          height="194"
                          image="/static/img/others/CustomReception.png"
                          alt="Paella dish"
                          style={{
                            backgroundPosition: "center",
                            width: "100%",
                            border: "2px solid #376fd0",
                            borderRadius: "10px",
                          }}
                        />
                      </Card>
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        Custom Reception1
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.7rem",
                          borderBottom: "2px solid #376fd0",
                        }}
                      >
                        Create your own reception with videos and links
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Grid
                      md={4}
                      style={{ marginRight: "10px" }}
                      onClick={toggleDrawer(true)}
                    >
                      <Card>
                        <CardMedia
                          component="img"
                          height="194"
                          image="/static/img/others/CustomReception.png"
                          alt="Paella dish"
                          style={{
                            backgroundPosition: "center",
                            width: "100%",
                            // border: "1px solid #b4b4b4",
                            borderRadius: "10px",
                          }}
                        />
                      </Card>
                      <Typography
                        variant="h6"
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      >
                        Custom Reception
                      </Typography>
                      <Typography
                        style={{
                          textAlign: "center",
                          padding: "10px",
                          fontSize: "0.7rem",
                        }}
                      >
                        Create your own reception with videos and links
                      </Typography>
                    </Grid>
                  </React.Fragment>
                )}
                <Drawer
                  anchor="right"
                  open={state}
                  onClose={toggleDrawer(false)}
                >
                  <CustomReceptionComponent />
                </Drawer>
              </Grid>
              <Grid container md={12} style={{ marginTop: "40px" }}>
                <React.Fragment>
                  <Grid md={8} style={{ marginRight: "10px" }}>
                    <Typography variant="h6">Reception Banner</Typography>
                    <Typography>
                      Add a banner image for your reception
                    </Typography>
                  </Grid>
                  <Grid
                    md={8}
                    style={{ marginRight: "10px", marginTop: "10px" }}
                  >
                    <input
                      accept="image/*"
                      style={{ display: "none" }}
                      id="raised-button-file"
                      multiple
                      type="file"
                    />
                    <label htmlFor="raised-button-file">
                      <img
                        src="/static/img/others/DefaultReception.png"
                        style={{ borderRadius: "10px" }}
                        mr={2}
                        alt=""
                      />
                      {/* </Button> */}
                    </label>
                    <Typography>
                      Recommended format: 1440x640px | JPG, SVG or PNG | Up to 5
                      MB
                    </Typography>
                  </Grid>
                </React.Fragment>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default Reception;
