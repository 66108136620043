import React from "react";
import styled from "@emotion/styled";
import ListItemText from "@mui/material/ListItemText";
import MenuList from "@mui/material/MenuList";
import LoungeComponent from "./Lounge";
import BoothsComponent from "./Booths";

import { Grid, Paper as MuiPaper, MenuItem } from "@mui/material";
import { spacing } from "@mui/system";

const Paper = styled(MuiPaper)(spacing);

function EventSpaces() {
  const [RTab, setRTab] = React.useState("Lounge");
  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "300px", padding: "0" }}>
            {RTab === "Lounge" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Lounge");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Lounge</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Lounge");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Lounge</ListItemText>
              </MenuItem>
            )}
            {RTab === "Booths" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Booths");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Booths</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Booths");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Booths</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        {RTab === "Lounge" ? <LoungeComponent /> : <BoothsComponent />}
      </Grid>
    </Grid>
  );
}

export default EventSpaces;
