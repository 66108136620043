import React from "react";
import styled from "@emotion/styled";

import {
  Box,
  Button,
  Divider as MuiDivider,
  Grid,
  Typography,
} from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

function LandingPage() {
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Landing Page
          </Typography>
          <Typography>
            Customize your landing page for a unique experience
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button variant="contained" color="primary">
              <PreviewIcon />
              Preview & Customize
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
        <Grid item md={12}>
          <img src="/static/img/others/BrandingLandingPage.png" alt="" />
        </Grid>
      </Box>
    </React.Fragment>
  );
}

export default LandingPage;
