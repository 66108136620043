import React, { useState } from "react";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link, NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import dayjs from "dayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import ListItem from "@mui/material/ListItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { axiosInstance } from "../../../utils/axios";

import {
  Box,
  Grid,
  CardContent,
  Card as MuiCard,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
  TextField as MuiTextField,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Checkbox,
  InputLabel,
  IconButton,
} from "@mui/material";

import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  Remove,
} from "@mui/icons-material";

import { spacing } from "@mui/system";

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 50vh;
  height: 100%;
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

// Data

const MyTable = ({ label, isAvailable, setIsAvailable }) => {
  const [checked, setChecked] = useState(isAvailable);
  const [slots, setSlots] = useState([{ timeStart: "", timeEnd: "" }]);

  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setChecked(isChecked);
    if (isChecked) {
      // If the checkbox is checked, pass the slots array to the parent
      setIsAvailable(
        slots.filter((slot) => slot.timeStart && slot.timeEnd),
        true
      );
    } else {
      // If the checkbox is unchecked, pass an empty array to the parent
      setIsAvailable([], false);
    }
  };

  const handleSlotChange = (index, field, value) => {
    const newSlots = [...slots];
    newSlots[index][field] = value;
    setSlots(newSlots);
    if (checked) {
      setIsAvailable(
        newSlots.filter((slot) => slot.timeStart && slot.timeEnd),
        true
      );
    }
  };

  const addSlot = () => {
    setSlots([...slots, { timeStart: "", timeEnd: "" }]);
  };

  const removeSlot = (index) => {
    const newSlots = slots.filter((_, i) => i !== index);
    setSlots(newSlots);
    if (checked) {
      setIsAvailable(
        newSlots.filter((slot) => slot.timeStart && slot.timeEnd),
        true
      );
    }
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item md={2}>
        <FormControlLabel
          control={<Checkbox checked={checked} onChange={handleSwitchChange} />}
          label={label}
        />
      </Grid>
      {checked &&
        slots.map((slot, index) => (
          <React.Fragment key={index}>
            <Grid item md={4}>
              <Select
                value={slot.timeStart}
                onChange={(e) =>
                  handleSlotChange(index, "timeStart", e.target.value)
                }
                fullWidth
              >
                <MenuItem value="09:00 am">09:00 am</MenuItem>
                <MenuItem value="10:00 am">10:00 am</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Grid>
            <Grid item md={1} style={{ textAlign: "center" }}>
              <IconButton onClick={() => removeSlot(index)}>
                <Remove />
              </IconButton>
            </Grid>
            <Grid item md={4}>
              <Select
                value={slot.timeEnd}
                onChange={(e) =>
                  handleSlotChange(index, "timeEnd", e.target.value)
                }
                fullWidth
              >
                <MenuItem value="05:00 pm">05:00 pm</MenuItem>
                <MenuItem value="06:00 pm">06:00 pm</MenuItem>
                {/* Add more options as needed */}
              </Select>
            </Grid>
            {index === slots.length - 1 && (
              <Grid item md={1} style={{ textAlign: "center" }}>
                <IconButton onClick={addSlot}>
                  <AddIcon />
                </IconButton>
              </Grid>
            )}
          </React.Fragment>
        ))}
    </Grid>
  );
};

const MonthView = ({ onAddOverride }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [timeSlots, setTimeSlots] = useState([{ timeStart: "", timeEnd: "" }]);
  const [isUnavailable, setIsUnavailable] = useState(false);

  const times = [
    "12:00 am",
    "12:15 am",
    "12:30 am",
    "12:45 am",
    "1:00 am",
    "1:15 am",
    "1:30 am",
    "1:45 am",
    "2:00 am",
    "2:15 am",
    "2:30 am",
    "2:45 am",
    "3:00 am",
    "3:15 am",
    "3:30 am",
    "3:45 am",
    "4:00 am",
    "4:15 am",
    "4:30 am",
    "4:45 am",
    "5:00 am",
    "5:15 am",
    "5:30 am",
    "5:45 am",
    "6:00 am",
    "6:15 am",
    "6:30 am",
    "6:45 am",
    "7:00 am",
    "7:15 am",
    "7:30 am",
    "7:45 am",
    "8:00 am",
    "8:15 am",
    "8:30 am",
    "8:45 am",
    "9:00 am",
    "9:15 am",
    "9:30 am",
    "9:45 am",
    "10:00 am",
    "10:15 am",
    "10:30 am",
    "10:45 am",
    "11:00 am",
    "11:15 am",
    "11:30 am",
    "11:45 am",
    "12:00 pm",
    "12:15 pm",
    "12:30 pm",
    "12:45 pm",
    "1:00 pm",
    "1:15 pm",
    "1:30 pm",
    "1:45 pm",
    "2:00 pm",
    "2:15 pm",
    "2:30 pm",
    "2:45 pm",
    "3:00 pm",
    "3:15 pm",
    "3:30 pm",
    "3:45 pm",
    "4:00 pm",
    "4:15 pm",
    "4:30 pm",
    "4:45 pm",
    "5:00 pm",
    "5:15 pm",
    "5:30 pm",
    "5:45 pm",
    "6:00 pm",
    "6:15 pm",
    "6:30 pm",
    "6:45 pm",
    "7:00 pm",
    "7:15 pm",
    "7:30 pm",
    "7:45 pm",
    "8:00 pm",
    "8:15 pm",
    "8:30 pm",
    "8:45 pm",
    "9:00 pm",
    "9:15 pm",
    "9:30 pm",
    "9:45 pm",
    "10:00 pm",
    "10:15 pm",
    "10:30 pm",
    "10:45 pm",
    "11:00 pm",
    "11:15 pm",
    "11:30 pm",
    "11:45 pm",
  ];

  const addTimeSlot = () => {
    setTimeSlots([...timeSlots, { timeStart: "", timeEnd: "" }]);
  };

  const removeTimeSlot = (index) => {
    const updatedTimeSlots = [...timeSlots];
    updatedTimeSlots.splice(index, 1);
    setTimeSlots(updatedTimeSlots);
  };

  const handleAddOverride = () => {
    if (selectedDate && (timeSlots.length > 0 || isUnavailable)) {
      onAddOverride({
        date: selectedDate,
        timeSlots: isUnavailable
          ? [{ timeStart: "Unavailable all day", timeEnd: "" }]
          : timeSlots,
      });
    }
  };

  return (
    <ChatContainer container component={Card}>
      <ChatSidebar item xs={12} md={6} lg={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DateCalendar", "DateCalendar"]}>
            <DemoItem label="">
              <DateCalendar
                value={selectedDate}
                onChange={(newValue) => setSelectedDate(newValue || dayjs())}
                style={{ width: "100%" }}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
      </ChatSidebar>
      <ChatSidebar item xs={12} md={6} lg={6} style={{ border: "0" }}>
        <Typography variant="body2" gutterBottom>
          Which hours are you free?
        </Typography>
        {timeSlots.map((slot, index) => (
          <Grid
            container
            spacing={2}
            alignItems="center"
            key={index}
            style={{ marginBottom: "10px" }}
          >
            <Grid item md={4}>
              <Select
                fullWidth
                value={slot.timeStart}
                onChange={(e) => {
                  const updatedTimeSlots = [...timeSlots];
                  updatedTimeSlots[index].timeStart = e.target.value;
                  setTimeSlots(updatedTimeSlots);
                }}
                disabled={isUnavailable}
              >
                {times.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={1} style={{ textAlign: "center" }}>
              <Remove
                style={{
                  color: "gray",
                  cursor: "pointer",
                  visibility: timeSlots.length > 1 ? "visible" : "hidden",
                }}
                onClick={() => removeTimeSlot(index)}
              />
            </Grid>
            <Grid item md={4}>
              <Select
                fullWidth
                value={slot.timeEnd}
                onChange={(e) => {
                  const updatedTimeSlots = [...timeSlots];
                  updatedTimeSlots[index].timeEnd = e.target.value;
                  setTimeSlots(updatedTimeSlots);
                }}
                disabled={isUnavailable}
              >
                {times.map((time) => (
                  <MenuItem key={time} value={time}>
                    {time}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item md={1}>
              <AddIcon
                onClick={addTimeSlot}
                style={{
                  cursor: "pointer",
                  color: "green",
                }}
              />
            </Grid>
          </Grid>
        ))}
        <FormGroup style={{ marginTop: "10px" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isUnavailable}
                onChange={(e) => setIsUnavailable(e.target.checked)}
              />
            }
            label="Mark unavailable (All day)"
          />
        </FormGroup>
      </ChatSidebar>
      <Grid container justifyContent="flex-end" style={{ marginTop: "20px" }}>
        <Button onClick={handleAddOverride} variant="contained" color="primary">
          Add Override
        </Button>
      </Grid>
    </ChatContainer>
  );
};

const CREATE_AVAILABILITY_MUTATION = `
  mutation CreateAvailability($input: AvailabilityInput!) {
    createAvailability(input: $input) {
      id
      name
      timezone
      days
      timeSlots {
        timeStart
        timeEnd
      }
      overrides {
        date
        timeSlots {
          timeStart
          timeEnd
        }
        isUnavailable
      }
    }
  }
`;
const EventEntryForm = (props) => {
  const [availabilityName, setAvailabilityName] = useState("");
  const [timezoneValue, setTimezoneValue] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [availabilityTimeSlots, setAvailabilityTimeSlots] = useState([]);
  const [availabilityOverrides, setAvailabilityOverrides] = useState([]);

  const [open, setOpen] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axiosInstance.post("/graphql", {
        query: CREATE_AVAILABILITY_MUTATION,
        variables: {
          input: {
            name: availabilityName,
            timezone: timezoneValue,
            days: selectedDays,
            timeSlots: Object.values(availabilityTimeSlots).flat(),
            overrides: availabilityOverrides,
          },
        },
      });

      if (response.data.errors) {
        throw new Error(response.data.errors[0].message);
      }

      const newAvailability = response.data.createAvailability;
      console.log("Availability created:", newAvailability);
    } catch (error) {
      console.error("Error creating availability:", error.message);
    }
  };

  // const handleDaySelection = (day, slots) => {
  //   setSelectedDays((prevDays) => [...new Set([...prevDays, day])]);
  //   setAvailabilityTimeSlots((prevSlots) => ({
  //     ...prevSlots,
  //     [day]: slots,
  //   }));
  // };

  // Handle day selection

  const handleDaySelection = (day, slots, isChecked) => {
    if (isChecked) {
      // Add day to selectedDays if not already present
      setSelectedDays((prevDays) => {
        if (!prevDays.includes(day)) {
          return [...prevDays, day];
        }
        return prevDays;
      });

      // Add time slots without the `day` field
      setAvailabilityTimeSlots((prevSlots) => [
        ...prevSlots,
        ...slots
          .filter((slot) => slot.timeStart && slot.timeEnd)
          .map((slot) => ({
            timeStart: slot.timeStart,
            timeEnd: slot.timeEnd,
          })),
      ]);
    } else {
      // Remove day from selectedDays
      setSelectedDays((prevDays) => prevDays.filter((d) => d !== day));

      // Remove all time slots for this day (by index, not by day, since day is no longer part of the timeslots)
      // This assumes time slots are being tracked separately from days
      setAvailabilityTimeSlots((prevSlots) =>
        prevSlots.filter(
          (_, i) => i !== prevSlots.findIndex((slot) => slot.day === day)
        )
      );
    }
  };

  const handleAddOverride = (override) => {
    const formattedOverride = {
      ...override,
      isUnavailable: !override.timeSlots || override.timeSlots.length === 0,
    };
    setAvailabilityOverrides([...availabilityOverrides, formattedOverride]);
    setOpen(false); // Close the dialog after adding
  };

  return (
    <form onSubmit={handleSubmit}>
      <Card mb={6}>
        <CardContent>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <TextField
                id="availabilityName"
                label="Availability Name"
                value={availabilityName}
                onChange={(e) => setAvailabilityName(e.target.value)}
                variant="outlined"
                fullWidth
                my={4}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item md={6}>
              <FormControl fullWidth variant="outlined" my={4}>
                <InputLabel id="timezone-label">Timezone</InputLabel>
                <Select
                  id="timezone"
                  label="Timezone"
                  value={timezoneValue}
                  onChange={(e) => setTimezoneValue(e.target.value)}
                  fullWidth
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Timezone
                  </MenuItem>
                  <MenuItem value="America/New_York">
                    America/New York GMT -4:00
                  </MenuItem>
                  <MenuItem value="Europe/London">
                    Europe/London GMT +1:00
                  </MenuItem>
                  <MenuItem value="Asia/Kolkata">
                    Asia/Kolkata GMT +5:30
                  </MenuItem>
                  {/* Add more timezones as needed */}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={6}>
            <Grid item md={12}>
              <Box style={{ marginTop: "15px", marginBottom: "15px" }}>
                <Grid item md={12}>
                  <ListItem alignItems="flex-start" disablePadding>
                    <Grid container>
                      <Grid item md={6}>
                        <Typography variant="subtitle1">
                          Set Availability
                          <Typography
                            color="success"
                            style={{ paddingLeft: "12px !important" }}
                          >
                            <small
                              style={{
                                position: "relative",
                                bottom: "4px",
                                paddingLeft: "2px",
                              }}
                            >
                              Set when you are available so that you can be
                              booked
                            </small>
                          </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </ListItem>
                </Grid>
                <Grid item md={12}>
                  {[
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday",
                  ].map((day) => (
                    <MyTable
                      key={day}
                      label={day}
                      isAvailable={false} // Default unchecked
                      setIsAvailable={(slots, isChecked) =>
                        handleDaySelection(day, slots, isChecked)
                      }
                    />
                  ))}
                </Grid>
                <br />
                <Grid item md={6} mb={3}>
                  <ListItem alignItems="flex-start" disablePadding>
                    <Grid container justifyContent="space-between">
                      <Typography variant="h6">Date Overrides</Typography>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(true)}
                      >
                        <AddIcon />
                        Add an override
                      </Button>
                    </Grid>
                  </ListItem>
                </Grid>
                {/* List of Date Overrides */}
                {availabilityOverrides.map((override, index) => (
                  <Grid
                    key={index}
                    item
                    md={6}
                    style={{
                      padding: "15px",
                      border: "1px solid #c4c4c4",
                      marginBottom: "15px",
                    }}
                  >
                    <Typography variant="subtitle1">
                      {dayjs(override.date).format("dddd, MMMM D")}{" "}
                      {/* Format the date */}
                      <Typography
                        color="success"
                        style={{ paddingLeft: "12px" }}
                      >
                        <small
                          style={{
                            position: "relative",
                            bottom: "4px",
                            paddingLeft: "2px",
                          }}
                        >
                          {override.timeSlots
                            .map((slot) =>
                              slot.timeStart && slot.timeEnd
                                ? `${slot.timeStart} - ${slot.timeEnd}`
                                : slot.timeStart
                            )
                            .join(", ")}
                        </small>
                      </Typography>
                    </Typography>
                  </Grid>
                ))}
              </Box>
            </Grid>
          </Grid>

          <Button variant="contained" color="primary" type="submit">
            Create Availability
          </Button>
          {/* Dialog for Adding Override */}
          <Dialog open={open} onClose={() => setOpen(false)} fullWidth>
            <DialogTitle>Select the dates to override</DialogTitle>
            <DialogContent>
              <MonthView onAddOverride={handleAddOverride} />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Close
              </Button>
              <Button onClick={handleAddOverride} color="primary">
                Add Override
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
    </form>
  );
};

function MyAvailabilityAdd() {
  return (
    <React.Fragment>
      <Helmet title="Add New Availability" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Add New Availability
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Link component={NavLink} to="/calendar/my-availability">
              Availability
            </Link>
            <Typography>Add New Availability</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/calendar/my-availability"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EventEntryForm />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default MyAvailabilityAdd;
