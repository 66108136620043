import { Sliders } from "react-feather";

const pagesSection = [
  {
    href: "/dashboard",
    icon: Sliders,
    title: "Space A",
  },
  {
    href: "/roles",
    icon: Sliders,
    title: "Space B",
  },
  {
    href: "/roles",
    icon: Sliders,
    title: "Space C",
  },
];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
