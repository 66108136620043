// utils/errorHandler.js

export const errorHandler = (error) => {
  let validationErrors = {
    submit: "Something went wrong",
  };

  if (typeof error.message === "string") {
    try {
      validationErrors = JSON.parse(error.message).validationErrors || {};
    } catch (parseError) {
      // In case JSON.parse fails, fall back to using the error message directly
      validationErrors.submit = error.message;
    }
  } else {
    validationErrors.submit = error.message;
  }

  return validationErrors;
};
