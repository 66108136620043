import React from "react";
import styled from "@emotion/styled";
import {
  Avatar as MuiAvatar,
  Box,
  Button,
  Grid,
  Typography,
  DialogActions,
  TextField,
} from "@mui/material";
import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";

function TiersAdd() {
  const [state, setState] = React.useState(false);
  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
    if (open === true) {
    }
    setState(open);
  };
  return (
    <Box
      sx={{ width: 450 }}
      style={{ width: "450px" }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      // onKeyDown={toggleDrawer(false)}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Manage Sponsor Tiers
          </Typography>
        </Grid>
      </Grid>
      <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
        <Grid container mb={4}>
          <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
            <Typography>1</Typography>
          </Grid>
          <Grid md={10}>
            <TextField
              name="groupName"
              label="Name *"
              variant="outlined"
              fullWidth
              my={2}
              value="Gold"
            />
          </Grid>
          <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
            <DeleteIcon />
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
            <Typography>2</Typography>
          </Grid>
          <Grid md={10}>
            <TextField
              name="groupName"
              label="Name *"
              variant="outlined"
              fullWidth
              my={2}
              value="Silver"
            />
          </Grid>
          <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
            <DeleteIcon />
          </Grid>
        </Grid>
        <Grid container mb={4}>
          <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
            <Typography>3</Typography>
          </Grid>
          <Grid md={10}>
            <TextField
              name="groupName"
              label="Name *"
              variant="outlined"
              fullWidth
              my={2}
              value="Bronze"
            />
          </Grid>
          <Grid md={1} style={{ display: "flex", alignItems: "center" }}>
            <DeleteIcon />
          </Grid>
        </Grid>
        <Grid md={12} mt={2} style={{ textAlign: "center" }}>
          <Button
            variant="outlined"
            onClick={toggleDrawer("right", false)}
            color="primary"
          >
            <AddIcon />
            Add Tier
          </Button>
        </Grid>
      </Grid>
      <DialogActions>
        <Button color="primary" onClick={toggleDrawer(false)}>
          Cancel
        </Button>
        {/* <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button> */}
        <Button
          color="primary"
          variant="contained"
          sx={{
            mb: 2,
            // Override default disabled styles
            "&.Mui-disabled": {
              backgroundColor: "primary.main", // Change this to your desired color
              color: "white", // Ensure text color stays visible
              opacity: 1, // Prevent the button from becoming semi-transparent
            },
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Box>
  );
}
export default TiersAdd;
