import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Copy, Info, Search as SearchIcon } from "react-feather";
import { useTranslation } from "react-i18next";
import { darken } from "polished";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import "../../../Styles/Custom.css";
import MenuList from "@mui/material/MenuList";
import { MuiColorInput } from "mui-color-input";
import Switch from "@mui/material/Switch";
import OutlinedInput from "@mui/material/OutlinedInput";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import {
  Avatar as MuiAvatar,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  DialogActions,
  TextField,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Card as MuiCard,
  CardMedia as MuiCardMedia,
  CardActions,
  AvatarGroup as MuiAvatarGroup,
  FormControlLabel,
} from "@mui/material";
import { green, orange } from "@mui/material/colors";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
  FilterList,
  Download,
  Mail,
  ContentCopy,
  Visibility,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing, width } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.paid && green[500]};
  background: ${(props) => props.sent && orange[700]};
  color: ${(props) =>
    (props.paid || props.sent) && props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const ToolbarTitle = styled.div`
  min-width: 200px;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const checkboxStyle = `
  position:absolute;
  bottom:0;
`;

const Customer = styled.div`
  display: flex;
  align-items: center;
`;

const CardMedia = styled(MuiCardMedia)`
  height: 220px;
`;

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function createData(
  customer,
  customerEmail,
  customerAvatar,
  status,
  amount,
  id,
  date
) {
  return { customer, customerEmail, customerAvatar, status, amount, id, date };
}

const rows = [
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
  createData(
    "26/04/2024",
    "Kolkata",
    "A",
    0,
    "$530,00",
    "000112",
    "2023-01-02"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  { id: "customer", alignment: "left", label: "Date" },
  { id: "customerEmail", alignment: "left", label: "Location" },
  { id: "customerAvatar", alignment: "left", label: "Ip Address" },
  { id: "status", alignment: "left", label: "Segment" },
  { id: "id", alignment: "left", label: "Widget Name" },
];

const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("md");
  const [open, setOpen] = useState(false);
  const [viewStyle, setViewStyle] = React.useState("List");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { numSelected } = props;

  const Input = styled(InputBase)`
    color: inherit;
    width: 100%;

    > input {
      color: ${(props) => props.theme.header.search.color};
      padding-top: ${(props) => props.theme.spacing(2.5)};
      padding-right: ${(props) => props.theme.spacing(2.5)};
      padding-bottom: ${(props) => props.theme.spacing(2.5)};
      padding-left: ${(props) => props.theme.spacing(12)};
      width: 160px;
    }
  `;

  const { t } = useTranslation();

  const Search = styled.div`
    border-radius: 2px;
    background-color: ${(props) => props.theme.header.background};
    display: none;
    position: relative;
    width: 100%;

    &:hover {
      background-color: ${(props) =>
        darken(0.05, props.theme.header.background)};
    }

    ${(props) => props.theme.breakpoints.up("md")} {
      display: block;
    }
  `;

  const SearchIconWrapper = styled.div`
    width: 50px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 22px;
      height: 22px;
    }
  `;

  const { doChangeProps } = props;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      //   onClick={toggleDrawer(anchor, false)}
      //   onKeyDown={toggleDrawer(anchor, false)}
    >
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item style={{ margin: "15px", paddingBottom: "10px" }}>
          <Typography variant="h3" gutterBottom display="inline">
            Add Filter
          </Typography>
          <Typography> Add filter to the attendees list</Typography>
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <FormLabel id="demo-radio-buttons-group-label">Segment</FormLabel>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Segment A" />
                <FormControlLabel control={<Checkbox />} label="Segment B" />
              </FormGroup>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <List>
        <ListItem>
          <Grid container spacing={6}>
            <Grid item md={12} className="checkboxPadding">
              <FormLabel id="demo-radio-buttons-group-label">Widget</FormLabel>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Widget 1" />
                <FormControlLabel control={<Checkbox />} label="Widget 2" />
              </FormGroup>
            </Grid>
          </Grid>
        </ListItem>
      </List>
      <DialogActions>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Cancel
        </Button>
        <Button onClick={toggleDrawer("right", false)} color="primary">
          Done
        </Button>
      </DialogActions>
    </Box>
  );

  return (
    <Toolbar>
      <ToolbarTitle>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Grid container>
            {viewStyle === "Card" ? (
              <Grid md={2}>
                <Checkbox />
              </Grid>
            ) : (
              <div></div>
            )}
            <Grid md={10}>
              <Typography>
                <Search>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <Input placeholder={t("Search")} />
                </Search>
              </Typography>
            </Grid>
          </Grid>
        )}
      </ToolbarTitle>
      <Spacer />
      <Grid container display="block" textAlign="right">
        {numSelected === 1 ? (
          <Grid>
            <Tooltip title="Edit">
              <IconButton
                aria-label="Details"
                size="large"
                onClick={() => setOpen(true)}
              >
                <Info />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton aria-label="Delete" size="large">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : numSelected > 1 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Paper>
            <Tooltip title="Filter">
              <IconButton
                aria-label="Filter"
                size="large"
                onClick={toggleDrawer("right", true)}
              >
                <FilterList />
              </IconButton>
              <Drawer
                anchor={"right"}
                open={state["right"]}
                onClose={toggleDrawer("right", false)}
              >
                {list("right")}
              </Drawer>
            </Tooltip>
            <Tooltip title="Download List">
              <IconButton
                aria-label="Download List"
                size="large"
                style={{ marginRight: "10px" }}
              >
                <Download />
              </IconButton>
            </Tooltip>
            <Button
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              variant="contained"
              color="secondary"
            >
              View
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={(e) => {
                  doChangeProps("List");
                  setViewStyle("List");
                  setAnchorEl(null);
                }}
              >
                List
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  doChangeProps("Card");
                  setViewStyle("Card");
                  setAnchorEl(null);
                }}
              >
                Card
              </MenuItem>
            </Menu>
          </Paper>
        )}
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title">
              Visitor Information
            </DialogTitle>
            <DialogContent>
              {/* <DialogContentText>Some Demo Contents.</DialogContentText> */}
              <div>
                <Accordion defaultExpanded>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <Typography>MAIN INFORMATION</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper>
                      <TableWrapper>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Country:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  India
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                City:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  Kolkata
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                First Seen:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  May 16 2024 | 4:09 PM
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Last Seen:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  May 16 2024 | 4:09 PM
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Total Page views:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  2
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Last Page Location:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  equitechsoftwares.in
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Browser:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  Google Chrome
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Online Status:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                    color: "green",
                                  }}
                                >
                                  Online
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    Visitor Information
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper>
                      <TableWrapper>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Name:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  Amit Dutta
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Email Id:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  amit@gmail.com
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Phone:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  9888899999
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Country:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  India
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                Company:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  Demo Company
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2-content"
                    id="panel2-header"
                  >
                    <Typography>PAGE VIEWS</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paper>
                      <TableWrapper>
                        <Table>
                          {/* <TableHead>
                            <TableRow>
                              <TableCell>Name</TableCell>
                              <TableCell>Count</TableCell>
                            </TableRow>
                          </TableHead> */}
                          <TableBody>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                appointcare.com:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <Chip label="2" color="success" />
                                </span>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                scope="row"
                                style={{ borderBottom: 0 }}
                              >
                                appointcare.com/privacy:
                                <span
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "15px",
                                  }}
                                >
                                  <Chip label="12" color="success" />
                                </span>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableWrapper>
                    </Paper>
                  </AccordionDetails>
                </Accordion>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </Toolbar>
  );
};

function EnhancedTable() {
  const [viewStyle, setViewStyle] = useState("List");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      // setViewStyle("Card");
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar
          numSelected={selected.length}
          doChangeProps={setViewStyle}
        />
      </Paper>
      {/* <ViewMenu doChangeProps={setViewStyle} /> */}
      {viewStyle === "List" ? (
        <Paper>
          <TableContainer>
            <Table
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={`${row.id}-${index}`}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                            onClick={(event) => handleClick(event, row.id)}
                          />
                        </TableCell>
                        <TableCell>{row.customer}</TableCell>
                        <TableCell>{row.customerEmail}</TableCell>
                        <TableCell>198.90.87.98</TableCell>
                        <TableCell>India</TableCell>
                        <TableCell>Widget 1</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={7} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : (
        <Box m={1} pt={3} style={{ margin: "0" }}>
          <Grid container spacing={6}>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Not Registered" color="warning" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Etiam rhoncus. Maecenas tempus, tellus eget condimentum rhoncus, sem quam semper libero, sit amet adipiscing sem neque sed ipsum."
                chip={<Chip label="Registered" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Nam pretium turpis et arcu. Duis arcu tortor, suscipit eget, imperdiet nec, imperdiet iaculis, ipsum. Sed aliquam ultrices mauris."
                chip={<Chip label="Registered" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
            <Grid item xs={12} lg={6} xl={3}>
              <Project
                title="Anna Walley"
                description="Curabitur ligula sapien, tincidunt non, euismod vitae, posuere imperdiet, leo. Maecenas malesuada. Praesent congue erat at massa."
                chip={<Chip label="Registered" color="success" />}
                image="/static/img/avatars/avatar-1.jpg"
              />
            </Grid>
          </Grid>
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

const AvatarGroup = styled(MuiAvatarGroup)`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const Project = ({ image, title, description, chip }) => {
  return (
    <Paper>
      <Card>
        <Checkbox
          value="remember"
          color="primary"
          style={{ position: "relative", top: "0px", left: "0px" }}
        />
        <CardContent>
          <Typography gutterBottom variant="h4" component="h2">
            Kolkata
          </Typography>
          <Typography gutterBottom component="p">
            198.90.87.98
          </Typography>
          <Typography mb={4} color="textSecondary" component="p">
            Segment: India <br />
            Widget: Widget 1 <br />
            Date: June 16, 2024 <br />
            Time: 10:48 PM
          </Typography>
          {chip}
        </CardContent>
      </Card>
    </Paper>
  );
};

function PeopleTab() {
  const [open, setOpen] = useState(false);
  const [RTab, setRTab] = React.useState("All Visitors");

  const [value, setValue] = React.useState("#ffffff");
  const handleChange = (newValue) => {
    setValue(newValue);
  };

  const [schedulingType, setSchedulingType] = useState("Collective");
  const handleSchedulingTypeChange = (event) => {
    setSchedulingType(event.target.value);
  };
  const [roundRobinHost, setRoundRobinHost] = useState("false");
  const handleRoundRobinHostChange = (event) => {
    setRoundRobinHost(JSON.stringify(event.target.checked));
  };
  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange1 = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };
  return (
    <Grid container>
      <Grid md={3}>
        <Paper>
          <MenuList style={{ height: "300px", padding: "0" }}>
            {RTab == "All Visitors" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("All Visitors");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>All Visitors</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("All Visitors");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>All Visitors</ListItemText>
              </MenuItem>
            )}
            {RTab == "Instaconnect Feed" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Instaconnect Feed");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Instaconnect Feed</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Instaconnect Feed");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Instaconnect Feed</ListItemText>
              </MenuItem>
            )}
            {RTab == "Startem" ? (
              <MenuItem
                onClick={(e) => {
                  setRTab("Startem");
                }}
                style={{
                  borderLeft: "3px solid",
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                  minHeight: "50px",
                }}
              >
                <ListItemText>Startem</ListItemText>
              </MenuItem>
            ) : (
              <MenuItem
                onClick={(e) => {
                  setRTab("Startem");
                }}
                style={{ minHeight: "50px" }}
              >
                <ListItemText>Startem</ListItemText>
              </MenuItem>
            )}
          </MenuList>
        </Paper>
        <Typography
          variant="body2"
          textAlign="right"
          style={{ paddingTop: "10px" }}
        >
          <Button
            size="small"
            variant="contained"
            color="primary"
            sx={{ mx: "0.5rem" }}
            onClick={() => setOpen(true)}
          >
            <AddIcon /> Add New Feed
          </Button>
        </Typography>
      </Grid>
      <Grid md={9} sx={{ paddingLeft: "25px" }}>
        <EnhancedTable />
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" mb={0}>
              Add New Feed
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add people to the people list. For a seamless one-click entry
                for your people, please fill the form field.
              </DialogContentText>
              <br />
              <Grid container spacing={6}>
                <Grid item md={12}>
                  <TextField
                    id="username"
                    label="Feed Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={12}>
                  <FormControl fullWidth my={2} variant="outlined">
                    <MuiColorInput
                      format="hex"
                      value={value}
                      onChange={handleChange}
                      label="Button Color"
                      id="biography"
                      variant="outlined"
                      defaultValue=""
                    />
                  </FormControl>
                </Grid>
                <Grid item md={12}>
                  <InputLabel id="demo-multiple-chip-label">Segment</InputLabel>
                  <Select
                    style={{ width: "100%" }}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={personName}
                    onChange={handleChange1}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={12} mb={4}>
                  <ListItem alignItems="flex-start" disablePadding>
                    <Grid container>
                      <Grid md={6}>
                        <Typography variant="subtitle1">
                          Who has access
                          <Typography
                            color="success"
                            style={{ paddingLeft: "12px !important" }}
                          >
                            {/* <small
                              style={{
                                position: "relative",
                                bottom: "4px",
                                paddingLeft: "2px",
                              }}
                            >
                              People in the group take turns and only one person
                              will show up for the event
                            </small> */}
                          </Typography>
                        </Typography>
                      </Grid>
                      <Grid md={12}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch onChange={handleRoundRobinHostChange} />
                            }
                            label="Add all team members, including future members"
                          />
                        </FormGroup>
                      </Grid>
                    </Grid>
                  </ListItem>
                </Grid>
                {roundRobinHost == "false" ? (
                  <Grid item md={12}>
                    <Grid container spacing={6}>
                      <Grid item md={12}>
                        <Select
                          style={{ width: "100%" }}
                          autoFocus
                          value="xsd"
                          inputProps={{
                            name: "max-width",
                            id: "max-width",
                          }}
                        >
                          <MenuItem value="xsd">Shabbir Bakh</MenuItem>
                          <MenuItem value="ssm">James</MenuItem>
                          <MenuItem value="l">Niaz Khan</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item md={12}>
                        <Box>
                          <Grid
                            item
                            md={12}
                            style={{
                              padding: "15px",
                              border: "1px solid #c4c4c4",
                              marginBottom: "15px",
                            }}
                          >
                            <ListItem alignItems="flex-start" disablePadding>
                              <Grid container>
                                <Grid md={10}>
                                  <Typography variant="subtitle1">
                                    Niaz Khan
                                  </Typography>
                                </Grid>
                                <Grid md={2}>
                                  <DeleteIcon style={{ float: "right" }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        </Box>
                        <Box>
                          <Grid
                            item
                            md={12}
                            style={{
                              padding: "15px",
                              border: "1px solid #c4c4c4",
                              marginBottom: "15px",
                            }}
                          >
                            <ListItem alignItems="flex-start" disablePadding>
                              <Grid container>
                                <Grid md={10}>
                                  <Typography variant="subtitle1">
                                    James
                                  </Typography>
                                </Grid>
                                <Grid md={2}>
                                  <DeleteIcon style={{ float: "right" }} />
                                </Grid>
                              </Grid>
                            </ListItem>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </Grid>
  );
}

export default function BasicTabs() {
  const [open, setOpen] = useState(false);
  const [age, setAge] = React.useState("");
  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <React.Fragment>
      <Helmet title="Visitors" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Visitors
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/widget/dashboard">
              Dashboard
            </Link>
            <Typography> Visitors</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <Divider my={6} />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <PeopleTab />
        </Grid>
      </Grid>
      <Paper mt={4}>
        <div>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title"
          >
            <DialogTitle id="form-dialog-title" mb={0}>
              Add New Attendee
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Add attendees to the attendee list. For a seamless one-click
                registration/entry for your attendees, please fill all mandatory
                form fields.
              </DialogContentText>
              <br />
              <Grid container spacing={6}>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="First Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Last Name"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    id="username"
                    label="Email Id"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Job Title"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="Organization"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <TextField
                    id="username"
                    label="City"
                    defaultValue=""
                    variant="outlined"
                    fullWidth
                    my={2}
                  />
                </Grid>
                <Grid item md={6}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        country
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={age}
                        label="Age"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Canada</MenuItem>
                        <MenuItem value={20}>Usa</MenuItem>
                        <MenuItem value={30}>India</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <FormControl>
                    {/* <FormLabel id="demo-radio-buttons-group-label">
                      Gender
                    </FormLabel> */}
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Register attendee"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Invite Attendee"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={12} className="checkboxPadding">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Send invitation email with unique access link"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={() => setOpen(false)} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </Paper>
    </React.Fragment>
  );
}
