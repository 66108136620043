import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Drawer from "@mui/material/Drawer";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Button,
  Grid,
  Typography,
  DialogActions,
  TextField,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
import axios from "axios";

const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  breakDate: Yup.date().required("Required"),
  breakTime: Yup.string().required("Required"),
  duration: Yup.number().required("Required"),
  description: Yup.string().required("Required"),
  tags: Yup.array().min(1, "At least one tag is required"),
});

function AvailableTracks({ onTrackSelect }) {
  const [tracks, setTracks] = useState([]);

  useEffect(() => {
    // Fetch tracks from backend (GraphQL query for tracks)
    const fetchTracks = async () => {
      const response = await axios.post("/graphql", {
        query: `
        query {
          tracks {
            id
            name
          }
        }`,
      });
      setTracks(response.data.data.tracks);
    };

    fetchTracks();
  }, []);

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple
        options={tracks}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => onTrackSelect(newValue)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={option.id}
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tracks"
            placeholder="Select Tracks"
            fullWidth
          />
        )}
      />
    </Grid>
  );
}

function BreakTags({ onTagChange }) {
  const [tags, setTags] = useState([]);

  return (
    <Grid item xs={12}>
      <Autocomplete
        multiple
        freeSolo
        options={tags}
        onChange={(event, newValue) => onTagChange(newValue)}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={index} label={option} {...getTagProps({ index })} />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Tags"
            placeholder="Add Tags"
            fullWidth
          />
        )}
      />
    </Grid>
  );
}

function BreakTab() {
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      breakDate: null,
      breakTime: null,
      duration: "",
      description: "",
      tracks: [],
      tags: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log("!!!!!!!!!! submit");
      // Extract only the date for breakDate and time for breakTime
      const breakDate = values.breakDate
        ? values.breakDate.toISOString().split("T")[0]
        : null;
      const breakTime = values.breakTime
        ? values.breakTime.toISOString().split("T")[1].split("Z")[0]
        : null;

      // Prepare mutation payload
      const payload = {
        query: `
        mutation CreateBreak($input: CreateBreakInput!) {
          createBreak(input: $input) {
            id
            name
          }
        }
      `,
        variables: {
          input: {
            name: values.name,
            breakDate: breakDate, // Store only the date
            breakTime: breakTime, // Store only the time
            duration: parseInt(values.duration),
            description: values.description,
            trackIds: values.tracks.map((track) => track.id),
            tags: values.tags,
          },
        },
      };

      // Send request to backend
      try {
        const response = await axios.post("/graphql", payload);
        console.log("Break created:", response.data.data.createBreak);
      } catch (error) {
        console.error("Error creating break:", error);
      }
    },
  });

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="inherit"
        onClick={toggleDrawer("right", true)}
        style={{ marginRight: "1rem" }}
      >
        <AddIcon />
        Break
      </Button>
      <Drawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Box sx={{ width: "600px", padding: "20px" }} role="presentation">
          <Typography variant="h3" gutterBottom>
            Add Break
          </Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Grid>
              <Grid item xs={4}>
                <DatePicker
                  label="Break Date"
                  value={formik.values.breakDate}
                  onChange={(value) => formik.setFieldValue("breakDate", value)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error:
                        formik.touched.breakDate &&
                        Boolean(formik.errors.breakDate),
                      helperText:
                        formik.touched.breakDate && formik.errors.breakDate,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TimePicker
                  label="Break Time"
                  value={formik.values.breakTime}
                  onChange={(value) => formik.setFieldValue("breakTime", value)}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error:
                        formik.touched.breakTime &&
                        Boolean(formik.errors.breakTime),
                      helperText:
                        formik.touched.breakTime && formik.errors.breakTime,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  id="duration"
                  name="duration"
                  label="Duration (Minutes)"
                  fullWidth
                  value={formik.values.duration}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.duration && Boolean(formik.errors.duration)
                  }
                  helperText={formik.touched.duration && formik.errors.duration}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  multiline
                  rows={4}
                  fullWidth
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>
              <AvailableTracks
                onTrackSelect={(tracks) =>
                  formik.setFieldValue("tracks", tracks)
                }
              />
              <BreakTags
                onTagChange={(tags) => formik.setFieldValue("tags", tags)}
              />
            </Grid>
            <DialogActions>
              <Button onClick={toggleDrawer("right", false)} color="primary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Done
              </Button>
            </DialogActions>
          </form>
        </Box>
      </Drawer>
    </React.Fragment>
  );
}

function Break() {
  return (
    <React.Fragment>
      <BreakTab />
    </React.Fragment>
  );
}

export default Break;
