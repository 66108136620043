/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet-async";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tabs from "@mui/material/Tabs";
import { ChevronLeft, ChevronRight, Search as SearchIcon } from "react-feather";
import { darken } from "polished";
import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import { axiosInstance } from "../../../utils/axios";

import {
  Paper,
  Typography,
  Divider as MuiDivider,
  List,
  ListItem,
  ListItemText,
  Grid,
  Card,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  InputBase,
  Table,
  TableBody,
  TableCell,
  TableRow,
  DialogContentText,
  TextField,
  MenuItem,
  Avatar as MuiAvatar,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VideocamIcon from "@mui/icons-material/Videocam";
import LanguageIcon from "@mui/icons-material/Language";
import axios from "axios";
import { PersonAdd } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

import { tableCellClasses } from "@mui/material/TableCell";

import {
  AccessTime,
  CalendarMonth,
  Language,
  Videocam,
  ViewColumn,
  CalendarViewMonth,
  ModeEditOutline,
  Add as AddIcon,
} from "@mui/icons-material";
import { Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import { spacing } from "@mui/system";
const Divider = styled(MuiDivider)(spacing);

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

const ChatContainer = styled(Grid)`
  width: 100%;
  height: 100%;
`;

const EventDetailsContainer = styled(Paper)`
  padding: 16px;
  margin-bottom: 16px;
`;

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
`;

const DateContainer = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;

const TimeSlotButton = styled(Button)`
  width: 90%;
  padding: 4px;
  margin-left: 5%;
  margin: 3px 0px;
`;

const ScrollableSlotsContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding: 8px;
`;

const CenteredContent = styled.div`
  text-align: center;
`;

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const ChatSidebar = styled(Grid)`
  border-right: 1px solid ${(props) => props.theme.palette.divider};
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 14px;
  }
`;

const CustomTableRow = styled(TableRow)`
  // &:nth-of-type(odd) {
  //   background-color: rgba(0, 0, 0, 0.025);
  // }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

function generateTimeSlots(timeStart, timeEnd, interval = 30) {
  const slots = [];
  let startTime = dayjs(timeStart, "hh:mm a");
  const endTime = dayjs(timeEnd, "hh:mm a");

  while (startTime.isBefore(endTime)) {
    slots.push({
      timeStart: startTime.format("hh:mm a"),
      timeEnd: startTime.add(interval, "minute").format("hh:mm a"),
    });
    startTime = startTime.add(interval, "minute");
  }

  return slots;
}

function getAvailableSlots(data, selectedDate) {
  console.log("!!!!!!! get available date", selectedDate);
  console.log("!!!!!!! get available", data);
  if (!data) return [];

  const dayOfWeek = selectedDate.format("dddd");
  const selectedDateStr = selectedDate.format("YYYY-MM-DD");

  // Check for an override
  const override = data.overrides?.find(
    (o) => dayjs(o.date).format("YYYY-MM-DD") === selectedDateStr
  );

  console.log("!!!!!!!!!!! overrides", override);

  let timeSlots = [];

  if (override && !override.isUnavailable) {
    // Generate time slots for each entry in override.timeSlots
    override.timeSlots.forEach((slot) => {
      const slots = generateTimeSlots(slot.timeStart, slot.timeEnd);
      timeSlots = [...timeSlots, ...slots]; // Combine slots into one array
    });
    console.log("!!!!!!!!! here", dayOfWeek);
  } else if (data.days?.includes(dayOfWeek)) {
    // Handle normal day availability
    timeSlots = generateTimeSlots(
      data.timeSlots[0].timeStart,
      data.timeSlots[0].timeEnd
    );
  } else {
    return []; // No availability
  }

  console.log("!!!!!!! get available timeslots", timeSlots);
  return timeSlots;
}

function MonthView({ availabilityData }) {
  const [value, setValue] = useState(dayjs()); // Selected date
  const [availableSlots, setAvailableSlots] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [formData, setFormData] = useState({ name: "", email: "", notes: "" });
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const slots = getAvailableSlots(availabilityData, value);
    setAvailableSlots(slots);
  }, [value, availabilityData]);

  const handleBooking = (slot) => {
    setSelectedSlot(slot);
    setOpenModal(true);
  };

  const handleSubmitBooking = () => {
    const bookingData = {
      date: value.format("YYYY-MM-DD"),
      timeStart: selectedSlot.timeStart,
      timeEnd: selectedSlot.timeEnd,
      name: formData.name,
      email: formData.email,
      notes: formData.notes,
    };

    axiosInstance
      .post("/graphql", {
        query: `
        mutation CreateBooking($input: BookingInput!) {
          createBooking(input: $input) {
            id
            date
            timeStart
            timeEnd
            name
            email
            notes
          }
        }
      `,
        variables: {
          input: bookingData,
        },
      })
      .then((response) => {
        if (response.data?.createBooking) {
          setSuccessMessage("Your booking was saved successfully!");
        } else {
          setSuccessMessage("Failed to save your booking. Please try again.");
        }
      })
      .catch((error) => {
        console.error("Error booking slot", error);
        setSuccessMessage("Failed to save your booking. Please try again.");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <ChatContainer container component={Card}>
      <Grid container spacing={3}>
        {/* Event Details Section */}
        <Grid item xs={12} md={3}>
          <EventDetailsContainer>
            <Typography variant="h6">This is the demo title</Typography>
            <IconTextContainer>
              <IconWrapper>
                <AccessTimeIcon fontSize="small" />
              </IconWrapper>
              <Typography variant="body2">30 mins</Typography>
            </IconTextContainer>
            <IconTextContainer>
              <IconWrapper>
                <VideocamIcon fontSize="small" />
              </IconWrapper>
              <Typography variant="body2">Video Call</Typography>
            </IconTextContainer>
            <IconTextContainer>
              <IconWrapper>
                <LanguageIcon fontSize="small" />
              </IconWrapper>
              <Typography variant="body2">Asia/Kolkata</Typography>
            </IconTextContainer>
          </EventDetailsContainer>
        </Grid>

        {/* Calendar Section */}
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              value={value}
              onChange={(newValue) => setValue(newValue)}
            />
          </LocalizationProvider>
        </Grid>

        {/* Available Slots Section */}
        <Grid item xs={12} md={3}>
          <DateContainer>
            <Typography variant="h6">
              {value.format("dddd, MMMM D, YYYY")}
            </Typography>
          </DateContainer>

          <ScrollableSlotsContainer>
            {availableSlots.length > 0 ? (
              availableSlots.map((slot, index) => (
                <TimeSlotButton
                  key={index}
                  variant="outlined"
                  size="small"
                  onClick={() => handleBooking(slot)}
                >
                  {slot.timeStart}
                </TimeSlotButton>
              ))
            ) : (
              <Typography
                variant="body2"
                style={{ padding: "16px", textAlign: "center" }}
              >
                No available slots
              </Typography>
            )}
          </ScrollableSlotsContainer>
        </Grid>
      </Grid>

      {/* Booking Dialog */}
      <Paper mt={4}>
        <Dialog
          open={openModal}
          onClose={() => setOpenModal(false)}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>Confirm Your Booking</DialogTitle>
          <DialogContent>
            {successMessage ? (
              <Typography variant="subtitle1" style={{ color: "green" }}>
                {successMessage}
              </Typography>
            ) : (
              <>
                <Typography variant="subtitle1">Booking Details:</Typography>
                <Typography variant="body2">
                  Date: {value.format("dddd, MMMM D, YYYY")}
                </Typography>
                <Typography variant="body2">
                  Time: {selectedSlot?.timeStart} - {selectedSlot?.timeEnd}
                </Typography>

                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Your Name"
                  type="text"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <TextField
                  margin="dense"
                  id="email"
                  label="Email Address"
                  type="email"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                <TextField
                  margin="dense"
                  id="notes"
                  label="Additional Notes"
                  type="text"
                  fullWidth
                  multiline
                  rows={3}
                  maxRows={4}
                  name="notes"
                  value={formData.notes}
                  onChange={handleChange}
                />
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenModal(false)} color="primary">
              Close
            </Button>
            {!successMessage && (
              <Button onClick={handleSubmitBooking} color="primary">
                Submit
              </Button>
            )}
          </DialogActions>
        </Dialog>
      </Paper>
    </ChatContainer>
  );
}

function TableRows({ rows, tableRowRemove, onValUpdate }) {
  return rows.map((rowsData, index) => {
    var opt = "Email";
    const { name, email, profile } = rowsData;
    return (
      <tr key={index}>
        <td>
          <TextField
            id="username"
            label={opt}
            defaultValue={name}
            variant="outlined"
            fullWidth
            my={2}
            rows={4}
            maxRows={6}
            name="name"
            onChange={(event) => onValUpdate(index, event)}
            style={{ marginBottom: "10px" }}
          />
        </td>
        <td style={{ textAlign: "center" }}>
          <DeleteIcon onClick={() => tableRowRemove(index)} />
        </td>
      </tr>
    );
  });
}
var i = false;

function Table1() {
  const [rows, initRow] = useState([]);
  const addRowTable = () => {
    const data = {
      name: "",
    };
    initRow([...rows, data]);
  };
  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };
  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  if (i === false) {
    addRowTable();
    i = true;
  }
  return (
    <>
      <Typography variant="body2" gutterBottom>
        <button
          onClick={addRowTable}
          style={{
            border: "0",
            backgroundColor: "transparent",
            fontWeight: "bold",
            color: "#376fd1",
            width: "100%",
            textAlign: "right",
          }}
          color="primary"
        >
          Add
          <AddIcon style={{ position: "relative", top: "7px" }} />
        </button>
      </Typography>
      <table style={{ width: "100%" }}>
        <tbody>
          <TableRows
            rows={rows}
            tableRowRemove={tableRowRemove}
            onValUpdate={onValUpdate}
          />
        </tbody>
      </table>
    </>
  );
}

function CustomizedTableDemo() {
  return (
    <Card mb={6} mt={6}>
      <Paper style={{ padding: "0" }}>
        <Table>
          <TableBody>
            {rows.map((row) => (
              <CustomTableRow key={row.id}>
                <CustomTableCell component="th" scope="row">
                  UTC -12:00
                </CustomTableCell>
                <CustomTableCell align="right">
                  Canada/Yukon Time
                  <br />
                  <small style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                    (UTC-07:00) Yukon
                  </small>
                </CustomTableCell>
              </CustomTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Card>
  );
}

function Preview() {
  const [view, setView] = React.useState(-1);
  const [avail, setAvail] = useState({});

  const handleChange = (event, newValue) => {
    setView(newValue);
  };
  const handleChange1 = () => {
    setView(0);
  };
  const getAvailabilityQuery = `query {
              getAllAvailabilities {
                id
                name
                timezone
                days
                timeSlots {
                  timeStart
                  timeEnd
                }
                overrides {
                  date
                  timeSlots {
                    timeStart
                    timeEnd
                  }
                  isUnavailable
                }
              }
            }`;

  useEffect(() => {
    const getAvail = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: getAvailabilityQuery,
        });

        if (response.data.errors) {
          throw new Error(response.data.errors[0].message);
        }

        const newAvailability = response.data.getAllAvailabilities;
        const lastAvailability = newAvailability[newAvailability.length - 1];
        console.log("Last availability:", lastAvailability);
        setAvail(lastAvailability); // Assuming you want to set the last one only
      } catch (error) {
        console.error("Error fetching availability:", error);
      }
    };

    getAvail();
  }, []);
  return (
    <React.Fragment>
      {view === -1 ? (
        <Wrapper style={{ width: "60%" }}>
          <Helmet title="Preview" />
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid container>
              <Grid item md={12} style={{ marginBottom: "15px" }}>
                <CenteredContent>
                  <BigAvatar
                    alt="Remy Sharp"
                    src="/static/img/avatars/avatar-1.jpg"
                  />
                  <label htmlFor="raised-button-file">
                    <Typography variant="h2" display="block" gutterBottom>
                      Shabbir Bakh
                    </Typography>
                  </label>
                </CenteredContent>
              </Grid>
              <Grid md={12}>
                <Paper>
                  <MenuList
                    className="preview"
                    style={{ height: "550px", paddingLeft: "40px" }}
                  >
                    <MenuItem
                      style={{
                        minHeight: "50px",
                        borderBottom: "1px solid #eee",
                      }}
                      onClick={handleChange1}
                    >
                      <ListItem
                        disableGutters
                        secondaryAction={
                          <IconButton aria-label="comment">
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        }
                      >
                        <ListItemText
                          primary="15 Min Meeting"
                          secondary={
                            <React.Fragment>
                              <Typography
                                sx={{ display: "inline" }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                                secondaryAction={
                                  <IconButton aria-label="comment">
                                    <AccessTime />
                                  </IconButton>
                                }
                              >
                                15m
                              </Typography>
                            </React.Fragment>
                          }
                        />
                      </ListItem>
                    </MenuItem>
                  </MenuList>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Wrapper>
      ) : view === 0 ? (
        <Wrapper style={{ width: "60%" }}>
          <Helmet title="Book" />
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Equitech Softwares
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Tabs
                  value={view}
                  onChange={handleChange}
                  aria-label="icon tabs example"
                >
                  <Tab icon={<CalendarMonth />} aria-label="phone" />
                  <Tab icon={<CalendarViewMonth />} aria-label="favorite" />
                  <Tab icon={<ViewColumn />} aria-label="person" />
                </Tabs>
              </div>
            </Grid>
          </Grid>

          <Divider my={6} />

          <MonthView availabilityData={avail} />
        </Wrapper>
      ) : view === 1 ? (
        <Wrapper style={{ width: "100%" }}>
          <Helmet title="Book" />
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Equitech Softwares
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Tabs
                  value={view}
                  onChange={handleChange}
                  aria-label="icon tabs example"
                >
                  <Tab icon={<CalendarMonth />} aria-label="phone" />
                  <Tab icon={<CalendarViewMonth />} aria-label="favorite" />
                  <Tab icon={<ViewColumn />} aria-label="person" />
                </Tabs>
              </div>
            </Grid>
          </Grid>
          <Divider my={6} />
          {/* <WeekView /> */}
          Weekly View
        </Wrapper>
      ) : (
        <Wrapper style={{ width: "95%" }}>
          <Helmet title="Book" />
          <Grid justifyContent="space-between" container spacing={10}>
            <Grid item>
              <Typography variant="h3" gutterBottom display="inline">
                Equitech Softwares
              </Typography>
            </Grid>
            <Grid item>
              <div>
                <Tabs
                  value={view}
                  onChange={handleChange}
                  aria-label="icon tabs example"
                >
                  <Tab icon={<CalendarMonth />} aria-label="phone" />
                  <Tab icon={<CalendarViewMonth />} aria-label="favorite" />
                  <Tab icon={<ViewColumn />} aria-label="person" />
                </Tabs>
              </div>
            </Grid>
          </Grid>
          <Divider my={6} />
          {/* <ColumnView /> */}
          Column View
        </Wrapper>
      )}
    </React.Fragment>
  );
}

export default Preview;
