import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../utils/axios";
import useAuth from "../../../hooks/useAuth";
import { useLocation } from "react-router-dom";
import {
  Alert,
  Box,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Checkbox,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Snackbar,
  Menu,
  MenuItem,
  ListItemText,
} from "@mui/material";
import {
  Add as AddIcon,
  Archive as ArchiveIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

function createData(id, product, date, total, status, method) {
  return { id, product, date, total, status, method };
}

const rows = [
  createData(
    "000253",
    "Salt & Pepper Grinder",
    "2023-01-02",
    "$32,00",
    0,
    "Visa"
  ),
  createData("000254", "Backpack", "2023-01-04", "$130,00", 0, "PayPal"),
  createData(
    "000255",
    "Pocket Speaker",
    "2023-01-04",
    "$80,00",
    2,
    "Mastercard"
  ),
  createData("000256", "Glass Teapot", "2023-01-08", "$45,00", 0, "Visa"),
  createData(
    "000257",
    "Unbreakable Water Bottle",
    "2023-01-09",
    "$40,00",
    0,
    "PayPal"
  ),
  createData("000258", "Spoon Saver", "2023-01-14", "$15,00", 0, "Mastercard"),
  createData("000259", "Hip Flash", "2023-01-16", "$25,00", 1, "Visa"),
  createData("000260", "Woven Slippers", "2023-01-22", "$20,00", 0, "PayPal"),
  createData("000261", "Womens Watch", "2023-01-22", "$65,00", 2, "Visa"),
  createData(
    "000262",
    "Over-Ear Headphones",
    "2023-01-23",
    "$210,00",
    0,
    "Mastercard"
  ),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

// const headCells = [
//   { id: "id", alignment: "right", label: "Order ID" },
//   { id: "product", alignment: "left", label: "Product" },
//   { id: "date", alignment: "left", label: "Date" },
//   { id: "total", alignment: "right", label: "Total" },
//   { id: "status", alignment: "left", label: "Status" },
//   { id: "method", alignment: "left", label: "Payment Method" },
//   { id: "actions", alignment: "right", label: "Actions" },
// ];

const headCells = [
  { id: "title", alignment: "left", label: "Title" },
  { id: "url", alignment: "left", label: "Url" },
  { id: "duration", alignment: "left", label: "Duration" },
];
const EnhancedTableHead = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTableToolbar = (props) => {
  // Here was 'let'
  const { numSelected } = props;

  return (
    <Toolbar>
      <Spacer />
      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" size="large">
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list" size="large">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

function EnhancedTable({ eventTypes, setEventTypes }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("customer");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div>
      <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={eventTypes.length}
            />
            <TableBody>
              {stableSort(eventTypes, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </TableCell>

                      <TableCell align="left">
                        <Box>
                          <Typography
                            variant="h4"
                            gutterBottom
                            display="inline"
                          >
                            {row.title}
                          </Typography>
                          <br />
                          {row.description}
                        </Box>
                      </TableCell>
                      <TableCell align="left">{row.url}</TableCell>
                      <TableCell align="left">{row.duration} Min's</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={8} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function EventTypes() {
  const { user } = useAuth();
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [eventTypes, setEventTypes] = useState([]);
  const [anchorElM, setAnchorElM] = React.useState(null);
  const openM = Boolean(anchorElM);
  const location = useLocation();
  const message = location.state?.message;
  const handleClickM = (event) => {
    setAnchorElM(event.currentTarget);
  };
  const handleCloseM = () => {
    setAnchorElM(null);
  };

  const handleCloseAlert = () => {
    setAlert({ ...alert, open: false });
  };

  // Fetch groups initially (replace with your fetch logic)
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await axiosInstance.post("/graphql", {
          query: `
            query {
              getEventTypes {
                id
                title
                url
                duration
                description
              }
            }
          `,
        });

        if (response.data.errors) {
          console.error(response.data.errors);
        } else {
          setEventTypes(response.data.getEventTypes);
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    };

    fetchGroups();
  }, []);

  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Event Types
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/calendar/dashboard">
              Dashboard
            </Link>
            <Typography>Event Types</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="primary"
              // component={NavLink}
              // to="/calendar/add-event-type"
              id="basic-button"
              aria-controls={openM ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openM ? "true" : undefined}
              onClick={handleClickM}
            >
              <AddIcon />
              New Event Type
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorElM}
              open={openM}
              onClose={handleCloseM}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem disabled="true">
                <ListItemText variant="h5">Create Event On</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/calendar/add-event-type">
                {/* <ListItemIcon style={{ minWidth: "30px" }}>
                  <EditIcon fontSize="small" />
                </ListItemIcon> */}
                <ListItemText>{user.name}</ListItemText>
              </MenuItem>
              <MenuItem component={Link} to="/calendar/add-event-types">
                {/* <ListItemIcon style={{ minWidth: "30px" }}>
                  <ContentCopy fontSize="small" />
                </ListItemIcon> */}
                <ListItemText>Insta Calendar</ListItemText>
              </MenuItem>
            </Menu>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={alert.severity}>
          {alert.message}
        </Alert>
      </Snackbar>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <div>
            {message && <div>{message}</div>}
            {/* Other dashboard content */}
          </div>
          {/* Replace with your groups table component */}
          <EnhancedTable
            eventTypes={eventTypes}
            setEventTypes={setEventTypes}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default EventTypes;
