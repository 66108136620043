import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet-async";
import { NavLink } from "react-router-dom";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import UserGroup from "./UserGroup";
import Segment from "./Segment";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  Divider as MuiDivider,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import {
  Add as AddIcon,
  ArrowBack as ArrowBackIcon,
} from "@mui/icons-material";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Settings() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Helmet title="Settings" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Settings
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to="/spaces/space-apps">
              Dashboard
            </Link>
            <Link component={NavLink} to="/spaces/space-apps">
              Settings
            </Link>
            <Typography>Settings</Typography>
          </Breadcrumbs>
        </Grid>
        {/* <Grid item>
          <div>
            <Button
              variant="contained"
              color="inherit"
              sx={{ mx: "0.5rem" }}
              component={Link}
              to="/widget/dashboard"
            >
              <ArrowBackIcon />
              Back
            </Button>
          </div>
        </Grid> */}
      </Grid>
      <Divider my={6} />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="User Groups" {...a11yProps(0)} />
            <Tab label="Segments" {...a11yProps(1)} />
            <Tab label="Appointment Settings" {...a11yProps(2)} />
            <Tab label="API Keys" {...a11yProps(3)} />
            <Tab label="Webhooks" {...a11yProps(4)} />
            <Tab label="Translations" {...a11yProps(5)} />
            <Tab label="Connector" {...a11yProps(6)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <UserGroup />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Segment />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          Subscription
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          Usage
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          Billing
        </CustomTabPanel>
      </Box>
    </React.Fragment>
  );
}
