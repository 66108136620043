import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import axios from "axios";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Autocomplete,
  Button,
  Chip,
  Drawer,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

// Styled Components
const PaperStyled = styled(Paper)`
  padding: 20px;
`;

// Form Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  sessionDate: Yup.date().required("Break Date is required"),
  sessionTime: Yup.date().required("Break Time is required"),
  duration: Yup.number()
    .required("Duration is required")
    .min(1, "Must be at least 1 minute"),
  host: Yup.array().min(1, "At least one Host is required"),
  speaker: Yup.array().min(1, "At least one Speaker is required"),
  description: Yup.string().required("Description is required"),
  tracks: Yup.array().min(1, "At least one Track must be selected"),
});

const SessionForm = ({ onClose, onSave, setDrawerOpen }) => {
  const [hostOptions, setHostOptions] = useState([]);
  const [supplierOptions, setSupplierOptions] = useState([]);
  const [trackOptions, setTrackOptions] = useState([]);
  const [tags, setTags] = useState([]);
  // Fetch hosts and suppliers (Hosts, Co-hosts for Host and Co-Host, Speakers for Supplier)
  useEffect(() => {
    axios
      .post("/graphql", {
        query: `
        query {
          getAllAttendees {
            id
            firstName
            lastName
            type
          }
        }
      `,
      })
      .then((response) => {
        const attendees = response.data.data.getAllAttendees;
        const hosts = attendees
          .filter(
            (attendee) =>
              attendee.type === "ATTENDEE" || attendee.type === "HOST"
          )
          .map((attendee) => ({
            label: `${attendee.firstName} ${attendee.lastName}`,
            value: attendee.id,
          }));
        setHostOptions(hosts);

        const suppliers = attendees
          .filter((attendee) => attendee.type === "SPEAKER")
          .map((attendee) => ({
            label: `${attendee.firstName} ${attendee.lastName}`,
            value: attendee.id,
          }));
        setSupplierOptions(suppliers);
      })
      .catch((error) => {
        console.error("Error fetching attendees:", error);
      });
  }, []);

  // Fetch tracks
  useEffect(() => {
    axios
      .post("/graphql", {
        query: `
        query {
          tracks {
            id
            name
          }
        }
      `,
      })
      .then((response) => {
        const tracks = response.data.data.tracks.map((track) => ({
          label: track.name,
          value: track.id,
        }));
        setTrackOptions(tracks);
      })
      .catch((error) => {
        console.error("Error fetching tracks:", error);
      });
  }, []);

  return (
    <Formik
      initialValues={{
        name: "",
        sessionDate: null,
        sessionTime: null,
        duration: "",
        host: [],
        coHost: [],
        speaker: [],
        description: "",
        tracks: [],
        tags: [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        // Extract only the date part for sessionDate and time part for sessionTime
        // const sessionDate = values.sessionDate
        //   ? values.sessionDate.toISOString().split("T")[0]
        //   : null;

        // const sessionDate = values.sessionDate
        //   ? `${values.sessionDate.getFullYear()}-${(
        //       values.sessionDate.getMonth() + 1
        //     )
        //       .toString()
        //       .padStart(2, "0")}-${values.sessionDate
        //       .getDate()
        //       .toString()
        //       .padStart(2, "0")}`
        //   : null;

        const sessionDate = values.sessionDate
          ? values.sessionDate.toLocaleDateString("en-CA") // 'YYYY-MM-DD' format
          : null;
        console.log("!!!!!!!!!!!! session date", values.sessionDate);
        console.log("!!!!!!!!!!!! session date formated", sessionDate);
        console.log("!!!!!!!!!!!! session time", values.sessionTime);
        // const sessionTime = values.sessionTime
        //   ? values.sessionTime.toISOString().split("T")[1].split("Z")[0]
        //   : null;

        const sessionTime = values.sessionTime
          ? values.sessionTime.toLocaleTimeString("en-GB", { hour12: false }) // 'HH:mm:ss' format
          : null;
        console.log("!!!!!!!!!!!! session time formated", sessionTime);

        // Prepare data for GraphQL mutation
        const newSession = {
          name: values.name,
          sessionDate: sessionDate, // Store only the date
          sessionTime: sessionTime, // Store only the time
          duration: parseInt(values.duration, 10),
          hostIds: values.host.map((h) => String(h.value)),
          coHostIds: values.coHost.map((h) => String(h.value)),
          speakerIds: values.speaker.map((s) => String(s.value)),
          description: values.description,
          trackIds: values.tracks.map((t) => String(t.value)),
          tags: values.tags,
        };

        axios
          .post("/graphql", {
            query: `
      mutation CreateSession($input: CreateSessionInput!) {
        createSession(input: $input) {
          id
          name
          sessionDate
          sessionTime
          duration
          hosts {
            id
            firstName
            lastName
          }
          coHosts {
            id
            firstName
            lastName
          }
          speakers {
            id
            firstName
            lastName
          }
          description
          tracks {
            id
            name
          }
          tags
        }
      }
    `,
            variables: {
              input: newSession,
            },
          })
          .then((response) => {
            console.log("Session created:", response.data);
            onSave(response.data.data.createSession);
            setDrawerOpen(false);
            setSubmitting(false);
            onClose();
          })
          .catch((error) => {
            console.error("Error creating session:", error);
            setSubmitting(false);
          });
      }}
    >
      {({ isSubmitting, setFieldValue, values }) => (
        <Form>
          <Typography variant="h5" gutterBottom>
            Add Session
          </Typography>

          <Typography variant="h6" gutterBottom>
            Basic Info
          </Typography>

          <Grid container spacing={3}>
            {/* Name Field */}
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="name"
                label="Session Name"
                variant="outlined"
                fullWidth
                helperText={<ErrorMessage name="name" />}
                error={Boolean(<ErrorMessage name="name" />)}
              />
            </Grid>

            {/* Break Date */}
            <Grid item xs={12} sm={6}>
              <Field name="sessionDate">
                {({ field }) => (
                  <DatePicker
                    label="Session Date"
                    value={values.sessionDate}
                    onChange={(value) => setFieldValue("sessionDate", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        helperText={<ErrorMessage name="sessionDate" />}
                        error={Boolean(<ErrorMessage name="sessionDate" />)}
                      />
                    )}
                  />
                )}
              </Field>
            </Grid>

            {/* Break Time */}
            <Grid item xs={12} sm={6}>
              <Field name="sessionTime">
                {({ field }) => (
                  <TimePicker
                    label="Session Time"
                    value={values.sessionTime}
                    onChange={(value) => setFieldValue("sessionTime", value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        helperText={<ErrorMessage name="sessionTime" />}
                        error={Boolean(<ErrorMessage name="sessionTime" />)}
                      />
                    )}
                  />
                )}
              </Field>
            </Grid>

            {/* Duration */}
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="duration"
                label="Duration (in minutes)"
                variant="outlined"
                fullWidth
                type="number"
                onChange={(e) =>
                  setFieldValue("duration", Number(e.target.value))
                }
                helperText={<ErrorMessage name="duration" />}
                error={Boolean(<ErrorMessage name="duration" />)}
              />
            </Grid>

            {/* Hosts */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={hostOptions}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => setFieldValue("host", newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Host"
                    variant="outlined"
                    fullWidth
                    helperText={<ErrorMessage name="host" />}
                    error={Boolean(<ErrorMessage name="host" />)}
                  />
                )}
              />
            </Grid>

            {/* Co-Hosts */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={hostOptions}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) =>
                  setFieldValue("coHost", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Co-Host"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Speakers (Suppliers) */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={supplierOptions}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) =>
                  setFieldValue("speaker", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Speaker"
                    variant="outlined"
                    fullWidth
                    helperText={<ErrorMessage name="speaker" />}
                    error={Boolean(<ErrorMessage name="speaker" />)}
                  />
                )}
              />
            </Grid>

            {/* Description */}
            <Grid item xs={12}>
              <Field
                as={TextField}
                name="description"
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                helperText={<ErrorMessage name="description" />}
                error={Boolean(<ErrorMessage name="description" />)}
              />
            </Grid>

            {/* Tracks */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={trackOptions}
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) =>
                  setFieldValue("tracks", newValue)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tracks"
                    variant="outlined"
                    fullWidth
                    helperText={<ErrorMessage name="tracks" />}
                    error={Boolean(<ErrorMessage name="tracks" />)}
                  />
                )}
              />
            </Grid>

            {/* Tags */}
            <Grid item xs={12}>
              <Autocomplete
                multiple
                freeSolo
                value={values.tags}
                onChange={(event, newValue) => setFieldValue("tags", newValue)}
                options={tags}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      key={index}
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Tags"
                    fullWidth
                  />
                )}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Submitting..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

const Session = (onSave) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setDrawerOpen(true)}
        startIcon={<AddIcon />}
      >
        Add Session
      </Button>

      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ width: "400px" }}
        PaperProps={{ style: { width: "400px" } }}
      >
        <PaperStyled>
          <SessionForm
            onClose={toggleDrawer(false)}
            onSave={onSave}
            setDrawerOpen={setDrawerOpen}
          />
        </PaperStyled>
      </Drawer>
    </>
  );
};

export default Session;
