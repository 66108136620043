import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Box,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactFormBuilder, ReactFormGenerator } from "react-form-builder2";
import "react-form-builder2/dist/app.css"; // Import Form Builder CSS
import { axiosInstance } from "../../utils/axios";

// Custom layout for preview and form builder
const LayoutContainer = {
  display: "flex",
  flexDirection: "row",
  height: "calc(100% - 64px)", // Adjusted height to fit the content
  width: "100%",
};

const PreviewContainer = {
  width: "35%",
  padding: "16px",
  backgroundColor: "#f8f8f8",
  borderRight: "1px solid #ddd",
  overflowY: "auto",
};

const DropzoneContainer = {
  width: "65%",
  padding: "16px",
  backgroundColor: "#fafafa",
  overflowY: "auto",
};

function RegistrationTab() {
  const [openFormBuilder, setOpenFormBuilder] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formDataPreview, setFormDataPreview] = useState([]);
  const [formName, setFormName] = useState("");
  const [showFormPreview, setShowFormPreview] = useState(false); // Control form preview visibility
  const [forms, setForms] = useState([]); // Store all forms
  const [selectedForm, setSelectedForm] = useState(""); // Store selected form

  useEffect(() => {
    fetchForms();
  }, []);

  // Fetch existing forms from backend (GraphQL)
  const fetchForms = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        query {
          getAllForms {
            _id
            name
            formData
          }
        }
      `,
      });
      const fetchedForms = response.data.getAllForms;
      setForms(fetchedForms);
    } catch (error) {
      console.error("Error fetching forms:", error);
    }
  };

  const handleFormSave = (data) => {
    const formattedData = data.task_data.map((item) => ({
      ...item,
      text: item.label || item.text || "", // Ensure 'text' field is present
    }));

    setFormData(formattedData); // Save formatted form data
  };

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
  };

  // Save form to backend using GraphQL

  const handleSaveForm = async () => {
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation SaveForm($input: FormInput!) {
          saveForm(input: $input) {
            _id
            name
          }
        }
      `,
        variables: {
          input: {
            name: formName, // Use the formName state for the unique name
            data: formData, // Send the form data
          },
        },
      });
      console.log("Form saved:", response.data.data.saveForm.name); // Log the form name
      fetchForms(); // Refresh forms after saving
      setOpenFormBuilder(false);
    } catch (error) {
      console.error("Error saving form:", error);
    }
  };

  const handleAnswerSave = async (data) => {
    console.log("!!!!!!!!!!! answer data", data);
    try {
      const response = await axiosInstance.post("/graphql", {
        query: `
        mutation SaveAnswer($input: AnswerInput!) {
          saveAnswer(input: $input) {
            _id
            answers
          }
        }
      `,
        variables: {
          input: {
            formId: selectedForm, // Use the formName state for the unique name
            answers: data, // Send the form data
          },
        },
      });
      console.log("!!!!!!!! Answer saved:", response.data.saveAnswer); // Log the form name
    } catch (error) {
      console.error("Error saving form:", error);
    }
  };

  const handleFormSelect = (event) => {
    const selectedFormName = event.target.value;
    setSelectedForm(selectedFormName);
    setShowFormPreview(!showFormPreview);
    // Find the selected form's data
    const selected = forms.find((form) => form._id === selectedFormName);
    if (selected) {
      setFormDataPreview(selected.formData); // Update the form data for preview
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 4,
        }}
      >
        <Typography variant="h3">Registration Form</Typography>
        <Button
          variant="contained"
          onClick={() => setOpenFormBuilder(true)}
          color="primary"
        >
          Add New Form
        </Button>
      </Box>

      {/* Dropdown for selecting forms */}
      {forms.length > 0 && (
        <FormControl sx={{ width: "250px", marginBottom: 3 }}>
          <InputLabel>Select a Form</InputLabel>
          <Select
            value={selectedForm}
            onChange={handleFormSelect}
            label="Select a Form"
          >
            {forms.map((form) => (
              <MenuItem key={form._id} value={form._id}>
                {form.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* Conditional Rendering for Form Preview */}
      {showFormPreview && (
        <Box
          sx={{
            marginBottom: 4,
            padding: 3,
            border: "1px solid #ddd",
            borderRadius: 4,
            backgroundColor: "#f8f8f8",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Form Preview - {selectedForm}
          </Typography>
          <ReactFormGenerator
            data={formDataPreview}
            action_name="Save"
            onSubmit={handleAnswerSave}
            answer_data={{}}
          />
        </Box>
      )}

      {/* Dialog for form builder */}
      <Dialog
        fullScreen
        open={openFormBuilder}
        onClose={() => setOpenFormBuilder(false)}
        PaperProps={{
          style: {
            height: "90vh",
            width: "100vw",
          },
        }}
      >
        <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" style={{ whiteSpace: "nowrap" }}>
            Form Builder
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setOpenFormBuilder(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        {/* Form Name Field */}
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
        >
          <Box sx={{ marginBottom: 2, marginTop: 3 }}>
            <TextField
              label="Form Name"
              size="small" // Smaller input
              sx={{ width: "250px" }} // Reduced width for Form Name field
              value={formName}
              onChange={handleFormNameChange}
              placeholder="Enter form name"
              variant="outlined"
              InputLabelProps={{ style: { fontSize: 14 } }} // Smaller font for label
              inputProps={{ style: { fontSize: 14 } }} // Smaller font for input
            />
          </Box>

          <Box sx={{ flexGrow: 1, display: "flex", height: "100%" }}>
            <div style={LayoutContainer}>
              {/* Left Side - Form Preview */}
              <div style={PreviewContainer}>
                <Typography variant="h6" mb={2}>
                  Form Preview ii bb
                </Typography>
                <ReactFormGenerator data={formData} />
              </div>

              {/* Center - Dropzone */}
              <div style={DropzoneContainer}>
                <Typography variant="h6" mb={2}>
                  Dropzone
                </Typography>
                <ReactFormBuilder onPost={handleFormSave} />
              </div>
            </div>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setOpenFormBuilder(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSaveForm} color="primary">
            Save Form
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default RegistrationTab;
